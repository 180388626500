import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Container, Box, Grid, TextField } from '@mui/material';

const LocationSelector = ({ newUser, setNewUser, showAddressAndPincode }) => {
  const [pincode, setPincode] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const validationSchema = Yup.object().shape({
    country: Yup.string().nullable().required('Country is required'),
    state: Yup.string().nullable().required('State is required'),
    city: Yup.string().nullable().required('City is required'),
    address: showAddressAndPincode ? Yup.string().required('Address is required') : Yup.string(),
    pincode: showAddressAndPincode ? Yup.string().required('Pincode is required') : Yup.string(),
    district: Yup.string().nullable().required('District is required'),
  });

  const formik = useFormik({
    initialValues: {
      country: newUser.country || '',
      state: newUser.state || '',
      city: newUser.city || '',
      address: newUser.address || '',
      pincode: newUser.pincode || '',
      district: newUser.district || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (pincode.length === 6) {
        try {
          const response = await fetch(`https://testapi.postalpincode.in/pincode/${pincode}`);
          const result = await response.json();
          console.log('API Response:', result);
          setData(result);
        } catch (error) {
          console.error('Error fetching pin data:', error);
          setError('Failed to fetch data. Please try again later.');
        }
      }
    };

    const timeoutId = setTimeout(() => {
      fetchData();
    }, 3000); 

    return () => clearTimeout(timeoutId);
  }, [pincode]);

  useEffect(() => {
    if (data && data[0]?.Status === 'Success') {
      const postOffice = data[0].PostOffice[0];
      formik.setFieldValue('district', postOffice.District || '');
      formik.setFieldValue('state', postOffice.State || '');
      formik.setFieldValue('country', postOffice.Country || '');
      formik.setFieldValue('pincode', postOffice.Pincode || '');
    }
  }, [data]);

  const handlePincodeChange = (e) => {
    const pincode = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (pincode === '' || (regex.test(pincode) && pincode.length <= 6)) {
      setPincode(pincode);
    }
  };

  const handleAddressChange = (e) => {
    formik.setFieldValue('address', e.target.value);
  };

  useEffect(() => {
    setNewUser(formik.values);
  }, [formik.values, setNewUser]);

  return (
    <Grid maxWidth="xl">
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Pincode"
              variant="outlined"
              fullWidth
              value={pincode}
              onChange={handlePincodeChange}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="District"
              variant="outlined"
              fullWidth
              value={formik.values.district || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              value={formik.values.state || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Country"
              variant="outlined"
              fullWidth
              value={formik.values.country || ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          {showAddressAndPincode && (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  value={formik.values.address}
                  onChange={handleAddressChange}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default LocationSelector;
