import React,  {useState}  from 'react';
import { Grid, Button, TextField, MenuItem, Paper, Container, Typography, Box } from '@material-ui/core';
import { NewBBPSIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";


const NewElectricity = () => {
  // State management for dropdown and input fields
  const [operator, setOperator] = useState('');
  const [consumerNumber, setConsumerNumber] = useState('');
  const [showConsumerInput, setShowConsumerInput] = useState(false);
  const [showProceedFields, setShowProceedFields] = useState(false);
  const [formData, setFormData] = useState({
    consumerName: '',
    bu: '',
    customerName: '',
    amount: '',
    billDate: '',
    dueDate: '',
    billNumber: '',
  });

  // List of electricity operators in India
  const operators = [
    'Tata Power',
    'Adani Electricity',
    'BSES Rajdhani',
    'BSES Yamuna',
    'MSEB Maharashtra',
    'CESC Kolkata',
    'NDPL Delhi',
  ];

  // Handle operator selection
  const handleOperatorChange = (e) => {
    setOperator(e.target.value);
    setShowConsumerInput(true);
    setShowProceedFields(false); // Hide form when changing operator
    setConsumerNumber(''); // Reset consumer number field
  };

  // Handle Proceed button click
  const handleProceedClick = () => {
    setShowProceedFields(true);
  };

  // Handle Reset button click
  const handleResetClick = () => {
    setOperator('');
    setConsumerNumber('');
    setShowConsumerInput(false);
    setShowProceedFields(false);
    setFormData({
      consumerName: '',
      bu: '',
      customerName: '',
      amount: '',
      billDate: '',
      dueDate: '',
      billNumber: '',
    });
  };

  // Handle form data change
  const handleFormDataChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                    Bill Payment
                </Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                    Electricity Bill Payment
                </Typography>
                </Grid>

                <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
                        Electricity Bill Payment
                    </Typography>
                    <Typography>
                       <NewBBPSIcon/>
                    </Typography>
                </Grid>
            </Grid>

            <Grid style={{ marginTop: '2%' }}>
                <Paper elevation={2} style={{ padding: '2rem', borderRadius: '10px' }}>
                {/* {/ Dropdown for operator selection /} */}
                {!showProceedFields && (
                <>
                    <Grid item xs={12} md={6}>
                        <TextField
                        select
                        label="Operator Name"
                        value={operator}
                        onChange={handleOperatorChange}
                        fullWidth
                        variant="outlined"
                        >
                        {operators.map((operator) => (
                            <MenuItem key={operator} value={operator}>
                            {operator}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Grid>

                    {/* {/ Consumer number input field /} */}
                    {showConsumerInput && (
                        <Grid item xs={12} md={6} style={{ marginTop: '2%' }}>
                        <TextField
                            label="Consumer Number"
                            value={consumerNumber}
                            onChange={(e) => setConsumerNumber(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                        </Grid>
                    )}

                    {/* {/ Wrapping buttons within the same grid container /} */}
                    <Grid container item xs={12} md={12} spacing={2} style={{ marginTop: '16px' }} justifyContent="flex-start">
                        <Grid item xs={12} md={2}>
                        <Button
                            style={{
                            color: 'white',
                            backgroundColor: '#4B6F8D99',
                            textTransform: 'none',
                            width: '100%',
                            }}
                            onClick={handleResetClick}
                        >
                            Reset
                        </Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                        <Button
                            style={{
                            color: 'white',
                            backgroundColor: '#027BFE',
                            textTransform: 'none',
                            width: '100%',
                            }}
                            onClick={handleProceedClick}
                            disabled={!consumerNumber}
                        >
                            Proceed
                        </Button>
                        </Grid>
                    </Grid>

                </>
                )}
{/* 
                {/ Form with multiple fields after proceeding /} */}
                {showProceedFields && (
                <>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A', marginBottom:'4%' }}>
                        Electricity Bill Details
                    </Typography>
                    <Grid container spacing={2}>
                    {/* {/ Row 1 /} */}
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Operator"
                        value={operator}
                        fullWidth
                        variant="outlined"
                        disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Consumer Name"
                        name="consumerName"
                        value={formData.consumerName}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Consumer Number"
                        value={consumerNumber}
                        fullWidth
                        variant="outlined"
                        disabled
                        />
                    </Grid>

                    {/* {/ Row 2 /} */}
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="BU"
                        name="bu"
                        value={formData.bu}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Customer Name"
                        name="customerName"
                        value={formData.customerName}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>

                    {/* {/ Row 3 /} */}
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Bill Date"
                        name="billDate"
                        value={formData.billDate}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Due Date"
                        name="dueDate"
                        value={formData.dueDate}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                        label="Bill Number"
                        name="billNumber"
                        value={formData.billNumber}
                        onChange={handleFormDataChange}
                        fullWidth
                        variant="outlined"
                        />
                    </Grid>
                    </Grid>

                    {/* {/ Buttons for Pay and Reset /} */}
                    <Grid container spacing={2} style={{ marginTop: '16px' }}>
                    <Grid item xs={12} md={2}>
                        <Button
                        style={{ color: 'white', backgroundColor: '#4B6F8D99', textTransform: 'none', width: '100%' }}
                        onClick={handleResetClick}
                        >
                        Reset
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                        style={{ color: 'white', backgroundColor: '#027BFE', textTransform: 'none', width: '100%' }}
                        disabled={!formData.amount || !formData.billNumber}
                        >
                        Pay
                        </Button>
                    </Grid>
                    </Grid>
                </>
                )}
                </Paper>
            </Grid>
    </Container>
  );
};

export default NewElectricity;
