import React from 'react';
import { Box, Typography, Button, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FareDetailsComponent = () => {
    const navigate = useNavigate();

    const handleBookingComponent = () => {
        navigate('/flights/booking')
    };
    return (

        <Box sx={{ p: 2, mt: 2 }} >
            <Grid container spacing={2} alignItems="center" flexDirection={'row'}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">IndiGo</Typography>
                    <Typography variant="h6">02:40 PM</Typography>
                    <Typography variant="body2">Kolhapur</Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign="right">
                    <Typography variant="h6">₹9,858</Typography>
                    <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>₹10,158</Typography>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box p={1} bgcolor="lightblue">
                        <Typography variant="subtitle2">Best Price</Typography>
                        <Typography variant="body1"><strong>SAVER</strong></Typography>
                        <Typography variant="body2">Chargeable</Typography>
                        <Typography variant="body2">Starting INR 2750</Typography>
                        <Typography variant="body2">Starting INR 3000</Typography>
                        <Typography variant="body2">15KG</Typography>
                        <Typography variant="body2">7KG</Typography>
                        <Button variant="contained" sx={{ mt: 1 }} onClick={handleBookingComponent}>₹9,858</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box p={1} bgcolor="lightgray">
                        <Typography variant="subtitle2">Best Value</Typography>
                        <Typography variant="body1"><strong>FLEXI PLUS</strong></Typography>
                        <Typography variant="body2">Included</Typography>
                        <Typography variant="body2">4 Days & Above: No Fee</Typography>
                        <Typography variant="body2">4 Days & Above: INR 500</Typography>
                        <Typography variant="body2">15KG</Typography>
                        <Typography variant="body2">7KG</Typography>
                        <Button variant="contained" sx={{ mt: 1 }}>₹11,011</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box p={1} bgcolor="lightgray">
                        <Typography variant="subtitle2">Super Value</Typography>
                        <Typography variant="body1"><strong>SUPER 6E</strong></Typography>
                        <Typography variant="body2">Included</Typography>
                        <Typography variant="body2">4 days and beyond: INR 500 Onwards</Typography>
                        <Typography variant="body2">25KG</Typography>
                        <Typography variant="body2">7KG</Typography>
                        <Typography variant="body2">Express CheckIn</Typography>
                        <Button variant="contained" sx={{ mt: 1 }}>₹18,558</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
};

export default FareDetailsComponent;
