import React from 'react';
import axios from 'axios';

const Testpayin = () => {
  // Define the payload
  const payload = {
    phone: "7798552844", // optional
    amount: 10,
    orderId: "abc123",
    redirectUrl: "https://google.com/"
  };

  // Function to handle API call
  const generateKey = async () => {
    try {
      const response = await axios.post('https://apimob.ipaisa.co.in/ip/genrateKey', payload);
      console.log('Response:', response.data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error generating key:', error);
    }
  };

  return (
    <div>
      <h1>Generate Key</h1>
      <button onClick={generateKey}>Generate</button>
    </div>
  );
};

export default Testpayin;
