import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Paper, Typography } from '@mui/material';
import { Grid } from '@mui/material';

const RetailerTotalServices = () => {
    const [data, setData] = useState({
        ChannelPartner: 20,
        SuperDistributor: 30,
        MasterDistributor: 10,
        AreaDistributor: 25,
        Distributor: 15,
        Retailer: 5,
    });

    const chartData = {
        labels: ['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5'],
        datasets: [
            {
                data: [
                    data.ChannelPartner,
                    data.SuperDistributor,
                    data.MasterDistributor,
                    data.AreaDistributor,
                    data.Retailer,
                ],
                backgroundColor: [
                    '#092E1F',
                    '#8F00FF',
                    '#B25AEF',
                    '#FF7B31',
                    '#00DDE5',
                ],
                hoverBackgroundColor: [
                    '#092E1F',
                    '#8F00FF',
                    '#B25AEF',
                    '#FF7B31',
                    '#00DDE5',
                ],
            },
        ],
    };

    const handleClick = (event, elements) => {
        if (elements && elements.length > 0) {
            const clickedElementIndex = elements[0].index;
            const clickedLabel = chartData.labels[clickedElementIndex];
            const clickedValue = chartData.datasets[0].data[clickedElementIndex];
            console.log(`${clickedLabel}: ${clickedValue}`);
        } else {
            console.log('No valid elements found');
        }
    };

    return (
        <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '18px', overflow: 'hidden', boxShadow:'none' }}>
            <Grid>
                <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Overall Services</Typography>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '1%' }}>
                <Grid item xs={12} md={7}>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <Pie
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                onClick: (event, elements) => handleClick(event, elements),
                            }}
                            height={300} // Set a fixed height for Pie chart
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={5} container direction="column" justifyContent="center">
                    {chartData.labels.map((label, index) => (
                        <Grid
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                overflow: 'hidden', // Ensures text doesn't overflow
                                textOverflow: 'ellipsis', // Truncates text with ellipsis
                                whiteSpace: 'nowrap', // Prevents text from wrapping
                            }}
                        >
                            <Grid
                                style={{
                                    backgroundColor: chartData.datasets[0].backgroundColor[index],
                                    borderRadius: '50%',
                                    width: '10px',
                                    height: '10px',
                                    marginRight: '10px',
                                }}
                            ></Grid>
                            <Typography
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%', // Ensures text doesn't go beyond container
                                }}
                            >
                                {label}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RetailerTotalServices;
