import React, { useRef, useState } from 'react'
import {
    Container,
    Button,
    Grid,
    DialogActions,
    TextField,
    Typography,
    Paper
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';

const styles = {
    formContainer: {
        padding: '16px',
        margin: 'auto',
    },
    textField: {
        width: '80%',
        marginBottom: '16px',
    },
    textArea: {
        width: '80%',
        marginBottom: '16px',
    },
    fileInput: {
        marginBottom: '16px',
    },
    imgPreview: {
        width: '375px',
        height: '175px',
        objectFit: 'cover',
        border: '1px solid #ccc',
    },
    note: {
        color: 'red',
        fontSize: '0.8rem',
    },
};

const BlogsUploader = () => {
    const editor=useRef(null);
    const[content,setContent]=useState('')

    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [recognition, setRecognition] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
    };

    const handleClose = () => {
        navigate("/blogsupdater")
    };

    const handleBackClick = () => {
        navigate('/blogsupdater');
    };

    const startRecognition = () => {
        if (recognition && recognition.recognizing) {
            recognition.stop();
        }

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognitionInstance = new SpeechRecognition();

        recognitionInstance.onstart = () => {
            recognitionInstance.recognizing = true;
        };

        recognitionInstance.onend = () => {
            recognitionInstance.recognizing = false;
        };

        recognitionInstance.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setContent((prevContent) => prevContent + " " + transcript);
        };

        recognitionInstance.onerror = (event) => {
            console.error(event.error);
        };

        setRecognition(recognitionInstance);
        recognitionInstance.start();
    };

    return (
        <Container style={{ marginTop: '7%' }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography style={{ fontWeight: 'bold', fontSize: '25px' }}>Add Blogs</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" style={{ backgroundColor: 'red' }} onClick={handleBackClick}>
                        <ArrowBackIcon style={{ marginLeft: '-5%', paddingRight: '2%' }} />Back
                    </Button>
                </Grid>
            <Grid style={{ display: 'flex'}}>
                    <Grid elevation={3} justifyContent='flex-start' style={styles.formContainer}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Blog Title"
                                    variant="outlined"
                                    style={styles.textField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Slug URL"
                                    variant="outlined"
                                    style={styles.textField}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={styles.dateInput}>
                                <TextField
                                    required
                                    label="Blog Date"
                                    type="date"
                                    variant="outlined"
                                    style={styles.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{marginLeft:'-10%'}}>
                                <TextField
                                    required
                                    label="Blog By"
                                    variant="outlined"
                                    style={styles.textField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                <Grid>
                    <Grid elevation={3} style={styles.formContainer}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                    Upload Blog Image
                                </Typography>

                                <Grid style={{ border: '1px solid black', borderRadius: '5px', padding: '5px', maxHeight: '36%' }}>
                                    <input
                                        accept="image/*"
                                        style={styles.fileInput}
                                        id="blog-image-upload"
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                </Grid>
                                <Typography style={styles.note} marginTop='3%'>
                                    Note: Preferred Image size 375px X 175px
                                </Typography>
                                {image && <img src={image} alt="Blog" style={styles.imgPreview} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid id="Description" container spacing={2} style={{ marginTop: '-2%', padding:'1.5%' }}>
                <Grid item xs={12}>
                    <Typography>Description : </Typography>
                    <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={8} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {}}
                />
                <Button onClick={startRecognition}  variant="contained" style={{ marginTop: '10px', textTransform: 'none', backgroundColor:'#512DA8' }}>
                    Start Speech to Text <MicIcon/>
                </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Meta Title"
                        variant="outlined"
                        style={styles.textField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Meta Keywords"
                        variant="outlined"
                        style={styles.textField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Meta Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        style={styles.textArea}
                    />
                </Grid>
                <DialogActions style={{ paddingLeft: '1.4%', justifyContent: "flex-start" }}>
                    <Button variant="contained" color="success">Submit</Button>
                    <Button onClick={handleClose} variant="contained" color="error">Cancel</Button>
                </DialogActions>
            </Grid>
            </Grid>
            
        </Container>
    )
}

export default BlogsUploader
