import React, { useState } from 'react';
import {
    Typography,
    Divider,
    Grid,
    FormControlLabel,
    Checkbox,
    Box,
    Slider,
    List,
    ListItem,
    display,
    ListItemAvatar,
    CircularProgress,
    Avatar,
    ListItemText,
    Paper,
    FormLabel,
    FormControl,
    marginBottom,
    Button,
    TextField,
    Container,

} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TrainIcon from '@mui/icons-material/Train';
import SubwayIcon from '@mui/icons-material/Subway';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PassengerDetail from './PassengerDetail';
import { useNavigate } from 'react-router-dom';


function TrainTicketBooking() {
    const [tripType, setTripType] = useState('oneway');
    const [activeButton, setActiveButton] = useState(null);
    const [showTrainDetails, setShowTrainDetails] = useState(false); // State to manage showing train details
    const navigate = useNavigate();



    const handleTripTypeChange = (event) => {
        setTripType(event.target.value);
        if (event.target.value === 'roundtrip') {
            formik.setFieldValue('toDate', null);
        }
    };

    const handlePassengerDetails = () => {
        navigate("/passengerdetails");
      };

      const handleNameInput = (e) => {
        const re = /^[A-Za-z\s]*$/;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };

const trains = []; // Initialize with actual data array
const value = [0, 1000]; // Example values, replace with actual state values
const handleChange = (event, newValue) => {}; // Define handleChange function
const valuetext = (value) => `${value}`; // Example function, replace with actual logic
const minPrice = 0; // Replace with actual min price
const maxPrice = 1000; // Replace with actual max price
const departureValue = [0, 24]; // Example values, replace with actual state values
const handleDepartureChange = (event, newValue) => {}; // Define handleDepartureChange function
const minTime = 0; // Replace with actual min time
const maxTime = 24; // Replace with actual max time
const arrivalValue = [0, 24]; // Example values, replace with actual state values
const handleArrivalChange = (event, newValue) => {}; // Define handleArrivalChange function



    const handleButtonClick = (value) => {
        setActiveButton(value);
        formik.setFieldValue('cabinClass', value);
    };

    const handleSwapStations = () => {
        const from = formik.values.from;
        const to = formik.values.to;
        formik.setFieldValue('from', to);
        formik.setFieldValue('to', from);
    };



    const validationSchema = Yup.object().shape({
        from: Yup.string().required('From field is required'),
        to: Yup.string().required('To field is required'),
        fromDate: Yup.date().required('From Date is required'),
        toDate: Yup.date().nullable().when('tripType', {
            is: 'roundtrip',
            then: Yup.date().required('To Date is required for round trips'),
        }),
    });

    const formik = useFormik({
        initialValues: {
            from: '',
            to: '',
            fromDate: new Date(),
            toDate: null,
            infants: 0,
            cabinClass: 'Economy',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            setShowTrainDetails(true); // Show train details after form submission
        },
    });

    return (
        <Container maxWidth="lg" sx={{ marginTop: '7%'}}>
            <Paper elevation={2} sx={{ padding: 2, borderRadius: 5 }}>
                <form onSubmit={formik.handleSubmit}>

                    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginTop: 1}}>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <FormLabel>Select Station</FormLabel>
                                <TextField
                                    variant="standard"
                                    name="from"
                                    label="From"
                                    onKeyPress={handleNameInput}
                                    value={formik.values.from}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.from && Boolean(formik.errors.from)}
                                    helperText={formik.touched.from && formik.errors.from}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} md={1} container justifyContent="center">
                            <CompareArrowsIcon onClick={handleSwapStations} sx={{ cursor: 'pointer' }} />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <FormLabel>Select Station</FormLabel>
                                <TextField
                                    variant="standard"
                                    name="to"
                                    label="To"
                                    onKeyPress={handleNameInput}
                                    value={formik.values.to}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.to && Boolean(formik.errors.to)}
                                    helperText={formik.touched.to && formik.errors.to}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormLabel>Departure Date</FormLabel>
                                    <DatePicker
                                        selected={formik.values.fromDate}
                                        onChange={(date) => formik.setFieldValue('fromDate', date)}
                                        customInput={
                                            <TextField
                                                variant="standard"
                                                name="fromDate"
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
                                                helperText={formik.touched.fromDate && formik.errors.fromDate}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <Button sx={{ borderRadius: 8}} type="submit" variant="contained" fullWidth style={{ height: '3rem', width: '11rem', marginLeft: '-40%', fontWeight: 'bold', fontSize: '100%',backgroundColor: '#512DA8' }}>
                                Search Train
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} justifyContent="flex-start" sx={{ marginTop: 2}}>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('AC & Non AC')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'AC & Non AC' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'AC & Non AC' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'AC & Non AC' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                AC & Non AC
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('AC Only')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'AC Only' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'AC Only' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'AC Only' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                AC Only
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('Non-AC Only')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'Non-AC Only' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'Non-AC Only' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Non-AC Only' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                Non-AC Only
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Paper>


            {/* // Dashboard */}

            <Grid container spacing={2} sx={{ marginTop: 2, marginBottom:3}}>
                <Grid item xs={12} md={3}>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 5   }}>
                        <Typography variant="h6">Filters</Typography>
                        <Divider />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                {/* <LocationOnIcon /> */}
                                <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Class</FormLabel>
                            </Grid>
                            <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                                <FormControlLabel control={<Checkbox />} label="1A - First Class AC" />

                                <FormControlLabel control={<Checkbox />} label="2A - AC 2 Tier" />

                                <FormControlLabel control={<Checkbox />} label="3A - AC 3 Tier" />

                                <FormControlLabel control={<Checkbox />} label="SL - Sleeper Class" />
                            </Grid>
                        </FormControl>
                        <Divider />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                {/* <LocationOnIcon /> */}
                                <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Train Type
                                {/* <TrainIcon/> */}
                                </FormLabel>
                            </Grid>
                            <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                                <FormControlLabel  control={<Checkbox />} label="Express Trains" />

                            </Grid>
                        </FormControl>
                        <Divider />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                {/* <LocationOnIcon /> */}
                                <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Quota</FormLabel>
                            </Grid>
                            {/* <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}> */}
                            <Grid container spacing={1} justifyContent="flex-start" item xs={12} paddingTop="10%" paddingBottom="10%">
                        <Grid item paddingBottom="4%"> 
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('General')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'General' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'General' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'General' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                               General
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('Tatkal')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'Tatkal' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'Tatkal' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Tatkal' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                Tatkal
                            </Button>
                        
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('Sr Citizen')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'Sr Citizen' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'Sr Citizen' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Sr Citizen' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                Sr Citizen
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                onClick={() => handleButtonClick('Ladies')}
                                sx={{
                                    borderRadius: 8,
                                    backgroundColor: activeButton === 'Ladies' ? '#512DA8' : 'grey.300',
                                    color: activeButton === 'Ladies' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Ladies' ? '#512DA8' : 'grey.300',
                                    },
                                }}
                            >
                                Ladies
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            <Divider />


            <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
                <Grid container alignItems="center">
                    <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Departure Time</FormLabel>
                </Grid>
                <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                <FormControlLabel control={<Checkbox />} label="Early Morning"/>
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(Before 06:00 hrs)</span>
                
                <FormControlLabel control={<Checkbox />} label="Morning" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(06:00 hrs to 12:00 hrs)</span>
                <FormControlLabel control={<Checkbox />} label="Afternoon" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(12:00 hrs to 18:00 hrs)</span>
                <FormControlLabel control={<Checkbox />} label="Evening" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(After 18:00 hrs)</span>
                
                </Grid>
            </FormControl>
            <Divider />
            <FormControl component="fieldset" sx={{ marginTop: 2, marginBottom: 2 }}>
                <Grid container alignItems="center">
                    <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Arrivel Time</FormLabel>
                </Grid>
                <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                <FormControlLabel control={<Checkbox />} label="Early Morning"/>
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(Before 06:00 hrs)</span>
                
                <FormControlLabel control={<Checkbox />} label="Morning" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(06:00 hrs to 12:00 hrs)</span>
                <FormControlLabel control={<Checkbox />} label="Afternoon" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(12:00 hrs to 18:00 hrs)</span>
                <FormControlLabel control={<Checkbox />} label="Evening" />
                <span style={{ fontSize: '0.75rem', color: 'gray', marginLeft:'20%'}}>(After 18:00 hrs)</span>
                
                </Grid>
            </FormControl>
            <Divider />


                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <CurrencyRupeeIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1, fontWeight:'bold' }}>Price</FormLabel>
                            </Grid>
                            <Box sx={{ width: 210, marginLeft: '15%', marginTop: '7%' }}>
                                <Typography variant='h6' gutterBottom fontSize={12}> Select Price Range </Typography>
                                <Slider
                                    style={{ width: '80%', color: '#512DA8' }}
                                    getAriaLabel={() => 'Price Range'}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={minPrice}
                                    max={maxPrice}
                                    step={100}
                                />
                                <Typography variant="caption" gutterBottom align="left" marginLeft={-1}>
                                    {value[0]}
                                </Typography>
                                <Typography variant="caption" gutterBottom align="right" marginLeft={18}>
                                    {value[1]}
                                </Typography>
                            </Box>
                        </FormControl>
                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <TrainIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Destination(s)</FormLabel>
                            </Grid>
                            <FormControlLabel style={{paddingTop:'4%'}} control={<Checkbox />} label={formik.values.from}   />
                        </FormControl>
                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <SubwayIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Origin(s)</FormLabel>
                            </Grid>
                            <FormControlLabel style={{paddingTop:'4%'}} control={<Checkbox />}  label={formik.values.to}/>
                        </FormControl>
                    </Paper>
                </Grid >



                {formik.values.from && formik.values.to && showTrainDetails && (
                    

                <Grid item xs={12} md={9} id='#TrainsDetails'>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4}}>
                        <Typography style={{display:'flex'}} fontWeight="bold" variant="h6">RAJYARANI EXP
                            <Typography style={{marginTop:'0.4%'}}>(17611)</Typography>
                        </Typography>
                        <Grid>
                        <Grid style={{display:'flex'}}>
                            <Typography style={{ justifyItems:"flex-start", fontSize:'20px', marginTop:'1%'}}>
                            02:55,
                            </Typography>
                            <span style={{paddingTop:'1%' , paddingLeft:'0.8%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>--- 49 m ---</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun,</span>
                            <Typography style={{ justifyContent:"flex-end", fontSize:'20px', marginTop:'1%', paddingLeft:'0.8%'}}>
                            03:44
                            </Typography>
                        </Grid>
                        <span style={{ paddingTop: '1%', fontSize: '1rem' }}>{formik.values.from}</span>
                        <span style={{ paddingTop: '1%', marginLeft: '52.6%', fontSize: '1rem' }}>{formik.values.to}</span>
                        </Grid>

                    <Grid style={{paddingTop:'5%', display:'flex'}}>
                    <Grid style={{cursor:'pointer'}} onClick={handlePassengerDetails}
                    >
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2S</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 56</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹55</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>16hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>SL</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 13</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹350</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>17hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>3A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'orange'}}>WL 33</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹750</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>23hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'Orange'}}>WL 9</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                            <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹1150</Typography>
                            <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>24hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


                        
                        <List>
                            {trains.map((train) => (
                                <React.Fragment key={train.id}>
                                    <ListItemText alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={train.station} src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${train.station} - $${train.price}`}
                                            secondary={`${train.from} to ${train.to}`}
                                        />
                                    </ListItemText>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>

                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop:'4%'}}>
                        <Typography style={{display:'flex'}} fontWeight="bold" variant="h6">SC SNSI EXP
                            <Typography style={{marginTop:'0.4%'}}>(17002)</Typography>
                        </Typography>
                        <Grid >
                        <Grid style={{display:'flex'}}>
                            <Typography style={{ justifyItems:"flex-start", fontSize:'20px', marginTop:'1%'}}>
                            04:30,
                            </Typography>
                            <span style={{paddingTop:'1%' , paddingLeft:'0.8%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>--- 55 m ---</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun,</span>
                            <Typography style={{ justifyContent:"flex-end", fontSize:'20px', marginTop:'1%', paddingLeft:'0.8%'}}>
                            05:45
                            </Typography>
                        </Grid>
                        <span style={{ paddingTop: '1%', fontSize: '1rem' }}>{formik.values.from}</span>
                        <span style={{ paddingTop: '1%', marginLeft: '52.6%', fontSize: '1rem' }}>{formik.values.to}</span>
                        </Grid>

                <Grid style={{paddingTop:'5%', display:'flex'}}>
                <Grid style={{cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2S</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 56</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹55</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>16hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>SL</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 13</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹350</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>17hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>3A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'orange'}}>WL 33</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹750</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>23hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'Orange'}}>WL 9</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹1150</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>24hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                        <List>
                            {trains.map((train) => (
                                <React.Fragment key={train.id}>
                                    <ListItemText alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={train.station} src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${train.station} - $${train.price}`}
                                            secondary={`${train.from} to ${train.to}`}
                                        />
                                    </ListItemText>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop:'4%'}}>
                        <Typography style={{display:'flex'}} fontWeight="bold" variant="h6">AJANTA EXP
                            <Typography style={{marginTop:'0.4%'}}>(17064)</Typography>
                        </Typography>
                        <Grid>
                        <Grid style={{display:'flex'}}>
                            <Typography style={{ justifyItems:"flex-start", fontSize:'20px', marginTop:'1%'}}>
                            04:45,
                            </Typography>
                            <span style={{paddingTop:'1%' , paddingLeft:'0.8%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>--- 1:20 hrs ---</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'15%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun,</span>
                            <Typography style={{ justifyContent:"flex-end", fontSize:'20px', marginTop:'1%', paddingLeft:'0.8%'}}>
                            06:10
                            </Typography>
                        </Grid>
                        <span style={{ paddingTop: '1%', fontSize: '1rem' }}>{formik.values.from}</span>
                        <span style={{ paddingTop: '1%', marginLeft: '52.6%', fontSize: '1rem' }}>{formik.values.to}</span>
                        </Grid>

                <Grid style={{paddingTop:'5%', display:'flex'}}>
                <Grid style={{cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2S</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 56</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹55</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>16hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>SL</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'green'}}>AVL 13</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹350</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>17hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>3A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'orange'}}>WL 33</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹750</Typography>
                                <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>23hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid style={{paddingLeft:'2%', cursor:'pointer'}}>
                        <Box 
                            style={{width: 180, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} 
                            border={2} 
                            borderRadius={2}
                        >
                            <Box style={{display: 'flex', justifyContent: 'space-between', }}>
                                <Typography style={{marginLeft:'5%',fontSize: '1.3rem', fontWeight: 'bold'}}>2A</Typography>
                                <Typography style={{marginRight:'5%',fontSize: '1.3rem', fontWeight: 'bold', color: 'Orange'}}>WL 9</Typography>
                            </Box>
                            <Box style={{display: 'flex',marginTop: '5%', justifyContent: 'space-between',}}>
                            <Typography style={{marginLeft:'5%',fontSize: '1.1rem'}}>₹1150</Typography>
                            <span style={{marginRight:'5%',fontSize: '1.1rem',  color: 'gray'}}>24hrs ago</span>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>    
                        <List>
                            {trains.map((train) => (
                                <React.Fragment key={train.id}>
                                    <ListItemText alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={train.station} src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${train.station} - $${train.price}`}
                                            secondary={`${train.from} to ${train.to}`}
                                        />
                                    </ListItemText>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                )}
                
            </Grid>
        </Container>
    );
}

export default TrainTicketBooking;
