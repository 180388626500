import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Grid, CircularProgress,
  Button,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: '#C1E0FB',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
}));

const DeletedUserTable = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDeletedUsers = async () => {
      const token = localStorage.getItem('jwtToken'); // Assuming you're storing the token in localStorage
      try {
        const response = await axios.get('https://testapi.ipaisa.co.in/myapp/admin/delted-user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data);
        setUsers(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the deleted users:', error);
        setLoading(false);
      }
    };

    fetchDeletedUsers();
  }, []);

  const formatDate = (dateTimeString) => {
    if (!dateTimeString) return 'Invalid Date';
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-');
    if (!datePart || !timePart || !year || !month || !day) return 'Invalid Date';
    return `${day}-${month}-${year} ${timePart}`;
  };

  const generatePDF = () => {
    if (!users || users.length === 0) {
        toast('No user data available to generate PDF.');
        return;
    }

    const doc = new jsPDF();
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(16);
    doc.text('Deleted Users Report', 50, 20);
    doc.setFontSize(12);
    doc.text('Date: ' + new Date().toLocaleDateString(), 50, 30);

    const tableColumn = ["User ID", "First Name", "Last Name", "Email", "Mobile Number", "Deleted Status"];
    const tableRows = [];

    users.forEach(user => {
      const rowData = [
        user.userid,
        user.firstName,
        user.lastName,
        user.email,
        user.mobileNumber,
        user.isDeleted ? 'Deleted' : 'Active'
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 12,
      },
    });

    doc.save('Deleted_Users_Report.pdf');
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>  

      <Grid style={{display:'flex', alignItems:'center', justifyContent:'space-between' }}>
      <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
        Deleted Users
      </Typography>
      <Grid>
        <Button
          onClick={generatePDF}
          variant="contained"
          color="primary"
          style={{ textTransform: 'none' }}
          startIcon={<DownloadIcon />}
        >
          Download PDF File
        </Button>
      </Grid>
      </Grid>


      <Grid style={{ marginTop: '3%' }}>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
              <TableRow>
                <TableCell style={{ color: 'white' }} align="center">Sr. No.</TableCell>
                <TableCell style={{ color: 'white' }} align="center">User ID</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Parent ID</TableCell>
                <TableCell style={{ color: 'white' }} align="center">First Name</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Last Name</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Email</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Created Date</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Mobile Number</TableCell>
                <TableCell style={{ color: 'white' }} align="center">Deleted Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0 ? (
                users.map((user, index) => (
                  <TableRow key={user.userid} className={index % 2 === 0 ? classes.oddRow : classes.evenRow}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{user.userid}</TableCell>
                    <TableCell align="center">{user.parentId}</TableCell>
                    <TableCell align="center">{user.firstName}</TableCell>
                    <TableCell align="center">{user.lastName}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.createdAt}</TableCell>
                    <TableCell align="center">{user.mobileNumber}</TableCell>
                    <TableCell  style={{ color: 'red', fontWeight:'bold' }}align="center">{user.isDeleted ? 'Deleted' : 'Active'}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No deleted users found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
};

export default DeletedUserTable;

