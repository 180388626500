import React from 'react';
import { Typography, List, Box, Container } from '@mui/material';

export default function RefundandCancel() {
  return (
    <Container component="section" sx={{ display: 'flex', justifyContent: 'center', my: 10}} 
    style={{marginTop:'2%', padding:'5%'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 8 }}>
        <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold', mb: 4 }}>
        Cancellation and Refund Policy:
        </Typography>

        <Typography variant="body1" sx={{ color: 'black'}}>
          You acknowledge and agree to all of the terms, conditions, and policies without any constraints or restrictions by downloading, accessing and using <b>iPaisa</b>.
        </Typography>
        <Typography variant="body1" sx={{ color: 'black'}}>
          We retain the right to modify the terms at any moment without providing you with prior notice. You agree to obey and be bound by the terms as modified if you continue to use <b>iPaisa</b> after any such changes. The terms take into account any supplementary terms and conditions, disclaimers, privacy policies, and other rules that may be relevant to particular services, sections of this website, <b>iPaisa</b>, or other circumstances.
        </Typography>
        <Typography variant="body1" sx={{ color: 'black' }}>
          Refund Details, Refund Summaries, Amount to be Refunded, Transaction Status (Pending, Failed, Completed), and Transaction Instrument (UPI, Credit Card, Debit Card, Wallet) are all explained in the <b>iPaisa</b> Refund Policy.
        </Typography>

        <Box sx={{ width: '85%', marginLeft:'5%' }} marginTop='3%'>
          <Typography variant="body1" component="ol" sx={{ listStyleType: 'decimal', pl: 4, mb: 4 }} spacing={4}>
              <Typography>
               <b>1.</b> You (the user) also acknowledge that accessing or using the <b>iPaisa</b> website or mobile application constitutes your implicit acceptance of this disclaimer.
              </Typography>
              <br/>
              <Typography>
              <b>2.</b> All payments made through <b>iPaisa</b> for mobile / DTH recharge, bill pay, P2P transfer or any other payment processed by YOU on <b>iPaisa</b> platform, merchant partners accepting <b>iPaisa</b> wallet as a payment option shall be final and not be responsible for any error and omission by YOU or Merchant partners. Such transactions cannot be refunded, returned, or cancelled once initiated.
              </Typography>
              <br/>
              <Typography>
               <b>3.</b> During any transaction between a user and a merchant, vendor, or service provider, <b>iPaisa</b> does not acquire ownership of any goods or services offered by the merchant. At no point does <b>iPaisa</b> make any claim or assert any right regarding the goods or services that the merchant is providing to the customer.
              </Typography>
              <br/>
              <Typography>
               <b>4.</b> <b>iPaisa</b> has no authority over fees associated with cancellations or refunds. If there is a cancellation or refund, it will be regulated by the terms and conditions of the merchant or vendor, not the aggregator. <b>iPaisa</b> will not be liable for any charges associated with refunds or cancellations.
              </Typography>
              <br/>
              <Typography>
               <b>5.</b> If you make a payment by mistake, <b>iPaisa</b> is not responsible for the refund or to take it back.
              </Typography>
              <br/>
              <Typography>
               <b>6.</b> If you accidentally send money to the wrong person or for the wrong amount, <b>iPaisa</b> is not responsible, and your only option is to contact the third party to whom the money was sent to request a refund (if any).
              </Typography>
              <br/>
              <Typography>
               <b>7.</b> All purchases made through the <b>iPaisa</b> platform, including prepaid recharges, are final and cannot be returned or exchanged. You are accountable for the cell or DTH account number that you purchased the prepaid recharge for as well as any associated fees. Also, you are accountable for all data card and similar recharge service information as well as any associated fees, for any prepaid recharge transaction made for services requiring an inaccurate data card, incorrect mobile, or incorrect DTH account number.
              </Typography>
              <br/>
              <Typography>
               <b>8.</b><b>iPaisa</b> disclaims any liability for any losses, damages, or other consequences that may result from any user&apos;s actions on its website or mobile application, whether intentional or not. User/You should expressly acknowledge that <b>iPaisa</b> is just a payment facilitator and middleman and that it is released from any liability as a result.
              </Typography>
              <br/>
              <Typography>
               <b>9.</b> Please read the terms and conditions that the merchant or vendor has given on their website or other platform. Your inability to access such terms and conditions or any loss brought on by such terms and conditions or their absence are not the fault of <b>iPaisa</b>. You agree and understand that the vendor and you are the only parties to the actual sale contract. The published details and descriptions of websites and apps run by the aggregator of the merchant or vendor are not within the control or prevention of <b>iPaisa</b>, and the company is not liable for their contents.
              </Typography>
              <br/>
              <Typography>
               <b>10.</b> The accuracy of the aggregator&apos;s content or listings; the existence, calibre, safety, or legality of the things presented and the ability of the aggregator and merchant/vendor to sell goods or render services are not under the aggregator&apos;s control to <b>iPaisa</b>.
              </Typography>
              <br/>
              <Typography>
               <b>11.</b> At no time during any transaction between you and the aggregator, merchant, or vendor does <b>iPaisa</b> assume ownership of any listings, bookings, or services provided by the aggregator, merchant, or vendor. At no stage does <b>iPaisa</b> make any claims or assertions regarding the same that has been made available to you by the aggregator, merchant, or vendor.
              </Typography>
              <br/>
              <Typography>
               <b>12.</b> You should get in touch with the specific aggregator or merchant/vendor directly as they are exclusively responsible for the material, listings, and bookings they make available through the <b>iPaisa</b> platform. If you would need further information, we kindly ask that you get in touch with the seller, merchant, or service provider. You can also confirm the information on their websites.
              </Typography>
              <br/>
              <Typography>
               <b>13.</b> Despite anything to the contrary contained herein, the service, the interface, the API work, and their respective information, pricing and data, and availability are subject at any time and from time to time to human, mechanical, typographical, or other errors, oversights, mistakes, limitations, delays, and service interruptions, including, without limitation, as may be caused in whole or in part by, related to, or arising out of computer hardware and software, telecommunications equipment, or any other cause.
              </Typography>
              <br/>
              <Typography>
              <b>14.</b> Under no circumstances will the user receive a return for any money that was sent incorrectly or for any other reason.
              </Typography>
              <br/>
              <Typography>
              <b>15.</b> <b>iPaisa</b> is not liable for any prepaid recharge purchases made using erroneous information from a data card, DTH account, mobile number, or FASTag. Every successful prepaid recharge sale made through <b>iPaisa</b> is complete with details. All successful prepaid recharge sales made through <b>iPaisa</b> are final; no returns or exchanges are accepted. You are accountable for the cell or DTH account number associated with the prepaid recharge you purchase, along with any associated fees.
              </Typography>
              <br/>
              <Typography>
              <b>16.</b> <b>iPaisa</b> is not a party to the agreement between the user and the merchant establishment, as stated in the Terms and Conditions. Any advertising or merchant linked to <b>iPaisa</b> website is not endorsed by the company.
              </Typography>
              <br/>
              <Typography>
              <b>17.</b> <b>iPaisa</b> is not required to keep an eye on the services or products that the user uses from the merchant establishment. For all responsibilities under the agreement, not just the merchant establishment&apos;s warranties or guarantees. Every problem or grievance should be resolved directly between the user and the merchant business, for any shortcomings in products or services that were acquired using an <b>iPaisa</b> wallet.
              </Typography>
              <br/>
              <Typography>
              <b>18.</b> <b>iPaisa</b> is under no obligation to monitor the merchant establishment’s service, products used by the user. For all obligations under the contract including (without limitation) warranties or guarantees the merchant establishment. User should be the direct resolver with the merchant establishment for every dispute or complaint. For any deficiency in goods and/or services purchased using <b>iPaisa</b> wallet.
              </Typography>
              <br/>
              <Typography>
              <b>19.</b> Refund of any payment made erroneously by the user to any merchant establishment or an erroneous transfer to any person will not be made by <b>iPaisa</b> in any circumstances.
              </Typography>
              <br/>
              <Typography>
              <b>20.</b> Any web-link on the platform to a third-party site is not an endorsement of that web-link. User shall be subject to the terms and conditions in relation to that web-link, by using or browsing any such other web-link.
              </Typography>
              <br/>
              <Typography>
              <b>21.</b> <b>iPaisa</b> records shall be binding as the conclusive evidence of the transactions carried out through use of the wallet, in the event of any dispute.
              </Typography>
              <br/>
              <Typography>
              <b>22.</b> In case, If the Account holder initiates fund Transfer to an unintended or incorrect account, <b>iPaisa</b> will not be responsible for recovering the money.
              </Typography>
              <br/>
              <Typography>
              <b>23.</b> To the provision of any fund transfer and fund collection facility provided/facilitated by <b>iPaisa</b>, the following terms and conditions shall be applicable. In case of entering any wrong beneficiary details to <b>iPaisa</b> at the time of availing the said facility like incorrect virtual payment address or incorrect mobile number or account number or IFSC code, user shall be solely responsible, due to which the fund is transferred to an incorrect beneficiary. Execution of payment order by <b>iPaisa</b> become irrevocable, has to be agreed by the user.
              </Typography>
              <br/>
              <Typography>
              <b>24.</b> All the responsibility of Accuracy and authenticity of the instructions provided to <b>iPaisa</b>, if is in the form and manner prescribed by <b>iPaisa</b>, will be taken by user and shall be considered to be enough to operate the said Facility. To verify the instructions <b>iPaisa</b> shall not be required independently. If to stop or prevent the implementation of any payment order issued by the user, <b>iPaisa</b> has no liability if it unable to do so. User cannot revoke the payment order subsequently once the order is issued.
              </Typography>
              <br/>
              <Typography>
              <b>25.</b> The user only has the authority for his/her decisions and sole responsibility for all instructions, requests, directives, orders, directions entered by the user.
              </Typography>
              <br/>
              <Typography>
              <b>26.</b> Refunds issued by merchants the other users if any will be credited into user’s <b>iPaisa</b> wallet account, in case of any refund of any Product or services.
              </Typography>
              <br/>
              <Typography>
              <b>27.</b> The cashback amount will be rolled back in <b>iPaisa</b> wallet.
              </Typography>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
