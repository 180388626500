import React from "react";
import {
  Typography,
  Container,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider
} from "@mui/material";

export default function TermsCondition() {
  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 12 }} style={{marginTop:'2%', padding:'5%'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h4" color="textPrimary" fontWeight="bold">
          Terms and Conditions
        </Typography>
        <Typography variant="h5" color="textPrimary" fontWeight="bold" style={{marginBottom:'1%', marginTop:'1%'}}>
          Sign-Up / Register
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph>
          In order to use the iPaisa Services, you need to register on the iPaisa platform by providing accurate and complete information. Also, you are required to keep your account, KYC details, KYC Verification and contact information, complete and updated at all the times. iPaisa is the account that you create when you sign-up/register with iPaisa.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          iPaisa’s single sign-up ID services offer you the convenience, after a one-time registration to sign-in/log-in to web page and online platform. After successfully registering for the i-Paisa, the user can log-in via each participating platform with his single access credentials.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          For the use of iPaisa services, the user must register on iPaisa platform. The registration as a user is only allowed if the user meets the eligibility criteria as set out in these terms and conditions. Each user can register for only one user account.<br/> The user is obliqued to provide correct and complete information during the registration and use of the iPaisa services.<br/> The log-in data is intended solely for personal use by the user and therefore always to be kept secret and safe.<br/> The user is not entitled to share his login details with third parties to use the iPaisa service or to disclose them otherwise. The user is oblique to inform iPaisa immediately on becoming aware of and/or suspecting a case of any unauthorised use, disclosure and/or misuse of their access data or of their user account.<br/> Furthermore, the user is obliqued not to use the user account of another person.<br/> Submission of the duly completed registration form by the user simply provides invitation to us to make an offer to enter into the agreement on the use of our iPaisa services.<br/> The user guarantees that the data submitted for their user account is in all respects complete, true, accurate and not misleading. Any changes to user’s data should be promptly updated correctly in their account.<br/> Once you sign up on iPaisa, you are entitled to an iPaisa account.
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>iPaisa Digital Services</strong><br/>
          iPaisa services are provided via an application iPaisa app and web platform. By using iPaisa platform, you further agree and acknowledge the terms of use of iPaisa services provided on iPasa platform. This account allows you to access iPaisa platform, browse through the iPaisa service, make payment using Wallet, debit card, credit card or net banking collectively, known as payment gateway services, to permissible merchants on iPaisa platform or on participating platforms. You can also avail recharge and bill payment facility made available to iPaisa users under the terms of use of such services.
        </Typography>

        <Typography variant="body1" paragraph>
        KYC Allows you to share and maintain the know your customer “KYC” details and information for understanding purposes of other financial and non-financial products available on iPaisa app as services illustrated below:
        </Typography>
        <List sx={{ pl: 3 }}>
          <ListItem>
            <ListItemText primary="* UPI (UPI - unified payment interface)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="* External wallets (EW)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="* Utility bill payments (BBPS services)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="* Prepaid top ups" />
          </ListItem>
          <ListItem>
            <ListItemText primary="* Hotel, Bus, Flight bookings" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Any other financial product feature that is provided on iPaisa platform subject to regulatory provisions and permitted by Law." />
          </ListItem>
        </List>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>Digital Services</strong>
          <ListItem>
            <Typography>
            iPaisa facilitates the payment of certain bills through the iPaisa platform with respect to certain services offered by iPaisa business partners who have partnered with iPaisa to enable their bills to be paid through the iPaisa platform. iPaisa also offers certain Digital products which facilitate the purchase of prepaid recharges for mobile, DTH, data card and FasTag, as well as purchase of bus tickets, hotel reservations or booking flight tickets. You can also avail other financial services offered by Fina through iPaisa. Other digital products available on iPaisa Platform also include coupon redemption services and IIT. The terms and conditions in relation to bill payment and digital products set out below are applicable to and binding on you in conjunction with the terms and conditions already accepted by you. Each term or condition is applicable to and binding on you or any of your activities on the iPaisa platform, even if such term or condition not specifically reproduced herein below.
            </Typography>
          </ListItem>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>Use of iPaisa platform</strong>
          <ListItem>
            <Typography>
            You understand that except for information, products or services clearly indicated as being supplied by iPaisa, we do not operate, control, or endorse any information, products or services on the Internet in anyway. You also understand that iPaisa cannot and does not guarantee or warranty that files available for download through the iPaisa platform will be free of viruses, worms or other code that may be damaging. You are responsible for implementing procedures to satisfy your particular requirements of Internet security and for accuracy of data input and output.
            </Typography>
          </ListItem>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>Third party services and terms and conditions</strong>
          <ListItem>
            <Typography>
            iPaisa provides third party services via iPaisa platform. You understand that iPaisa do not own those services and you may need to accept their respective terms and conditions, and may require to share additional information in order to avail such a products or services provided by third parties on IP platform and iPaisa does not hold any liability on the information collected from you and third-party website or application and we cannot indemnify you for any third-party actions.
            </Typography>
          </ListItem>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>iPaisa Recharges</strong>
            <ListItem>
              <Typography>
              iPaisa is only a reseller of digital products. iPaisa does not provide mobile operator services and is only a reseller of prepaid mobile recharge services which are ultimately provided by telecommunications service providers or by other distributors or aggregators. iPaisa is not a warrantor, insurer, or guarantor of the services to be provided by the telecommunication companies. Prepaid mobile recharge sold by iPaisa to you is sold without recourse against iPaisa for any breach of contract by the Telecommunication companies. Any disputes regarding the quality, time (minutes) provided cost, expiration, or other terms of the mobile prepaid recharge purchased must be handled directly between you and the telecommunication company. The terms and conditions set out in this section are applicable, mutatis mutandis, to other prepaid recharge products available on the iPaisa platform including prepaid recharges in relation to DTH, data card and FasTag as well as other prepaid recharge products that may be offered on the iPaisa platform. iPaisa will not be responsible for any failure on the part of any of its recharge partners in effecting a recharge.
              </Typography>
            </ListItem>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>Bill payments</strong>
          <ListItem>
            <Typography>
            iPaisa offers a convenient and secure way to make payments towards identified billers using a valid payment account.<br/>
            Depending upon the iPaisa business partner to whom this specific service is availed by you:
            </Typography>
          </ListItem>
        </Typography>
        <Divider/>

        <Typography variant="body1" color="textPrimary" paragraph>
          <List style={{ pl: 3, listStyleType: 'bullet' }}>
            <ListItem>
              <ListItemText primary="* The specific features of the service may differ" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The number of billers available or the service may differ" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The type and arrange of payment accounts that can be used to issue a payment instruction may differ" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The modes or device over which the service can be accessed may differ." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The charges, fees for availing the service or any aspect of the service may differ." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* Specific details related to these aspects would be available with the iPaisa business partner on their portal/website over which the service is being availed." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* From time-to-time iPaisa at its sole discretion, can add or delete from such a list of billers or types of payment account that can be used in respect of making payments to a biller." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The type and range of payment accounts that can be used for making payments may differ for each biller depending on biller specifications." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* There may be additional fees or charge when using certain type of payments account in respect of a biller." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The terms upon which a payment can be made to a biller can differ depending on whether a wallet, a card or bank account is used to issue the payment instruction." />
            </ListItem>
          </List>

          <ListItem>
            <Typography>
              Further, depending on the specific facilities allowed by iPaisa business partner, payments to a biller can be made either:
            </Typography>
          </ListItem>    
          <Divider/>

          <List sx={{ pl: 3, listStyleType: 'disc' }}>
            <ListItem>
              <ListItemText primary=" * Wallet" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* By issuing a payment instruction for an online debit / charge to payment account or" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* By scheduling an automatic debit to a payment account" />
            </ListItem>
          </List>
        </Typography>
        <Divider/>
        <Typography variant="body1" color="textPrimary" paragraph>
          <Box sx={{ ml: 2 }}>
          In using the bill payment service, you agreed to:
            <Typography variant="body1" color="textPrimary" paragraph>
              Provide true, accurate, current and complete information about yourself, your payment account details, your biller details and maintain and promptly update the registration data, payment data and biller data to keep it true, accurate, current and complete all the times.
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph>
              If you provide any information that is untrue, inaccurate or not current or incomplete, or iPaisa has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete iPaisa has the right to suspend, Block or terminate your account and refuse any and all current or future use of iPaisa services.
            </Typography>
          </Box>
        </Typography>
        <Divider/>

        <Typography variant="body1" color="textPrimary" paragraph>
          iPaisa assumes no responsibility and shall incur no liability if it is unable to affect any payment instruction on the payment date owing to any one or more of the following circumstances.
          <Divider/>

          <List sx={{ pl: 3, listStyleType: 'disc' }}>
            <ListItem>
              <ListItemText primary="* If the payment account has insufficient funds limits to cover for the amount as mentioned in the payment instructions" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The funds available in the payment account are under any encumbrance or charge" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* The payment instruction issued by you is incomplete, inaccurate, invalid or delayed" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* If payment is not processed by biller upon receipt" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* If your bank or National Clearing Centre refuses or delays honouring the payment instruction" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* Circumstances beyond the control of iPaisa, including but not limited to natural disaster, bank strikes, power failure, fire, system failure because of computer or telephone lines breakdown due to an unforeseeable cause or interference from an outside force" />
            </ListItem>
            <ListItem>
              <ListItemText primary="* In case the bill payment is not affected from for any reason you will be informed about the failed payment by SMS and/or an email." />
            </ListItem>
            <ListItem>
              <ListItemText primary="* Once a wallet/Bank/UPI transaction is processed successfully, it cannot be reversed back or refunded in any case." />
            </ListItem>
            <Divider/>

            <ListItem>
              <ListItemText primary="* User Errors" />
            </ListItem>
            <Divider/>

            <ListItem>
              <ListItemText primary="* If any User erroneously make payment to the wrong biller, party or make double payment or make wrong amount, iPaisa will not reimburse you or reverse the payment that you have erroneously made. Your only recourse will be to ask that party, merchant to refund to whom you have sent the payment erroneously." />
            </ListItem>
            <Divider/>

            <ListItem>
              <ListItemText primary="* You agree that in case any amount is transferred erroneously by You to any Merchant, third party platforms or any other person, iPaisa shall not be liable to refund such amount to You in any circumstances." />
            </ListItem>
            <Divider/>

          </List>
        </Typography>

        <Divider/>
        <Typography variant="body1" color="textPrimary" paragraph>
          iPaisa assumes no responsibility and shall incur no liability if it is unable to affect any payment instruction on the payment date owing to any one or more of the following circumstances.
        </Typography>
        <Divider/>

        <Typography variant="body1" color="textPrimary" paragraph>
        <strong>Communication :</strong>
          <ListItem>
            <Typography>
              By accepting the terms and conditions, you accept the following:<br/>
              iPaisa may communicate with you or send alerts on the contact information that you may have provided to iPaisa during the course of your engagement, including but not limited to sign up, transacting or availing any third-party products or services, iPaisa platform or via email or push notifications.
              </Typography> 
          </ListItem>

          <ListItem>
          <Typography>
              iPaisa will make best efforts to provide alerts via SMS/email/push notifications.
              The alerts will be received -in the form of SMS, only if the mobile phone is in ‘on’ mode to receive the SMS, in the form of email, only if the email servers and email IDs are functional and in the form of push notifications, if the user has enabled the receipt of such a notification.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography> 
              If the mobile phone is in ‘off’ mode or if the email servers or IDs are not functional or if the push notifications feature has been turned off then you may not get the alert at all or get delayed messages.
              </Typography>
          </ListItem> 
          <ListItem>
            <Typography> 
              You agree that there can be disruption in communications due to factors that are not under our control, including but not limited to your phone being switched off, incorrect email address, network interruption. You agree not to hold iPaisa liable for non- delivery for any alert or any loss suffered by you due to delay, distortion or failure of communication. 
              </Typography>
            </ListItem> 
            <ListItem>
             <Typography> 
              The SMS/ email alert /push notification service or via other progressive technology provided by iPaisa is an additional facility provided for your convenience and that it may be susceptible to error, omission and / or inaccuracy. In the event that you observe any error in the information provided in the alert, iPaisa shall be immediately informed about the same by you and iPaisa will make the best possible efforts to rectify the error as early as possible. You shall not hold iPaisa liable for any loss /damages, claim expense, including legal cost that may be incurred, suffered by you on account of SMS /email alert / push notification facility. 
              </Typography>
            </ListItem> 
            <ListItem>
              <Typography> 
              The clarity, readability, accuracy and promptness of providing the SMS / email alert / push notification service depend on many factors including the infrastructure and connectivity of the service provider. iPaisa shall not be responsible for any non-delivery, delayed delivery or distortion of the alert in anyway whatsoever.
              </Typography>
            </ListItem> 
            <ListItem>
              <Typography> 
              You acknowledge that you are responsible for the contact details shared with us and shall update us on any change on your contact details. You also authorize us to contact you or communicate with you for any iPaisa service or offers.  iPaisa may use third party service providers to send alerts or communicate with you.  You authorise iPaisa and iPaisa entities to override the DND settings to reach out to you over calls, SMS, emails and any other mode of communication. 
              </Typography>
            </ListItem> 
            <ListItem>
              <Typography> 
              You will identify and hold harmless iPaisa and the SMS or email service provider including its officials from, any damages, claims, demands, proceedings, losses, cost charges, and expenses whatsoever including legal charges and attorney's fees which
              iPaisa or SMS or email service provider may at any time sustain /incur /suffer or to be subjected to as a consequence of, or arising out of any of the following: 
              <ListItem>
                <Typography>
                i){" "} Incorrect number or a number that belongs to an unrelated third party provided by you and / or<br/> 
                ii){" "} the customer receiving any message relating to the reservation number, travel itinerary information, booking confirmation, modification to a ticket, cancellation of ticket, change in bus schedule, delay and/or rescheduling, from iPaisa or /and the SMS/email service provider. 
                </Typography>
              </ListItem>
            </Typography>
          </ListItem>
        </Typography>

        <Divider/>
        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'18px'}}>
        <strong>Third party terms and conditions</strong>
        <Divider/>

          <ListItem>
            <Typography>
              You understand and agree that iPaisa provides third party services via iPaisa platform. iPaisa does not own those services and you may need to accept their respective terms and conditions. You may require to share additional information in order to avail such a products or services provided by third parties on iPaisa platform. iPaisa does not hold any liability on the information collected from you by third party website or application and iPaisa cannot indemnify you for any third-party action. 
            </Typography>
          </ListItem>

          <ListItem>
            <Typography style={{fontSize:'18px', fontWeight:'bold'}}>
              Offers
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
            iPaisa or iPaisa entities may invite you to participate in any offer from time to time. You agree that participating in such an offer is subject to your agreement to the respective offer’s terms and conditions. You also understand that the offers might be provided by third parties on iPaisa platforms and you may require to agree that offers provided to any user, may vary from user to user. 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
            iPaisa reserves the right to disqualify any user from any offer in case the user does not meet the eligibility criteria for such an offer or any other reasons thereof including but not limited to misuse of offer, misrepresentation, fraud or suspicious transactions or activities, including but not limited to the conditions or any at our sole discretion without further notice. 
            </Typography>
          </ListItem>

          <ListItem>
            <Typography style={{fontSize:'18px', fontWeight:'bold'}}>
            Coupon redemption
            </Typography>
          </ListItem>

          <ListItem>
            <Typography>
            Coupon redemption is subject to standard and specified terms and conditions mentioned by respective third party, in addition to the terms and conditions. Coupons are issued on behalf of respective third parties. Hence any damage injuries, losses, incurred by you as a result of using the coupon is not the responsibility of iPaisa and its affiliates and each of their directors, officers, employees. However, the redemption coupons offered by iPaisa will be the sole responsibility of iPaisa.
            </Typography>
          </ListItem>           
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Bus tickets</strong>
        <Typography>
        ipaisa is only a bus ticket agent. It does not operate buses or offers bus transport services. iPaisa has tide up with bus operators and service providers, in order to provide a comprehensive choice of bus operators, departure times and prices to customers. <br/>
        Responsibilities of iPaisa in Bus booking:
        </Typography>
        <Divider/>
        <ListItem>
          <ListItemText primary="• Issuing a valid ticket (ticket that is accepted by the bus operator) for its network of bus operators.">
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText primary="• Providing refund and support in the event of cancellation.">
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText primary="• Providing customer support and information in case of any delays /inconvenience.">
          </ListItemText>
        </ListItem>
        </Typography>
        <Divider/>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
          <strong>iPaisa will not be responsible for the following:</strong>
          <List sx={{ pl: 3, listStyleType: 'disc' }}>
            <ListItem>
              <ListItemText primary="• The bus operators’ bus not departing or reaching on time" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The bus operator’s bus seats etc not being up to the customer’s expectations." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The bus operator’s employees’ unprofessional/unlawful act or conduct" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The bus operator cancels the trip due to any reason" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The baggage of customer getting lost, stolen, damaged" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The bus operator changing the customer’s seat at the last minute to accommodate any of the passengers like Senior citizens, children, pregnant women, persons with disability or any other person in a situation of emergency." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The customer waiting at the wrong boarding point (Note: please call the bus operator to find out the exact boarding point)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The bus operator changing the boarding point and/or using a pick-up vehicle at the boarding point to take customers to the bus departure point" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The arrival and departure times mentioned on the ticket are only tentative timings. However, the bus will not leave the source before the time that is mentioned on the ticket" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Passengers are required to furnish the following at the time of boarding the bus: A soft-copy/a print out of the ticket /received by SMS, email. Identity proof, Aadhar card / driving licence / company ID / card passport pan card or voters ID card. Failing to do so, they may not be allowed to board the bus." />
            </ListItem>
          </List>
        </Typography>
        <Divider/>

        <Typography variant="body1" color="textPrimary" paragraph>
          <strong>Cancellation policy:</strong>
            <ListItem>
              <Typography>
                Passengers are expected to read the ticket cancellation policy carefully. Tickets will be cancelled as per the cancellation policy linked with the ticket. The transaction charges will not be refunded in the event of Ticket cancellation. 
              </Typography>
            </ListItem>

            <ListItem>
            <Typography>
              Refund policy mentioned on the ticket is indicative. The actual cancellation charges are determined by bus operators and bus providers at the actual time of cancellation. iPaisa has no role in governing the cancellation charges. 
              </Typography>
            </ListItem>

            <ListItem>
            <Typography>         
              Cancellation charges are calculated on the actual fare of the ticket. If any discount coupons are used while purchasing the ticket, the discounted value would be used to calculate the refund amount when a ticket is cancelled. 
              </Typography>
            </ListItem>

            <ListItem>
            <Typography>            
              In case of booking confirmation email /SMS gets delayed or fails because of technical reasons or as a result of incorrect email ID /phone number provided by the user, ticket/s will be considered “booked” as long as the ticket shows up on the confirmation page of the iPaisa platform. 
              </Typography>
            </ListItem>

            <ListItem>
              <Typography>
              Please note that iPaisa provides the information regarding amenities, (video/air-conditioner, comfortless of seats etc.) in good faith to help passengers to make an informed decision. These amenities as shown on iPaisa platform have been configured and provided by the bus service provider or bus operator.  Any refunds or claims due to non -functioning or unavailability of these services needs to be settled directly with the bus operator.
              </Typography>
            </ListItem> 

            <ListItem>
              <Typography>            
              iPaisa may communicate with you on the mobile number email, provided by you while registering, for the purpose of collecting feedback from you regarding your travel bookings including but not limited to the bus facilities and/or services of the bus operator.
              </Typography>
            </ListItem>

            <ListItem>
               <Typography>  
               iPaisa is not responsible for any kind of journey inconvenience, injury or death caused during the journey or flowing time from the journey.
              </Typography>
            </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph>
        <strong>Grievance :</strong>
          <ListItem>
            <Typography>
              Grievance and claims related to the bus journey should be reported to iPaisa help team{"  "} {"  "}
              <a href="mailto:help@ipaisa.co.in" style={{ textDecoration: 'underline', color: 'blue' }}>care@ipaisa.co.in</a>{"  "} {"  "} in  within 10 days of your travel date.
            </Typography>
          </ListItem>
            <Divider/>
          <ListItem>
            <Typography style={{fontWeight:'bold', fontSize:'18px'}}>
            Grevience  Manager – Adv. Sheetal Patil 
            </Typography>
          </ListItem>

          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
             Address:
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
              iPaisa 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
              Second Floor, 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
              Golden Empire, 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
              Baner, Pune 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
              411045 
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontWeight:'bold'}}>
            <a href="mailto:help@ipaisa.co.in" style={{ textDecoration: 'underline', color: 'blue' }}>care@ipaisa.co.in</a>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography style={{fontSize:'19px'}} >
              Thank you for your understanding and cooperation.
            </Typography>
          </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Limitation of Liability and/or Damages</strong>
            <ListItem>
              <Typography>
              In no event  iPaisa or its partners, suppliers, licensors, agents ,contractors be liable to for any indirect, consequential, incidental, special or punitive damages ,including without limitation  damages for loss of profits or revenues, business interruption, loss of business opportunity ,loss of data or loss of other economic interests, whether  in contract, negligence ,tort or otherwise, arising from the use / inability to use the services however caused and whether arising in contract, tort, negligence, warranty or otherwise exceed the amount paid by you for using the services giving rise to the cause of action or beyond or in access Rs 100 whichever is less.
              </Typography>
            </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Indemnification</strong>
            <Divider/>

            <ListItem>
              <Typography>
              You agree to Indemnify, save and hold iPaisa, its entities, employees, officers, directors and partners harmless from any and all claims’ losses, damages and liabilities cost and expenses, including without limitation legal fees and expenses arising out of or related to your breach of this terms or use privacy policy and other policies or your violation of any, rules or regulations.
              </Typography>
              </ListItem>

            <ListItem>
              <Typography>
              iPaisa reserves the right at your expense to assume the exclusive defence and control of any matter for which you are required to indemnify iPaisa including rights to settle, and you agree to co-operate with iPaisa defines and settlement of these claims. 
              </Typography>
            </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
          <strong>Disclaimer no warranties </strong>
          <ListItem>
            <Typography>
            You acknowledge that iPaisa is a reseller of mobile recharges and it is not liable for any third-party obligations due to rates, quality and all other instances whether to any such a telecom company’s subscribers or otherwise. You expressly agree that use of the iPaisa services on iPaisa platform is at your sole risk. It is your responsibility to evaluate the accuracy, completeness and usefulness of all the opinions, advice, services, merchandise and other information provided through the site or on the Internet generally. 
           </Typography>
          </ListItem>

          <ListItem>
            <Typography>
            iPaisa and its third-party partners make no warranty, express or implied regarding the quality of services including but not limited to the services meet your requirements, the services will be interrupted timely or error free or any product information or material obtained by you in connection with the service will meet your requirements.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography>
            iPaisa does not authorise anyone to make any warranty on our behalf and you should not rely on any such a statement. This paragraph shall survive termination of its agreement. In no event will iPaisa be liable for any incidental, consequential, or indirect damages including but not limited to damages for loss of profits, business interruption, loss of programs or information whatsoever, arising out of the use of or inability to use the iPaisa platform.

            </Typography>
          </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
          <strong>Termination</strong>
            <ListItem>
              <Typography>
              You agree that iPaisa in its sole discretion , for any or no reason, and without penalty, may suspect or terminate your account, without prior notice and restrict your access to iPaisa application be determined that you have violated  the terms of use and you consent  that in case iPaisa  suffers losses not limited to monetary losses due to your actions we can take injunctive relief or any other legal action as deemed necessary within the said circumstances and iPaisa is not liable for any loss to you due to termination. 
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
              You agree that iPaisa will not be liable to you or any third party for any such a termination or interruption. Any suspected, fraudulent, abusive or illegal activity may be referred to as appropriate law enforcement authorities. 

              </Typography>
            </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Modifications/Amendments of this agreement </strong>
            <ListItem>
              <Typography>
              iPaisa reserves the right to modify, add, change or remove portions of this agreement at any time by posting notification on the iPaisa platform or otherwise communicating the notifications to you. The changes will become effective and shall be deemed accepted by you 24 hours after the initial posting and shall apply immediately on a going forward basis with respect to your use iPaisa platform stop availing the iPaisa services or for payment transactions initiated after the posting date.
              </Typography>
            </ListItem>

            <ListItem>
              <Typography>
              If you do not agree with any such a change, you sole and exclusive remedy is to terminate your use of iPaisa services on its platform. For certain changes iPaisa may be required under applicable law to give you advice notice and iPaisa will comply with such a requirement. Your continued use of iPaisa platform following the posting or changes herein will imply that you accept and agree to the changes. 
              </Typography>
            </ListItem>
            
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
         <strong>Force majeure</strong>
            <ListItem>
              <Typography>
                Any event that is beyond the control of iPaisa including but not limited to war, riots, flood, fire, act of God, explosion, strikes, computer hacking and authorized access to computer data and storage devices, computer crashes, acts state/central Governmental legal or regulatory actions, prohibiting or impending iPaisa entities from performing its respective obligations under the contract. 
              </Typography>
            </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Force majeure</strong>
          <ListItem>
              <Typography>
              If any dispute, or matter arising under or in connection with these terms of use or use of iPaisa platform including the construction, validity or termination of this agreement, performance of the iPaisa platform:
             </Typography>
            </ListItem>

            <ListItem>
              <Typography>
              You agree that any claim or dispute you may have against iPaisa must be resolved by a court having jurisdiction in Pune, India only. You agree to submit to the personal jurisdiction of the courts located within Pune, India only for the purpose of litigating all such claims or disputes. This agreement shall be governed by Indian laws. This paragraph shall survive termination of this agreement. 
              </Typography>
          </ListItem>
        </Typography>

        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Severability </strong>
          <ListItem>
            <Typography>
            If any provision of this agreement is held to be unlawful, void, invalid or otherwise unforeseeable then that provision will be limited or eliminated from this agreement to the minimum extent required and the remaining provisions will remain valid and enforceable. 
            </Typography>
          </ListItem>
        </Typography>
        <Typography variant="body1" color="textPrimary" paragraph style={{fontSize:'19px'}}>
        <strong>Survival  </strong>
          <ListItem>
            <Typography>
              Upon termination of this agreement, any provision which by its nature or express term should survive, will survive such a termination or expiration as applied to transfers and relationship prior to such termination or expiration. 
            </Typography>
          </ListItem>
        </Typography>

      </Box>
    </Container>
  );
}
