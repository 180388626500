import { PAYOUT_SUCCESS, PAYOUT_FAILURE } from '../actionTypes';

const initialState = {
    success: false,
    error: null,
};

const payoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYOUT_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
            };
        case PAYOUT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default payoutReducer;
