import React from 'react';
import { Container, Grid, Typography, Avatar, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import avatarImage from '../../res/NewWebIcons/check.svg';
import avatarImage2 from '../../res/NewWebIcons/password-check.svg';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const LandingPage2 = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    // Get isTxnMpinSet from localStorage
    const isTxnMpinSet = localStorage.getItem('isTxnMpinSet') === 'True'; // Compare string value

    const handleSetPasswordClick = () => {
        navigate('/setipaisampin'); // Navigate to the Set Password route
    };

    const handleForgotPasswordClick = () => {
        navigate('/forgotipaisampin'); // Navigate to the Forgot Password route
    };

    return (
        <Container sx={{ marginTop: 'calc(3% + 56px)', paddingTop:{xs:'3rem', md:'0rem'}, backgroundColor:'#F4F5FA', height:'100vh'}}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Settings</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>IPIN</Typography>
            </Grid>
            <Grid style={{marginTop:'2%'}}>
              <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>IPIN</Typography>
            </Grid>


        <Paper style={{ boxShadow:'none',marginTop:'3%', padding:'2rem', borderRadius:'12px', boxShadow:'none'}}>
            <Grid
                container
                justifyContent="center"
                sx={{
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    justifyContent: 'space-evenly',
                    opacity: 1,
                }}
            >
                {/* Conditionally render Set Password button */}
                {!isTxnMpinSet && (
                    <Grid
                        item
                        onClick={handleSetPasswordClick}
                        sx={{
                            width: '151px',
                            height: '161px',
                            position: 'relative',
                            gap: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#e5e5e5',
                            opacity: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor:"pointer",
                        }}
                    >
                        <Avatar
                            alt="User Avatar"
                            src={avatarImage}
                            sx={{
                                width: '24px',
                                height: '24px',
                                opacity: 1,
                            }}
                        />
                        <Grid sx={{ marginTop: '10%' }}>
                            <Typography style={{alignItems:'center', textTransform:'none', color:'black', fontWeight:'bold'}} >
                                Set IPIN
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                {/* Conditionally render Forgot Password button */}
                {isTxnMpinSet && (
                    <>
                    <Grid
                        item
                        // onClick={handleForgotPasswordClick}
                        sx={{
                            width: '151px',
                            height: '161px',
                            position: 'relative',
                            gap: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#e5e5e5',
                            opacity: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor:"pointer",
                        }}
                    >
                        <Avatar
                            alt="IPIN Check Avatar"
                            src={avatarImage}
                            sx={{
                                width: '24px',
                                height: '24px',
                                opacity: 1,
                            }}
                        />
                        <Grid sx={{ marginTop: '10%' }}>
                            <Typography style={{alignItems:'center', textTransform:'none', color:'black', fontWeight:'bold', cursor:"pointer"}} >
                                Change IPIN
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        onClick={handleForgotPasswordClick}
                        sx={{
                            width: '151px',
                            height: '161px',
                            position: 'relative',
                            gap: '10px',
                            borderRadius: '8px',
                            backgroundColor: '#e5e5e5',
                            opacity: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor:"pointer",
                        }}
                    >
                        <Avatar
                            alt="Password Check Avatar"
                            src={avatarImage2}
                            sx={{
                                width: '24px',
                                height: '24px',
                                opacity: 1,
                            }}
                        />
                        <Grid sx={{ marginTop: '10%' }}>
                            <Typography style={{alignItems:'center', textTransform:'none', color:'black', fontWeight:'bold', cursor:"pointer"}} >
                                Forgot IPIN
                            </Typography>
                        </Grid>
                    </Grid>
                    </>
                )}
            </Grid>
        </Paper>
        </Container>
    );
};

export default LandingPage2;
