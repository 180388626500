import React, { useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';

const SeatSelection = () => {
    const [selectedSeat, setSelectedSeat] = useState(null);

    const seats = [
        { row: 'F', seats: [2000, 400, 400, 400] },
        { row: 'E', seats: [1500, 400, 400, 200] },
        { row: 'D', seats: [2000, 400, 400] },
        { row: 'C', seats: [2000, 400, 400] },
        { row: 'B', seats: [1500, 400, 400] },
        { row: 'A', seats: [null, 400, 400] },
    ];

    const handleSeatSelect = (row, seatIndex) => {
        setSelectedSeat({ row, seatIndex });
    };

    const renderSeats = () => {
        return seats.map((seatRow, rowIndex) => (
            <React.Fragment key={rowIndex}>
                {rowIndex === 3 && <Box sx={{ height: '40px' }} />} {/* Space between third and fourth rows */}
                <Grid container item spacing={1} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography>{seatRow.row}</Typography>
                    </Grid>
                    {seatRow.seats.map((price, seatIndex) => (
                        <Grid item key={seatIndex}>
                            <Button
                                variant={selectedSeat?.row === seatRow.row && selectedSeat?.seatIndex === seatIndex ? 'contained' : 'outlined'}
                                onClick={() => price !== null && handleSeatSelect(seatRow.row, seatIndex)}
                                sx={{
                                    width: '60%',
                                    minWidth: '60px',
                                    minHeight: '60px',
                                    margin: '2px',
                                    backgroundColor: price === null ? 'grey' : 'white',
                                    color: price === null ? 'white' : 'black',
                                    '&:hover': {
                                        backgroundColor: price === null ? 'grey' : 'lightblue',
                                    },
                                }}
                                disabled={price === null}
                            >
                                {price === null ? 'X' : `₹${price}`}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        ));
    };

    return (
        <Box sx={{ p: 1, maxWidth: '600px', margin: 'auto' }}>
            <Typography variant="h6" textAlign="center">Select Your Seat</Typography>
            <Grid container direction="column" spacing={2} sx={{ mt: 2 }}>
                {renderSeats()}
            </Grid>
            {selectedSeat && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" textAlign="center">
                        Selected Seat: Row {selectedSeat.row}, Seat {selectedSeat.seatIndex + 1}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SeatSelection;
