import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Container,
  Card,
  CardContent,
  Box,
  InputAdornment,

} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import { ReactComponent as ChargeBackCard } from '../Assets/Dashboard/ChargeBackCard.svg';

const AdminChargeBackReports = () => {
  const rows = [
    {
      name: "Kamran Khan",
      userId: "12345",
      mobileNo: "9876543210",
      bankName: "HDFC",
      branch: "Aurangabad",
      ifsc: "MAHB021212",
      accountNumber: "98765664546",
    },
  ];

  const lineHeight = '48px';

  return (
  <Container style={{ paddingBottom:'1rem',marginTop: 'calc(3% + 56px)' }}>
    <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back</Typography>
          <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back Report</Typography>
    </Grid>
    <Grid style={{marginTop:'1%'}}>
        <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Charge Back Report</Typography>
    </Grid>

    <Grid style={{marginTop:'3%'}}>
      <Grid container spacing={1}>
        {/* Card Section */}
        <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
          <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none'}}>
            <Card style={{ width: "100%", height: "260px", borderRadius: "12px", alignContent: "center", textAlign: 'center', backgroundColor:'#5FE9F3', boxShadow:'none'}}>
              <CardContent>
                <ChargeBackCard />
                <Typography color="textSecondary" sx={{ color: "#FFFFFF", fontSize: "22px" }} gutterBottom>
                  Charge Back
                </Typography>
                <Typography variant="h5" sx={{ color: "#FFFFFF", fontSize: "30px" }} component="h2" >
                  ₹ 10,000
                </Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        {/* Form Section */}
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField label="Search" fullWidth 
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}/>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel >User Type</InputLabel>
                  <Select label="User Type">
                    <MenuItem value="">Select User Type</MenuItem>
                    <MenuItem value="admin">AREADISTRIBUTOR</MenuItem>
                    <MenuItem value="user">RETAILER</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Users</InputLabel>
                  <Select label="Users">
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="User1">User1</MenuItem>
                    <MenuItem value="User2">User2</MenuItem>
                    <MenuItem value="User3">User3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>

            <Grid item style={{display:'flex', justifyContent:'flex-end', marginTop:'2%'}}>
                <Button
                  startIcon={<SearchIcon />}
                  sx={{
                    height: lineHeight,
                    fontSize: '16px',
                    textTransform: 'none',
                    width: { xs: '100%', md: '18%' },
                    borderRadius: '8px',
                    backgroundColor: "#027BFE",
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: "#027BFE",
                        color: '#FFFFFF',
                    }}}>
                  Search
                </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>

    <Grid style={{marginTop:'3%'}}>
      <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', boxShadow:'none' }}>
        <Table>
        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
            <TableRow>
              <TableCell style={{color:'white'}}>Sr.No</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
              <TableCell style={{color:'white'}}>N/A</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>1</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
                <TableCell>N/A</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Container>
  );
};

export default AdminChargeBackReports;
