// redux/actionTypes.js
export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';
export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const TOGGLE_PARTNER_STATUS = 'TOGGLE_PARTNER_STATUS';
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';
export const SET_ERROR = 'SET_ERROR';
export const SET_BANK_DETAILS = 'SET_BANK_DETAILS';
export const PAYOUT_SUCCESS = 'PAYOUT_SUCCESS';
export const PAYOUT_FAILURE = 'PAYOUT_FAILURE';
export const BULK_PAYOUT_SUCCESS = 'BULK_PAYOUT_SUCCESS';
export const BULK_PAYOUT_FAILURE = 'BULK_PAYOUT_FAILURE';
