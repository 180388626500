import { makeStyles, styled } from "@material-ui/styles";
import {
  Button,
  Container,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

// Custom styled IOSSwitch component
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: "background-color 500ms",
  },
});

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const AllVirtualAcc = () => {
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [accountList, setAccList] = useState([]);
  const classes = useStyles();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchAccList(newPage);
  };

  // Handle toggle change for a specific item
  const handleToggle = async (id, status) => {
    // const newStatus = !status;
    setAccList((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, is_active: !status } : item
      )
    );

    try {
      const jwtToken = localStorage.getItem("jwtToken");

      const response = await axios.post(
        // "https://testapi.ipaisa.co.in/myapp/virtual/changeaccountstatus",

        "https://testapi.ipaisa.co.in/myapp/virtual/changeaccountstatus",
        {
          id: id,
          status: !status,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      console.log(response.data);
      if (response.status !== 200) {
        // toast.error("Error with " + response.status);
        Swal.fire({
          title: "Something Went Wrong..!!",
          text: "With Status Code" + response.status,
          icon: "error",
        });
        fetchAccList(1);
      } else {
        // toast.success("Status of Account is Changed Successfully!!!");
        Swal.fire({
          title: "Success",
          text: `Status of Account is Changed to ${response.data.data.virtual_account.is_active ? "Actived" : "Deactivated"} Successfully!!!`,
          icon: "success",
        });
        fetchAccList(1);
      }
      fetchAccList(1);
      //   console.log(response);
    } catch (e) {}
  };

  const fetchAccList = async (current) => {
    const jwtToken = localStorage.getItem("jwtToken");
    try {
      const response = await axios.get(
        // "https://testapi.ipaisa.co.in/myapp/virtual/fetchallaccounts",

        "https://testapi.ipaisa.co.in/myapp/virtual/fetchallaccounts",
        {
          headers: { Authorization: `Bearer ${jwtToken}`, current: current },
        }
      );

      if (response.status !== 200) {
        // toast.error("Error with " + response.status);
        Swal.fire({
          title: "Something Went Wrong..!!",
          text: "With Status Code" + response.status,
          icon: "error",
        });
      }

      setTotalPages(response.data.data.total_pages);
      var list = response.data.data.results;
      //   console.log(list);
      setAccList(list);
      //   console.log(response);
    } catch (e) {}
  };

  useEffect(() => {
    fetchAccList(currentPage);
  }, []);

  return (
    <>
      <Container
        style={{
          marginBottom: "4%",
          borderRadius: "8px",
          marginTop: "calc(4% + 56px)",
          backgroundColor: "#background: #F5F7FA",
        }}
      >
        <h2>This is All Queen QR List...</h2>

        <TableContainer component={Paper}>
          <Table>
            <TableHead
              style={{
                backgroundColor: "#027BFE",
                color: "white",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Sr no
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Label / Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual UPI Handle / ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Virtual Account Number
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Total Ammount
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountList && accountList.length > 0 ? (
                accountList?.map((item, i) => {
                  return (
                    <TableRow
                      key={item.id} // Use unique key based on the transaction ID
                      className={`${classes.tableRow} ${i % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell align="center">{i + 1}</TableCell>{" "}
                      {/* Serial Number */}
                      <TableCell align="center">{item.label}</TableCell>
                      <TableCell align="center">
                        {item.virtual_upi_handle}
                      </TableCell>
                      <TableCell align="center">
                        {item.virtual_account_number}
                      </TableCell>
                      <TableCell align="center">XXXX</TableCell>
                      <TableCell
                        align="center"
                        // sx={{ width: "100px", height: "200px" }}
                      >
                        <Switch
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "green",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "green",
                              },
                            "& .MuiSwitch-switchBase": {
                              color: "red",
                            },
                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                              backgroundColor: "red",
                            },
                          }}
                          checked={item?.is_active}
                          onChange={() => handleToggle(item.id, item.is_active)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    List Not Available
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {/* <span>
                    Showing Transactions From {pageFrom} To {pageTo}
                  </span> */}

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage <= 1}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages}
                    sx={{ marginLeft: 1 }}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default AllVirtualAcc;
