import { Grid, IconButton, InputBase, Paper } from '@material-ui/core'
import { Box, Button, Container, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from "@mui/icons-material/Search";


const DateSelectorandSearch = () => {
  return (
    <Container style={{marginTop:'20%'}}>
        
            <Paper elevation={2} style={{padding:'2rem', borderRadius:'8px', marginTop:'2%'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="From Date"
                            type="date"
                            // value={fromDate}
                            // onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="To Date"
                            type="date"
                            // value={toDate}
                            // onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '3%' }}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                            <Button 
                            // onClick={handleSearch}
                                sx={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}>
                                Search
                            </Button>
                            <Button
                                // onClick={() => handleClear()}
                                sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Grid
                    style={{
                        display: 'flex',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        maxWidth: '20%',
                        borderRadius: 10,
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        padding: '1%',
                        justifyContent: 'center',
                        marginBottom: '2%',
                        marginTop:'2%'
                    }}
                >
                    <InputBase
                        placeholder="Search"
                        // value={searchQuery}
                        // onChange={(e) => setSearchQuery(e.target.value)}
                        // onKeyDown={(e) => {
                        //     if (e.key === 'Enter') {
                        //         handleSearch(); // Trigger search when Enter is pressed
                        //     }
                        // }}
                        fullWidth
                        style={{ paddingLeft: '3%' }}
                    />
                    <IconButton backgroundColor='textSecondary'
                        // onClick={handleSearch}
                    >
                        <SearchIcon />
                    </IconButton>
                </Grid>
      
    </Container>
  )
}

export default DateSelectorandSearch
