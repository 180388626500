
import { SET_WALLET_BALANCE, SET_ERROR } from '../actionTypes';

const initialState = {
    balance: 0,
    error: null,
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WALLET_BALANCE:
            return {
                ...state,
                balance: action.payload,
                error: null,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default walletReducer; // Make sure to export the reducer here
