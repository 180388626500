
export const statusMessages = {
    200: 'Payout successful',
    400: 'Bad request. Please check your input.',
    401: 'Unauthorized access. Please log in again.',
    402: 'Insufficient balance for Transfer',
    403: 'Unauthorized: Invalid or expired session',
    500: 'Internal server error. Please try again later.',
};

export const getToastMessage = (status, defaultMessage) => {
    return statusMessages[status] || defaultMessage || 'An unexpected error occurred.';
};
