import {
  DELETE_PARTNER,
  DELETE_PARTNER_SUCCESS,
  DELETE_PARTNER_FAILURE,
  FETCH_PARTNERS_SUCCESS,
} from '../actionTypes';

const initialState = {
  loading: false,
  partners: {
    CHANNELPARTNER: [],
    SUPERDISTRIBUTOR: [],
    MASTERDISTRIBUTOR: [],
    AREADISTRIBUTOR: [],
    RETAILER: [],
    ADMIN: [],
  },
  error: null,
};

const deletePartnerFromCategory = (category, userId) =>
  category.filter(partner => partner.userid !== userId);

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PARTNER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: Object.keys(state.partners).reduce((acc, key) => {
          acc[key] = deletePartnerFromCategory(state.partners[key], action.payload);
          return acc;
        }, {}),
      };
    case DELETE_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default partnerReducer;
