import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, TablePagination,
    Container, IconButton,
    TextField,
    Box,
    InputBase,
    Button
} from '@mui/material';
import { toast } from 'react-toastify';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';
import PendingRefundReportsInvoice from './PendingRefundReportsInvoice';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon1 from "@mui/icons-material/Download";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const PendingRefundReport = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [error, setError] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isNextPageEnabled, setIsNextPageEnabled] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const classes = useStyles();
    const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code

    const handleSearch = () => {
        setCurrentPage(0); // Reset to the first page on new search

        // If searchQuery is empty or only whitespace, call the API with page=0 and rowsPerPage=10
        if (!searchQuery.trim()) {
            fetchCompleteData(0, fromDate, toDate, '', 10); // Call API with no search query
        } else {
            fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query
        }
    };

    // Handle search input change (no API call here)
    const handleSearchInput = (event) => {
        const updatedSearchQuery = event.target.value; // Get the updated search query value
        setSearchQuery(updatedSearchQuery); // Update search query state

        // Check if the search query length becomes 0 (i.e., the input is cleared)
        if (updatedSearchQuery.length === 0) {
            // Fetch data with default parameters when search query is cleared
            fetchCompleteData(0, '', '', '', rowsPerPage);
        }
    };


    // Function to fetch completed data from the API
    const fetchCompleteData = async (page, fromDate, toDate, searchQuery, rowsPerPage) => {
        setLoading(true); // Set loading to true before making the API call

        const token = localStorage.getItem('jwtToken');

        // Build the URL parameters conditionally
        let url = 'https://testapi.ipaisa.co.in/myapp/admin/getPending-refundPoutTxns?';


        // Add search query if available
        if (searchQuery.trim()) {
            url += `searchBy=${searchQuery}&`;
        }

        // Add fromDate if available
        if (fromDate) {
            url += `fromDate=${fromDate}&`;
        }

        // Add toDate if available
        if (toDate) {
            url += `toDate=${toDate}&`;
        }

        // Add pagination parameters (page and rowsPerPage)
        url += `page=${page}&size=${rowsPerPage}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.statusCodeValue === 204 || response.data.body.message === "NO_CONTENT") {
                // Handle the case when no content is returned
                console.log('No content available');
                setTransactions([]); // Clear the data state if no content
                setStatusCodeValue(response.data.statusCodeValue);
            } else {
                setTransactions(response.data.body.transactions); // Set the data to the state if content is available
                setTotalRecords(response.data.body.totalRecords || 0); // Assuming totalRecords is provided in the response
            }
        } catch (error) {
            console.error('Error fetching completed transactions:', error);
            setTransactions([]); // Optional: Clear data in case of error
        } finally {
            setLoading(false); // Set loading to false after API call
        }
    };

    useEffect(() => {
        fetchCompleteData(currentPage, fromDate, toDate, searchQuery, rowsPerPage); // Fetch data on component mount or when page/filters change
    }, [currentPage]); // Run when these dependencies change

    const handleClear = () => {
        setFromDate('');
        setSearchQuery('');
        setToDate('');
        setCurrentPage(0); // Reset to first page
        fetchCompleteData(0, '', '', '', rowsPerPage); // Fetch data with default params when cleared
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage); // Update the page state
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setCurrentPage(0); // Reset to first page when rows per page changes
    };

    const handleDownloadInvoice = async (transaction) => {
        const receiptElement = document.getElementById(`receipt-${transaction.id}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${transaction.id}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: `Pending-Refund Receipt-${transaction.id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };


    return (
        <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reports</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pending Refund Report</Typography>
            </Grid>
            <Grid style={{ marginTop: '1%' }}>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Pending Refund Report</Typography>
            </Grid>

            <Paper
                sx={{
                    padding: '2rem',
                    borderRadius: '8px',
                    marginTop: '2%',
                    overflow: 'hidden', // Prevent content overflow
                    boxShadow: 'none',
                }}
            >
                <Grid container spacing={2}>
                    {/* Date Fields */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    {/* Search and Buttons */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginTop: '1%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            gap: '1rem',
                        }}
                    >
                        {/* Search Field */}
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                maxWidth: { xs: '100%', sm: '50%' },
                                borderRadius: '10px',
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                padding: '0.5rem',
                            }}
                        >
                            <InputBase
                                fullWidth
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearchInput} // Updates search query
                            />
                            <IconButton color="primary" onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        {/* Action Buttons */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap', // Wrap buttons on smaller screens
                                gap: '1rem',
                            }}
                        >
                            <Button
                                onClick={handleSearch}
                                sx={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: { xs: '100%', sm: '120px' }, // Responsive width
                                }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={handleClear}
                                sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: { xs: '100%', sm: '120px' }, // Responsive width
                                }}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Grid style={{ marginTop: '1%' }}>
                <TableContainer
                    component={Paper}
                    sx={{
                        marginTop: '2%',
                        maxWidth: '100%',
                        overflowX: 'auto',
                        borderRadius: '12px 12px 0px 0px',
                        boxShadow: 'none',
                    }}
                >
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <Table>
                            <TableHead sx={{ backgroundColor: '#027BFE' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Sr.no</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>ID</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>External Reference</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payer Account</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payee Account</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payee Name</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transaction Value</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transfer Mode</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Timestamp</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Invoice</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((transaction, index) => (
                                    <TableRow key={transaction.id}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell>{(currentPage) * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{transaction.id || 'N/A'}</TableCell>
                                        <TableCell>{transaction.externalRef || 'N/A'}</TableCell>
                                        <TableCell>{transaction.payerAccount || 'N/A'}</TableCell>
                                        <TableCell>{transaction.payeeAccount || 'N/A'}</TableCell>
                                        <TableCell>{transaction.payeeName || 'N/A'}</TableCell>
                                        <TableCell>{transaction.txnValue || 'N/A'}</TableCell>
                                        <TableCell>{transaction.transfer_mode || 'N/A'}</TableCell>
                                        <TableCell>{new Date(transaction.timestamp).toLocaleString() || 'N/A'}</TableCell>
                                        <TableCell style={{ color: 'darkgoldenrod', fontWeight: 'bold' }}>{transaction.status || 'N/A'}</TableCell>
                                        <TableCell align="center">
                                            <DownloadIcon
                                                onClick={() => handleDownloadInvoice(transaction)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${transaction.id}`}>
                                                <PendingRefundReportsInvoice data={transaction} />
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {!loading && !error && (
                    <TablePagination
                        component="div"
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        sx={{ marginTop: 2 }}
                        nextIconButtonProps={{
                            disabled: (currentPage + 1) * rowsPerPage >= totalRecords || statusCodeValue === 204,
                        }}
                        backIconButtonProps={{
                            disabled: currentPage === 0,
                        }}
                    />
                )}
            </Grid>
        </Container>
    );
};

export default PendingRefundReport;
