import { useState } from 'react';
import { Box, Button, Grid, Paper, Typography, MenuItem, TextField } from '@material-ui/core';
import { Container } from '@mui/material';
import { NewBBPSIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";

const NewLandline = () => {
  // State management for dropdown and input fields
  const [operator, setOperator] = useState('');
  const [landlineNumber, setLandlineNumber] = useState('');
  const [showLandlineInput, setShowLandlineInput] = useState(false);
  const [showProceedFields, setShowProceedFields] = useState(false);
  const [amount, setAmount] = useState('');
  const [mode, setMode] = useState('');

  // Operator options array
  const operators = [
    'Airtel Landline',
    'BSNL Landline - Corporate',
    'BSNL Landline - Individual'
  ];

  // Handle operator selection
  const handleOperatorChange = (e) => {
    setOperator(e.target.value);
    setShowLandlineInput(true);
    setShowProceedFields(false); // Reset to hide the second form
    setLandlineNumber(''); // Reset the input field
  };

  // Handle Proceed button click
  const handleProceedClick = () => {
    setShowProceedFields(true);
  };

  // Handle Reset button click
  const handleResetClick = () => {
    setOperator('');
    setLandlineNumber('');
    setAmount('');
    setMode('');
    setShowLandlineInput(false);
    setShowProceedFields(false);
  };

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '11px' }}>
            Bill Payment
          </Typography>
          <PartnerArrowIcon />
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '11px' }}>
            Landline Postpaid
          </Typography>
        </Grid>

        <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
            Landline Postpaid
          </Typography>
          <Typography>
            <NewBBPSIcon/>
          </Typography>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: '2%' }}>
        <Paper elevation={2} style={{ padding: '2rem', borderRadius: '10px' }}>
          {/* Conditional rendering for Operator and Landline fields */}
          {!showProceedFields && (
            <>
              {/* Dropdown for operator selection */}
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  label="Operator Name"
                  value={operator}
                  onChange={handleOperatorChange}
                  fullWidth
                  variant="outlined"
                >
                  {operators.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                      {operator}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Landline input field */}
              {showLandlineInput && (
                <Grid item xs={12} md={6} style={{ marginTop: '16px' }}>
                  <TextField
                    label="Landline Number with STD Code*"
                    value={landlineNumber}
                    onChange={(e) => setLandlineNumber(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              )}

              {/* Buttons for Proceed and Reset */}
              <Grid container spacing={2} style={{ marginTop: '16px' }}>
                <Grid item xs={12} md={2}>
                  <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#4B6F8D99',
                      textTransform: 'none',
                      width: '100%',
                    }}
                    onClick={handleResetClick}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#027BFE',
                      textTransform: 'none',
                      width: '100%',
                    }}
                    onClick={handleProceedClick}
                    disabled={!landlineNumber}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          {/* Conditional rendering for Amount and Mode fields after Proceed */}
          {showProceedFields && (
            <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                    label="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    fullWidth
                    variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                    select
                    label="Mode"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    fullWidth
                    variant="outlined"
                    >
                    <MenuItem value="Credit Card">Credit Card</MenuItem>
                    <MenuItem value="Debit Card">Debit Card</MenuItem>
                    <MenuItem value="Net Banking">Net Banking</MenuItem>
                    </TextField>
                </Grid>
            </Grid>

              {/* Buttons for Pay and Reset */}
              <Grid container spacing={2} style={{ marginTop: '16px' }}>
                <Grid item xs={12} md={2}>
                  <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#4B6F8D99',
                      textTransform: 'none',
                      width: '100%',
                    }}
                    onClick={handleResetClick}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#027BFE',
                      textTransform: 'none',
                      width: '100%',
                    }}
                    disabled={!amount || !mode}
                  >
                    Pay
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </Container>
  );
};

export default NewLandline;
