import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  CardMedia,
  Container,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ForgotMpin from "../WebSite/ForgotMpin";
import iPaisaMobScreenImage from "../Assets/login/iPaisaMobScreen.png";
import IndianFlag from "../Assets/login/IndianFlag.png";
import EdsomImg from "../Assets/login/EdsomImg.jpeg";
import logo from "../Assets/iPaisaLogo/iPaisaLogo2.png";
import { EdsomIcon, MadeInIndiaIcon } from "../../res/icons";

const validationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^\d+$/, "Mobile number must be digits only")
    .length(10, "Mobile number must be exactly 10 digits"),
  mpin: Yup.string()
    .required("MPIN is required")
    .matches(/^\d{0,4}$/, "MPIN must be 0-4 digits long")
    .max(4, "MPIN must be at most 4 digits long"),
});

const setMpinSchema = Yup.object({
  newMpin: Yup.string()
    .required("New MPIN is required")
    .matches(/^\d{0,4}$/, "MPIN must be 0-4 digits long")
    .max(4, "MPIN must be at most 4 digits long"),
  confirmMpin: Yup.string()
    .oneOf([Yup.ref("newMpin"), null], "MPINs must match")
    .required("Confirm MPIN is required"),
});

const SignIn = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(null);
  const [showForgotMpin, setShowForgotMpin] = useState(false);
  const [showSetMpin, setShowSetMpin] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [setMpin, setSetMpin] = useState(null);
  const navigate = useNavigate();

  // Handler to reset the forgot MPIN state
  const handleForgotMpinSuccess = () => {
    setShowForgotMpin(false);
    toast.success("Now You Can Login!");
    navigate("/signin"); // Redirect to SignIn page
  };

  useEffect(() => {
    if (isMobileValid === true) {
      toast.success("Mobile number is valid");
    } else if (isMobileValid === false) {
      // toast.error("Mobile number is already registered");
    }
  }, [isMobileValid]);

  const handleLogin = useCallback(
    async (values, { setSubmitting }) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          "https://testapi.ipaisa.co.in/myapp/auth/signin",
          values
        );

        // Check for 'Bad Credentials' response
        if (
          response.data.success === false &&
          response.data.message === "Bad Credentials"
        ) {
          toast.error("Invalid mobile number or MPIN. Please try again.");
          return; // Prevent navigation to dashboard
        }

        // Check for 'INACTIVE' status
        if (response.data.status === "INACTIVE") {
          toast.error("Your account is inactive. Please contact support.");
          return; // Prevent navigation to dashboard
        }

        // Proceed with setting items in localStorage and navigating if login is successful
        localStorage.setItem("jwtToken", response.data.token);
        localStorage.setItem("uType", response.data.userType);
        localStorage.setItem(
          "username",
          response.data.firstName + " " + response.data.lastName
        );
        localStorage.setItem("bulkPayout", response.data.bulkPayout);
        localStorage.setItem("mobileNumber", values.mobileNumber);
        localStorage.setItem("mpin", values.mpin);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("status", response.data.status);
        localStorage.setItem("isTxnMpinSet", response.data.isTxnMpinSet);
        localStorage.setItem("commORSur", response.data.commORSur);
        localStorage.setItem("firstname", response.data.firstName);
        localStorage.setItem("lastname", response.data.lastName);

        // Only call WhatsApp API if isFirstLogin is true
        if (response.data.isFirstLogin === "TRUE") {
          await axios.post(
            "https://testapi.ipaisa.co.in/myapp/auth/whatsapp/msg",
            {
              to: values.mobileNumber,
              text1: response.data.firstName + " " + response.data.lastName,
              text2: values.mobileNumber,
              text3: values.mpin,
            }
          );
        }

        toast.success("Login successful");
        navigate("/dashboard");
      } catch (error) {
        console.error("Error logging in:", error);
        toast.error("Enter valid Mobile Number or Mpin");
      } finally {
        setIsSubmitting(false);
        setSubmitting(false);
      }
    },
    [navigate, setMpin]
  );

  const validatemobileno = useCallback(async (mobile) => {
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/validatemobilenumber",
        { mobileno: mobile }
      );

      const isValidMobile = response.data.mobileValidate;
      setIsMobileValid(isValidMobile);

      if (isValidMobile) {
        setSetMpin(response.data.setMpin); // Store the setMpin value for later use

        if (response.data.setMpin === false) {
          setShowSetMpin(true); // Prompt to set MPIN
        } else {
          setShowSetMpin(false); // Proceed to login with MPIN
        }
      } else {
        setShowSetMpin(false); // Mobile number not registered
      }
    } catch (error) {
      console.error("Error validating mobile number:", error);
      setIsMobileValid(false);
    }
  }, []);

  const handleSetMpin = useCallback(
    async (values, { setSubmitting }) => {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          "https://testapi.ipaisa.co.in/myapp/auth/setmpin",
          { mobileno: mobileNumber, mpin: values.newMpin }
        );
        if (response.data.success) {
          toast.success("MPIN set successfully");
          setShowSetMpin(false);
          navigate("/signin"); // Redirect to sign-in page
        }
      } catch (error) {
        console.error("Error setting MPIN:", error);
        toast.error("Error setting MPIN");
      } finally {
        setIsSubmitting(false);
        setSubmitting(false);
      }
    },
    [mobileNumber, navigate]
  );

  return (
    <Grid container style={{ height: "100vh" }}>
      {/* <img src={IndianFlag} alt="Indian Flag" style={{
        position: 'absolute',
        top: '4%',
        right: '2%',
        width: '3%', // Adjust the width as needed
        height: 'auto'
      }} /> */}
      <ToastContainer />
      <Grid item xs={12} md={6} style={leftPanelStyle}>
        <LeftPanel />
      </Grid>
      <Grid item xs={12} md={6} style={rightPanelStyle}>
        <RightPanel
          handleForgotMpinSuccess={handleForgotMpinSuccess} // Pass the handler
          showForgotMpin={showForgotMpin}
          setShowForgotMpin={setShowForgotMpin}
          handleLogin={handleLogin}
          validatemobileno={validatemobileno}
          isSubmitting={isSubmitting}
          isMobileValid={isMobileValid}
          showSetMpin={showSetMpin}
          handleSetMpin={handleSetMpin}
          setMobileNumber={setMobileNumber}
          mobileNumber={mobileNumber} // Pass mobileNumber to RightPanel
        />
      </Grid>
    </Grid>
  );
};

const leftPanelStyle = {
  backgroundColor: "#3029d9",
};

const rightPanelStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
};

const LeftPanel = React.memo(() => (
  <div>
    <div style={{ textAlign: "center", marginTop: "1%" }}>
      <img
        src={iPaisaMobScreenImage}
        style={{ maxWidth: "62.5%" }}
        alt="Screenshots"
      />
    </div>
  </div>
));

const RightPanel = React.memo(
  ({
    handleForgotMpinSuccess,
    showForgotMpin,
    setShowForgotMpin,
    handleLogin,
    validatemobileno,
    isSubmitting,
    isMobileValid,
    showSetMpin,
    handleSetMpin,
    setMobileNumber,
    mobileNumber,
  }) => (
    <>
      {/* <Container style={{ marginTop: 'calc(3% + 56px)' }}>
  <Grid sx={{  marginTop: {xs: '-13%', md:'-5%'}, marginRight: {xs: '95%', md:'100%'}, display:'flex',         position: 'absolute',
}}>
    <EdsomIcon/>
  </Grid>
  </Container> */}

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        mb={2}
      >
        {/* <EdsomIcon style={{ marginRight: 'auto', marginLeft: 0 }} /> */}
        <img
          src={EdsomImg}
          alt="Edsom Logo"
          style={{
            marginRight: "auto",
            marginLeft: 0,
            width: "7%",
            height: "auto",
          }}
        />
        <img
          src={IndianFlag}
          alt="Indian Flag"
          style={{
            width: "6%",
            height: "auto",
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="400px"
        padding="0px 20px 20px 20px"
        sx={{ paddingTop: { xs: "", md: "6%" } }}
      >
        <CardMedia
          component="img"
          image={logo}
          alt="iPaisa Logo"
          style={{ backgroundColor: "transparent", borderRadius: "50%" }}
          sx={{
            height: { xs: 50, md: 80 },
            width: { xs: 50, md: 80 },
            mt: { xs: 1, md: 1 },
            // marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
          }}
        />
        <Typography
          variant="h5"
          style={{
            marginBottom: "1%",
            color: "#0078FF",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "1%",
          }}
        >
          Sign In
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: "2%", textAlign: "center" }}
        >
          Welcome to iPaisa
        </Typography>
        {showForgotMpin ? (
          <ForgotMpin handleMpinSuccess={handleForgotMpinSuccess} /> // Pass the handler
        ) : showSetMpin ? (
          <SetMpinForm
            handleSetMpin={handleSetMpin}
            isSubmitting={isSubmitting}
          />
        ) : (
          <SignInForm
            handleLogin={handleLogin}
            validatemobileno={validatemobileno}
            isSubmitting={isSubmitting}
            isMobileValid={isMobileValid}
            setShowForgotMpin={setShowForgotMpin}
            setMobileNumber={setMobileNumber}
          />
        )}
      </Box>
    </>
  )
);

const SignInForm = ({
  handleLogin,
  validatemobileno,
  isSubmitting,
  isMobileValid,
  setShowForgotMpin,
  setMobileNumber,
}) => (
  <Formik
    initialValues={{ mobileNumber: "", mpin: "" }}
    validationSchema={validationSchema}
    onSubmit={handleLogin}
  >
    {({ errors, touched, setFieldValue }) => {
      const handleInputChange = (e, field, maxLength) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

        if (numericValue.length <= maxLength) {
          setFieldValue(field, numericValue);
          if (field === "mobileNumber") {
            setMobileNumber(numericValue);
            if (numericValue.length === 10) {
              validatemobileno(numericValue);
            }
          }
        }
      };

      return (
        <Form style={{ width: "90%" }} autoComplete="off">
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mobileNumber"
            label="Mobile Number"
            name="mobileNumber"
            autoComplete="off"
            autoFocus
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="mobileNumber" />}
            error={touched.mobileNumber && Boolean(errors.mobileNumber)}
            onChange={(e) => handleInputChange(e, "mobileNumber", 10)}
          />
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="mpin"
            label="MPIN"
            type="password"
            id="mpin"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="mpin" />}
            error={touched.mpin && Boolean(errors.mpin)}
            onChange={(e) => handleInputChange(e, "mpin", 4)}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            marginBottom="2%"
          >
            <Button
              style={{ color: "#0078FF", textTransform: "none" }}
              onClick={() => setShowForgotMpin(true)}
            >
              Forgot MPIN?
            </Button>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{
              height: "50px",
              backgroundColor: "#0078FF",
              borderRadius: "5px",
              fontWeight: "1000",
              textTransform: "none",
            }}
            disabled={isSubmitting || isMobileValid === false}
          >
            Login
          </Button>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            marginTop="5%"
          >
            <MadeInIndiaIcon style={{ width: "15%", height: "auto" }} />
          </Box>
        </Form>
      );
    }}
  </Formik>
);

const SetMpinForm = ({ handleSetMpin, isSubmitting }) => (
  <Formik
    initialValues={{ newMpin: "", confirmMpin: "" }}
    validationSchema={setMpinSchema}
    onSubmit={handleSetMpin}
  >
    {({ errors, touched, setFieldValue }) => {
      const handleInputChange = (e, field, maxLength) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

        if (numericValue.length <= maxLength) {
          setFieldValue(field, numericValue);
        }
      };

      return (
        <Form style={{ width: "100%" }} autoComplete="off">
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="newMpin"
            label="New MPIN"
            name="newMpin"
            type="password"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="newMpin" />}
            error={touched.newMpin && Boolean(errors.newMpin)}
            onChange={(e) => handleInputChange(e, "newMpin", 4)}
          />
          <Field
            as={TextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirmMpin"
            label="Confirm MPIN"
            name="confirmMpin"
            type="password"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            helperText={<ErrorMessage name="confirmMpin" />}
            error={touched.confirmMpin && Boolean(errors.confirmMpin)}
            onChange={(e) => handleInputChange(e, "confirmMpin", 4)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{
              height: "50px",
              backgroundColor: "#0078FF",
              borderRadius: "5px",
              fontWeight: "1000",
              textTransform: "none",
            }}
            disabled={isSubmitting}
          >
            Set MPIN
          </Button>
        </Form>
      );
    }}
  </Formik>
);

export default SignIn;
