import { Container, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import ManageAPI from './ManageAPI'
import { FilterIcon } from '../../res/NewWebIcons';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    PayInText: {
        color: 'green',
    },
    PayOutText: {
        color: 'red',
      },
  }));
const APITable = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState(''); 


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option) => {
        setAnchorEl(null);
        if (option) {
            setFilter(option);
            // Call your API here based on the selected option
        }
    };

    const classes = useStyles();
    const theme = useTheme();


    const [searchQuery, setSearchQuery] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [userType, setUserType] = useState('');

    const channelPartners = [
        {name: 'John Doe', type: 'Pay-In',  status: 'Active' },
        {name: 'Jane Smith', type: 'Pay-Out',  status: 'Inactive' },
        {name: 'Mike Johnson', type: 'Pay-In',  status: 'Active' },
        {name: 'Emily Davis', type: 'Pay-Out',  status: 'Inactive' },
      ];

    const filteredData = channelPartners.filter((row) => {
        return Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <ManageAPI/>

        <Grid style={{ marginTop: '4%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>API</Typography>
            <Grid style={{ cursor: 'pointer' }} onClick={handleClick}>
                <FilterIcon />
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
            >
                <MenuItem onClick={() => handleClose('Pay-In')} style={{color:'green', fontSize:'12px'}}>Pay-In</MenuItem>
                <MenuItem onClick={() => handleClose('Pay-Out')} style={{color:'red', fontSize:'12px'}}>Pay-Out</MenuItem>
            </Menu>
        </Grid>

        <Grid style={{ marginTop: '2%' }}>
            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
              <Table>
                <TableHead style={{ backgroundColor: '#027BFE' }}>
                  <TableRow>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Sr.No</TableCell>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Name</TableCell>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Type</TableCell>
                    <TableCell sx={{ color: '#FFFFFF', textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredData.map((partner, index, txn) => (
                    <TableRow key={partner.userId} sx={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#D0EFFF' }}>
                      <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{partner.name}</TableCell>
                      <TableCell className={partner.type === 'Pay-In' ? classes.PayInText : classes.PayOutText} sx={{ textAlign: 'start', fontSize: { xs: '9px', sm: '10px', md: '11px' } }} >{partner.type}</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontSize: { xs: '9px', sm: '10px', md: '11px' } }}>{partner.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
    </Container>
  )
}

export default APITable
