import { Grid, InputLabel, Select } from '@material-ui/core';
import { FormControl, MenuItem, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

const RetailersPerformanceGraph = () => {
    const [data, setData] = useState({
        ChannelPartner: 20,
        SuperDistributor: 30,
        MasterDistributor: 10,
        AreaDistributor: 25,
        Distributor: 15,
        Retailer: 5,
    });
    
    const [timeRange, setTimeRange] = useState('Today');

    const getBarChartData = (range) => {
        switch (range) {
            case 'One Week':
                return [data.ChannelPartner * 2, data.SuperDistributor * 2, data.MasterDistributor * 2, data.AreaDistributor * 2, data.Distributor * 2, data.Retailer * 2];
            case 'One Month':
                return [data.ChannelPartner * 4, data.SuperDistributor * 4, data.MasterDistributor * 4, data.AreaDistributor * 4, data.Distributor * 4, data.Retailer * 4];
            case 'Six Months':
                return [data.ChannelPartner * 6, data.SuperDistributor * 6, data.MasterDistributor * 6, data.AreaDistributor * 6, data.Distributor * 6, data.Retailer * 6];
            default:
                return [data.ChannelPartner, data.SuperDistributor, data.MasterDistributor, data.AreaDistributor, data.Distributor, data.Retailer];
        }
    };

    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };

    const barChartData = {
        labels: ['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5'],
        datasets: [
            {
                label: 'Services',
                data: getBarChartData(timeRange),
                backgroundColor: [
                    '#092E1F',
                    '#8F00FF',
                    '#B25AEF',
                    '#FF7B31',
                    '#00DDE5',
                ],
                hoverBackgroundColor: [
                    '#092E1F',
                    '#8F00FF',
                    '#B25AEF',
                    '#FF7B31',
                    '#00DDE5',
                ],
            },
        ],
    };

    return (
        <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '18px', boxShadow:'none'}}>
            <Grid style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Services</Typography>
                <Grid>
                    <FormControl>
                        <Select
                            labelId="time-range-label"
                            value={timeRange}
                            onChange={handleTimeRangeChange}
                            style={{ fontSize: '16px', padding: '5px' }}
                            label="Time Range"
                        >
                            <MenuItem value="Today">Today</MenuItem>
                            <MenuItem value="One Week">One Week</MenuItem>
                            <MenuItem value="One Month">One Month</MenuItem>
                            <MenuItem value="Six Months">Six Months</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>

            <Grid container spacing={2} direction="column" style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                    <div style={{ position: 'relative', height: '300px' }}> {/* Fixed height for the chart */}
                        <Bar
                            data={barChartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RetailersPerformanceGraph;
