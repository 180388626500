import { Box, Button, Container, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { makeStyles } from '@mui/styles';
import { getBankDetails, addBeneficiary, verifyBankDetails } from "./api";
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import Failedtoverify from "./Failedtoverify";
import Successtoverify from "./Successtoverify";
import AddBeneSuccessPop from './AddBeneSuccessPop';
import Failedtoaddbene from './Failedtoaddbene';
import AccPresentPopup from './AccPresentPopup';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { initiatePayout } from '../../redux/actions/payoutActions';
import PayoutPopup from './PayoutPopup';
import PayoutFailedPopup from './PayoutFailedPopup';
import InsufficiantB from './InsufficiantB';
import DeleteBeneficiarySucc from './DeleteBeneficiarySucc';
import DeleteBeneficiaryFail from './DeleteBeneficiaryFail';
import { Delete } from '@mui/icons-material';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: '#D0EFFF',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  }
}));

const NewAddBeneficiary = () => {

  const classes = useStyles();
  const [bankDetails, setBankDetails] = useState({
    name: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountNumber: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [verifyResult, setVerifyResult] = useState(null);
  const [failedtoverify, setFailedtoverify] = useState(false);
  const [successtoverify, setSuccesstoverify] = useState(false);
  const [addbenesuccess, setAddbenesuccess] = useState(false);
  const [failedtoaddbene, setFailedtoaddbene] = useState(false);
  const [accPresentPopup, setAccPresentPopup] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false); // New state for verification success
  const [loadingPay, setLoadingPay] = useState(false);
  const [showSuccessPayout, setShowSuccessPayout] = useState(false);
  const [showFailedPayout, setShowFailedPayout] = useState(false);
  const [insufficiantB, setInsufficiantB] = useState(false);
  const [deleteBeneSuccess, setDeleteBeneSuccess] = useState(false);
  const [deleteBeneFail, setDeleteBeneFail] = useState(false);
  const [verifiedName, setVerifiedName] = useState("");
  const bankdata = {
    name: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountNumber: "",
  }

  const handleClose = () => {
    setFailedtoverify(false);
    setSuccesstoverify(false);
    setAddbenesuccess(false);
    setFailedtoaddbene(false);
    setAccPresentPopup(false);
    setShowSuccessPayout(false);
    setShowFailedPayout(false);
    setInsufficiantB(false);
    setDeleteBeneSuccess(false);
    setDeleteBeneFail(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getBankDetails(setBankDetails, setStatusMessage);
  }, []);

  const validationSchema = Yup.object({
    // name: Yup.string().required("Name is required"),
    bankName: Yup.string().required("Bank Name is required"),
    branch: Yup.string().required("Branch is required"),
    ifsc: Yup.string().matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code"),
    accountNumber: Yup.string()
      .matches(/^[0-9]+$/, "Account Number must contain only digits")
      .required("Account Number is required"),
  });

  const initialValues = {
    name: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountNumber: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    console.log(values);
    addBeneficiary(values, setSubmitting, () => getBankDetails(setBankDetails, setStatusMessage), setAddbenesuccess, setFailedtoaddbene, setAccPresentPopup);
  };

  const handleCancel = () => {
    // navigate("/navbar");
  };



  const handleDeleteBeneficiary = async (accountNo) => {
    try {
      const token = localStorage.getItem('jwtToken'); // Ensure you have the JWT token stored in local storage
      const response = await axios.delete('https://testapi.ipaisa.co.in/myapp/auth/deleteBeneficiary', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        data: {
          accountNo: accountNo,
        },
      });

      // Check if the response indicates success
      if (response.status === 200 && response.data.statusCodeValue === 200) {
        setDeleteBeneSuccess(true);
        toast.success(response.data.body.message); // Display success message
        // Optionally, refresh the bank details or update the UI accordingly
        getBankDetails(setBankDetails, setStatusMessage); // Refresh the list if necessary
      }
    } catch (error) {
      console.error('Error deleting beneficiary:', error.response ? error.response.data : error.message);
      setDeleteBeneFail(true);
      toast.error("Error deleting beneficiary: " + (error.response ? error.response.data.body.message : error.message));
    }
  };


  const handleVerifyBank = async (accountNumber, ifsc) => {
    setLoadingVerify(true);
    try {
      const response = await verifyBankDetails(accountNumber, ifsc);
      if (response.body.body.status === "Transaction Successful") {
        const userName = response.body.body.UserName;
        initialValues.name = userName;

        setBankDetails((prevDetails) =>
          (Array.isArray(prevDetails) ? prevDetails : []).map((bank) =>
            bank.accountNumber === accountNumber
              ? { ...bank, name: userName }
              : bank
          )
        );

        setVerifiedName(userName);
        setVerificationSuccess(true);
        toast.success("Verification Successful");
        console.log(bankDetails);
      } else {
        toast.error("Verification Failed: " + response.body.body.status);
        setVerificationSuccess(false);
      }
    } catch (error) {
      toast.error("Error verifying bank details");
      setVerificationSuccess(false);
    } finally {
      setLoadingVerify(false);
    }
  };
  // const handleSearchChange = async (event) => {
  //   const value = event.target.value;
  //   setSearchQuery(value);

  //   if (value.trim() === '') {
  //     // If search query is empty, fetch all records using the `getBankDetails` API
  //     try {
  //       setStatusMessage('Fetching all records...');
  //       await getBankDetails(setBankDetails, setStatusMessage); // Fetch all records
  //       setFilteredBankDetails(bankDetails); // Show all records
  //       setStatusMessage('');
  //     } catch (error) {
  //       console.error('Error fetching all records:', error);
  //       setStatusMessage('Error fetching all records. Please try again.');
  //     }
  //   } else {
  //     // Perform client-side filtering based on `bankDetails`
  //     const filteredData = bankDetails.filter((bank) =>
  //       bank.bankName?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.ifsc?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.branch?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.accountNumber?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.user?.userid?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.user?.mobileNumber?.toLowerCase().includes(value.toLowerCase()) ||
  //       bank.user?.fullName?.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setFilteredBankDetails(filteredData);

  //     // Optionally, you can make a search API call for more accurate results
  //     try {
  //       setStatusMessage(`Searching for: ${value}...`);
  //       const token = localStorage.getItem('jwtToken');
  //       const response = await fetch(
  //         `https://testapi.ipaisa.co.in/myapp/auth/searchInBenificiary?searchBy=${encodeURIComponent(value)}`,
  //         {
  //           method: 'GET',
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error('Failed to fetch search results');
  //       }

  //       const searchResults = await response.json();
  //       setFilteredBankDetails(searchResults); // Update filtered results with API data
  //       setStatusMessage('');
  //     } catch (error) {
  //       console.error('Error searching records:', error);
  //       setStatusMessage('Error searching records. Please try again.');
  //     }
  //   }
  // };


  // State for filtered records
  const [filteredBankDetails, setFilteredBankDetails] = React.useState(bankDetails);
  useEffect(() => {
    const fetchAllRecords = async () => {
      if (!searchQuery.trim()) {
        setStatusMessage('Fetching all records...');
        try {
          await getBankDetails(setBankDetails, setStatusMessage); // Fetch all records
          setFilteredBankDetails(bankDetails); // Update the filtered list
          setStatusMessage(''); // Clear status message
        } catch (error) {
          console.error('Error fetching all records:', error);
          setStatusMessage('Error fetching all records. Please try again.');
        }
      }
    };

    fetchAllRecords();
  }, [searchQuery, getBankDetails, setBankDetails, setFilteredBankDetails]);

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      setStatusMessage(`Searching for: ${searchQuery}...`);
      const token = localStorage.getItem('jwtToken');
      try {
        const response = await fetch(
          `https://testapi.ipaisa.co.in/myapp/auth/searchInBenificiary?searchBy=${encodeURIComponent(searchQuery)}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch search results');
        }

        const result = await response.json();
        const searchResults = result.body?.data || [];
        setFilteredBankDetails(searchResults);
        console.log(searchResults, 'search results');
        setStatusMessage('');
      } catch (error) {
        console.error('Error fetching search results:', error);
        setStatusMessage('Error fetching search results. Please try again.');
      }
    }
  };



  // Sync `filteredBankDetails` with `bankDetails`
  React.useEffect(() => {
    setFilteredBankDetails(bankDetails);
  }, [bankDetails]);


  // Update `filteredBankDetails` whenever `bankDetails` changes
  React.useEffect(() => {
    setFilteredBankDetails(bankDetails);
  }, [bankDetails]);

  const dispatch = useDispatch();

  const handlePayClick = async (values) => {
    // setPendingSubmission(values); // Store form values for later submission

    try {
      // Fetch active payout provider
      const token = localStorage.getItem('jwtToken'); // Adjust if you store the token differently
      const response = await axios.get(
        'https://testapi.ipaisa.co.in/myapp/auth/get-active-payout-provider',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const providerData = response.data?.data || {};
        const activeProvider = providerData.providerName;

        console.log('Active Gateway Provider:', activeProvider);

        // Save provider name in localStorage for later use
        localStorage.setItem('activeProvider', activeProvider);

        // Determine which modal to open based on MPIN status
        if (localStorage.getItem("isTxnMpinSet") === "False") {
          // setOpenSetPasswordModal(true); // Open SetPassword modal if MPIN is not set
        } else {
          // setIpinModalOpen(true); // Open IPIN modal if MPIN is set
        }
      } else {
        toast.error(response.data?.message || 'Unable to fetch active payout provider');
      }
    } catch (error) {
      console.error('Error fetching active payout provider:', error);
      toast.error('Failed to fetch active payout provider');
    }
  };

  const handlePay = async (values) => {
    setLoadingPay(true); // Start loading

    const payload = {
      name: values.name,
      payeeaccountNumber: values.accountNumber,
      bankIfsc: values.ifsc,
      transferMode: "IMPS",
      transferAmount: values.amount,
      remarks: "testing",
      purpose: "REIMBURSEMENT",
      payeraccountNumber: "120664700000021", // Replace with actual payer account number
    };

    try {
      // Call handlePayClick to fetch the active provider and show modals if necessary
      await handlePayClick(values);

      // Fetch the active provider from localStorage
      const activeProvider = localStorage.getItem('activeProvider');

      if (activeProvider === "INSTANTPAY") {
        const token = localStorage.getItem('jwtToken'); // Get the JWT token from localStorage

        // If the active provider is INSTANTPAY, call the related API
        const instantPayPayload = {
          ...payload,
          // Include any additional details needed for INSTANTPAY
        };

        try {
          await dispatch(initiatePayout(payload, setShowSuccessPayout, setInsufficiantB, setShowFailedPayout));

        } catch (error) {
          console.error('Error during InstantPay payout:', error);
          toast.error('Failed to initiate payout through INSTANTPAY');
        }
      } else if (activeProvider === "RAZORPAY") {
        await dispatch(initiatePayout(payload, setShowSuccessPayout, setInsufficiantB, setShowFailedPayout));

        // If the active provider is Razorpay, show a toast message for Razorpay integration
        toast.info('Razorpay payout provider selected');
      } else {
        // If no recognized provider is found, display an error message
        toast.error('Unknown payout provider');
      }

      // After checking the active provider, initiate the payout
    } catch (error) {
      console.log('Error during payout:', error);
      toast.error('Error during payout');
    } finally {
      setLoadingPay(false); // Stop loading
    }
  };



  useEffect(() => {
    if (showSuccessPayout) {
      // Trigger success popup
      toast.success("Payout was successful!"); // This will show a success toast notification
    }
    if (insufficiantB) {
      // Trigger insufficient balance popup
      toast.error("Insufficient balance for the payout."); // This will show an error for insufficient balance
    }
    if (showFailedPayout) {
      // Trigger failed payout popup
      toast.error("Failed Payout"); // This will show a generic error message
    }
  }, [showSuccessPayout, insufficiantB, showFailedPayout]);

  // const filteredBankDetails = bankDetails.filter((bank) =>
  //   (bank.name && bank.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (bank.user.userid && bank.user.userid.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (bank.user.mobileNumber && bank.user.mobileNumber.includes(searchQuery)) ||
  //   (bank.bankName && bank.bankName.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (bank.branch && bank.branch.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (bank.ifsc && bank.ifsc.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //   (bank.accountNumber && bank.accountNumber.toString().includes(searchQuery))
  // );

  const lineHeight = '56px';
  const lineHeight2 = '50px';

  const renderForm = (values, handleChange, handleBlur, isSubmitting) => (
    <Paper style={{ boxShadow: 'none', padding: '2rem', borderRadius: '8px', boxShadow: 'none' }}>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label="Name"
              value={values.name = verifiedName}
              disabled // Make field non-editable
              helperText={
                <ErrorMessage name="name" component="div" style={{ color: "red" }} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="bankName"
              name="bankName"
              label="Bank Name"
              value={values.bankName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="bankName" component="div" style={{ color: "red" }} />}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }} >
            <TextField
              fullWidth
              variant="outlined"
              id="branch"
              name="branch"
              label="Branch"
              value={values.branch}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="branch" component="div" style={{ color: "red" }} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
            <TextField
              fullWidth
              variant="outlined"
              id="ifsc"
              name="ifsc"
              label="IFSC"
              value={values.ifsc}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="ifsc" component="div" style={{ color: "red" }} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
            <TextField
              fullWidth
              variant="outlined"
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={values.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="accountNumber" component="div" style={{ color: "red" }} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
            <Button
              sx={{
                border: '1.5px solid #0288D1',
                color: "#0288D1",
                fontWeight: "bold",
                textTransform: "none",
                fontSize: '16px',
                width: { xs: '100%', md: '25%' },
                height: lineHeight,
                borderRadius: "9px",
              }}
              onClick={() => handleVerifyBank(values.accountNumber, values.ifsc)}
              disabled={loadingVerify} // Disable button when loading
            >
              {loadingVerify ? <CircularProgress size={24} /> : "Verify Bank"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              variant="outlined"
              id="amount"
              name="amount"
              label="Amount"
              type="number" // Ensures only numeric input
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="amount" component="div" style={{ color: "red" }} />}
            />
            <Button
              sx={{
                backgroundColor: "#027BFE",
                color: "white",
                fontSize: '16px',
                height: '56px', // Match height with TextField
                textTransform: "none",
                borderRadius: "9px",
                marginLeft: '1rem', // Add some space between the TextField and button
              }}
              onClick={() => handlePay(values)}
              disabled={!verificationSuccess || loadingPay}
            >
              {loadingPay ? <CircularProgress sx={{ color: 'white' }} size={24} /> : "Pay"}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: { xs: '0%', md: '1%' } }}>
            <Box display="flex" justifyContent="end" gap={2}>
              <Button
                sx={{
                  border: '1.5px solid #FF0000',
                  color: '#FF0000',
                  textTransform: 'none',
                  fontSize: '16px',
                  height: lineHeight2,
                  width: { xs: '100%', md: '16%' },
                  borderRadius: "9px",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  fontSize: '16px',
                  height: lineHeight2,
                  textTransform: "none",
                  width: { xs: '100%', md: '16%' },
                  '&:hover': {
                    backgroundColor: "#027BFE",
                    color: '#FFFFFF',
                  },
                  borderRadius: "9px",
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Adding..." : "Add"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  );

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Balance</Typography>
        <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Add Beneficiary</Typography>
      </Grid>
      <Grid style={{ marginTop: '1%' }}>
        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Add Beneficiary</Typography>
      </Grid>

      <Grid spacing={2} style={{ marginTop: '3%' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, isSubmitting }) =>
            renderForm(values, handleChange, handleBlur, isSubmitting)
          }
        </Formik>




        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '3%' }}>
          <Grid>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>History</Typography>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              maxWidth: '60%',
              borderRadius: 10,
              border: '1px solid rgba(0, 0, 0, 0.3)',
            }}>
            <IconButton onClick={handleSearch}>
              <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }} />
            </IconButton>
            <InputBase
              style={{ color: 'rgba(0, 0, 0, 3)' }}
              placeholder="Search"
              sx={{ width: '100%' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>

        </Grid>

        <Grid style={{ marginTop: '2%' }}>
          <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', boxShadow: 'none' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#027BFE' }}>
                <TableRow>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'center' }} >Sr.No</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Bank Name</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Branch</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >IFSC</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Account Number</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', textAlign: 'start' }} >Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBankDetails.length > 0 ? (
                  filteredBankDetails.map((bank, index) => (
                    <TableRow
                      key={bank.id}
                      className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>{bank.name || 'N/A'}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>{bank.bankName}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>{bank.branch}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>{bank.ifsc}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>{bank.accountNumber}</TableCell>
                      <TableCell sx={{ textAlign: 'start' }}>
                        <IconButton onClick={() => handleDeleteBeneficiary(bank.accountNumber)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      {statusMessage ? <Typography>{statusMessage}</Typography> : 'No records found'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>


            </Table>
          </TableContainer>
        </Grid>

      </Grid>

      {successtoverify && <Successtoverify handleClose={handleClose} />}
      {failedtoverify && <Failedtoverify handleClose={handleClose} />}
      {addbenesuccess && <AddBeneSuccessPop handleClose={handleClose} />}
      {failedtoaddbene && <Failedtoaddbene handleClose={handleClose} />}
      {accPresentPopup && <AccPresentPopup handleClose={handleClose} />}
      {showSuccessPayout && <PayoutPopup handleClose={handleClose} />}
      {showFailedPayout && <PayoutFailedPopup handleClose={handleClose} />}
      {insufficiantB && <InsufficiantB handleClose={handleClose} />}
      {deleteBeneSuccess && <DeleteBeneficiarySucc handleClose={handleClose} />}
      {deleteBeneFail && <DeleteBeneficiaryFail handleClose={handleClose} />}

    </Container>
  )
}

export default NewAddBeneficiary
