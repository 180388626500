import { Grid } from '@mui/material'
import React from 'react'

const MovieTicket = () => {
  return (
    <Grid style={{display:'flex', justifyContent:'center',fontWeight:'bold', fontSize:'20px', marginTop:'27%'}}>
        Coming Soon...
    </Grid>
  )
}

export default MovieTicket
