import {Grid, Typography } from '@mui/material'
import React from "react";
import {PartnerArrowIcon} from '../../res/NewWebIcons';



const BalanceTopComponent = () => {


  return (
    <Grid>
    <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
        <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Balance</Typography>
        <PartnerArrowIcon/>
        <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'13px'}}>Balance</Typography>
    </Grid>

    <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
        <Grid>
        <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Balance</Typography>
        </Grid> 
    </Grid>
</Grid>
  )
}

export default BalanceTopComponent
