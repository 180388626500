import React, { useState } from 'react';
import {
    Box, Typography, Grid, Button, Divider, Accordion, AccordionSummary, AccordionDetails, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SeatSelection from './SeatSelection';

const BookingComponent = ({ selectedFare, onBack }) => {
    const [passengers, setPassengers] = useState(Array(5).fill({ firstName: '', lastName: '' }));

    const handlePassengerChange = (index, field, value) => {
        const newPassengers = passengers.slice();
        newPassengers[index][field] = value;
        setPassengers(newPassengers);
    };

    const handleSaveDetails = () => {
        console.log("Passenger details:", passengers);
    };

    return (
        <Box sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6">Review Your Booking</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={8}>
                    <Box sx={{ p: 2, bgcolor: 'lightblue' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1">Kolhapur</Typography>
                                <Typography variant="h6">02:40 PM</Typography>
                                <Typography variant="body2">Mon, 11 Nov</Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Typography variant="body1">Hyderabad</Typography>
                                <Typography variant="h6">04:00 PM</Typography>
                                <Typography variant="body2">Mon, 11 Nov</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">2h 20m layover in Hyderabad</Typography>
                        <Typography variant="body2">You need to change the flight during the layover.</Typography>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1">Hyderabad</Typography>
                                <Typography variant="h6">06:20 PM</Typography>
                                <Typography variant="body2">Mon, 11 Nov</Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Typography variant="body1">Tirupati</Typography>
                                <Typography variant="h6">07:25 PM</Typography>
                                <Typography variant="body2">Mon, 11 Nov</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body2">Baggage: Check-in 15 kg, Cabin 7 kg</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ p: 2, bgcolor: 'lightgray' }}>
                        <Typography variant="h6">Fare Breakup</Typography>
                        <Typography variant="body2">Base Fare: ₹43,235</Typography>
                        <Typography variant="body2">Taxes & Fees: ₹9,300</Typography>
                        <Typography variant="body2">Instant Discount: -₹1,500</Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>Grand Total: ₹51,035</Typography>
                        <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={onBack}>Back</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={8}>
                    <Box>
                        {passengers.map((passenger, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Passenger {index + 1} Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="First and Middle Name"
                                                    value={passenger.firstName}
                                                    onChange={(e) => handlePassengerChange(index, 'firstName', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Last Name"
                                                    value={passenger.lastName}
                                                    onChange={(e) => handlePassengerChange(index, 'lastName', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleSaveDetails}
                                                    sx={{ height: '80%', width: '50%', marginTop: '2%' }}

                                                >
                                                    Save Details
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                    <Grid item xs={4} style={{ alignItems: 'flex-end' }}>
                        <Button
                            variant="contained"
                            onClick={handleSaveDetails}
                            sx={{ height: '80%', width: '50%', marginTop: '2%' }}

                        >
                            PRoceed To Pay
                        </Button>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                        <SeatSelection />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BookingComponent;
