// // MobileRecharge.js
// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   FormLabel,
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   Divider,
//   Dialog,
//   DialogContent,
// } from "@mui/material";
// import { ArrowBackIos } from "@mui/icons-material";
// import axios from "axios";


// export default function MobileRecharge() {
//   const [showPlans, setShowPlans] = useState(false);
//   const [modalOpen, setModalOpen] = useState(false); // Open modal by default for demonstration
//   const [walletBalance, setWalletBalance] = useState(0);
//   const userName = localStorage.getItem('username');
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [operator, setOperator] = useState("");
//   const [circle, setCircle] = useState("");
//   const [segment, setSegment] = useState(""); // API Segment
//   const [loading, setLoading] = useState(false); // Loading state for API call

//   const handleGetPlans = () => {
//     setShowPlans(true); // Show the Recharge Plans screen
//   };

//   const handleBack = () => {
//     setShowPlans(false); // Return to the Mobile Recharge form
//   };

//   const handleClose = () => setModalOpen(false);

//   const handlePay = () => setModalOpen(true);


//   const plans = [
//     {
//       price: "₹199",
//       validity: "28 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹239",
//       validity: "28 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹319",
//       validity: "28 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹666",
//       validity: "55 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹749",
//       validity: "66 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹899",
//       validity: "86 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹949",
//       validity: "90 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     {
//       price: "₹1029",
//       validity: "98 Days",
//       description: "Get Data: 2 GB/day, Validity: 28 days Talktime: Unlimited local and national calls, SMS: 100 SMS/day",
//     },
//     // Add more plans as needed
//   ];

//   const fetchWalletBalance = async () => {
//     try {
//       const token = localStorage.getItem("jwtToken");
//       const response = await axios.get(
//         "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setWalletBalance(response.data.walletBalance);
//     } catch (error) {
//       console.error("Error fetching wallet balance:", error);
//     }
//   };
//   const handleMobileBlur = async (e) => {
//     const mobileNumber = e.target.value.trim();
//     if (/^[0-9]{10}$/.test(mobileNumber)) {
//       try {
//         setLoading(true);
//         const response = await axios.get(
//           `https://testapi.ipaisa.co.in/myapp/auth/OperatorCheck?tel=${mobileNumber}`,
//           {
//             headers: {
//               Authorization:
//                 "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
//             },
//           }
//         );

//         const { Operator, circle, segment } = response.data.records;

//         setOperator(Operator || "");
//         setCircle(circle || "");
//         setSegment(segment || "");
//       } catch (error) {
//         console.error("Error fetching operator details:", error);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };
//   useEffect(() => {
//     fetchWalletBalance();
//   }, []);



//   return (
//     <Box sx={{ p: 3 }}>
//       {/* Toggle between Mobile Recharge Form and Recharge Plans */}
//       {!showPlans ? (
//         <Box>
//           <FormLabel
//             component="legend"
//             style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
//           >
//             Mobile Recharge Or Bill Payment
//           </FormLabel>
//           <RadioGroup
//             row
//             defaultValue={segment === "PREPAID" ? "Prepaid" : "Postpaid"} // Dynamically set default
//             sx={{ mb: 2, mt: 1 }}
//           >
//             <FormControlLabel
//               value="Prepaid"
//               control={<Radio />}
//               label="Prepaid"
//               disabled={segment === "PREPAID"} // Disable if segment is POSTPAID
//             />
//             <FormControlLabel
//               value="Postpaid"
//               control={<Radio />}
//               label="Postpaid"
//               disabled={segment !== "PREPAID"} // Disable if segment is not POSTPAID
//             />
//           </RadioGroup>


//           <Grid container spacing={2}>
//             <Grid item md={4} xs={12}>
//               <TextField
//                 label="Mobile Number"
//                 variant="outlined"
//                 fullWidth
//                 value={mobileNumber}
//                 onChange={(e) => setMobileNumber(e.target.value)}
//                 onBlur={handleMobileBlur}
//                 disabled={loading}
//               />
//             </Grid>
//             <Grid item md={4} xs={12}>
//               <TextField
//                 label="Operator"
//                 variant="outlined"
//                 fullWidth
//                 value={operator}
//                 disabled
//               />
//             </Grid>
//             <Grid item md={4} xs={12}>
//               <TextField
//                 label="Circle"
//                 variant="outlined"
//                 fullWidth
//                 value={circle}
//                 disabled
//               />
//             </Grid>
//           </Grid>

//           <Box sx={{ mt: 3 }}>
//             <Grid container justifyContent="flex-end">
//               <Grid item md={3} xs={12}>
//                 <Button
//                   onClick={handleGetPlans}
//                   style={{
//                     color: "white",
//                     backgroundColor: "#027BFE",
//                     textTransform: "none",
//                     fontSize: "16px",
//                     width: "100%",
//                   }}
//                 >
//                   Get Plans
//                 </Button>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//       ) : (
//         <>
//           <Box>
//             {/* Back Button */}
//             <Button
//               onClick={handleBack}
//               style={{
//                 color: "#027BFE",
//                 textTransform: "none",
//                 fontSize: "16px",
//                 marginBottom: "16px",
//               }}
//             >
//               <ArrowBackIos style={{ fontSize: '16px' }} />
//               Back
//             </Button>

//             {/* Select Recharge Plan */}
//             <FormLabel
//               component="legend"
//               style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
//             >
//               Select Recharge Plan
//             </FormLabel>

//             <RadioGroup row defaultValue="Data Plans" sx={{ mb: 2, mt: 1 }}>
//               <FormControlLabel value="Data Plans" control={<Radio />} label="Data Plans" />
//               <FormControlLabel value="Talk Time Plans" control={<Radio />} label="Talk Time Plans" />
//               <FormControlLabel value="Combo Packs" control={<Radio />} label="Combo Packs" />
//             </RadioGroup>

//             <Grid container spacing={2}>
//               {plans.map((plan, index) => (
//                 <Grid item md={3} xs={12} key={index}>
//                   <Grid
//                     sx={{
//                       borderRadius: "12px",
//                       border: "1px solid #D9D9D9",
//                       cursor: "pointer",
//                       "&:hover": { boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" },
//                     }}
//                   >
//                     <CardContent>
//                       <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                         <Typography variant="h6" fontWeight="bold">
//                           {plan.price}
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                           Validity For {plan.validity}
//                         </Typography>
//                       </Grid>
//                       <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />
//                       <Typography variant="body2" mt={1}>
//                         {plan.description}
//                       </Typography>
//                     </CardContent>
//                   </Grid>
//                 </Grid>
//               ))}
//             </Grid>

//             <Box sx={{ mt: 3, textAlign: "right" }}>
//               <Button
//                 onClick={handlePay}
//                 style={{
//                   color: "white",
//                   backgroundColor: "#027BFE",
//                   textTransform: "none",
//                   fontSize: "16px",
//                   width: "150px",
//                 }}
//               >
//                 Pay
//               </Button>
//             </Box>
//           </Box>

//           <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
//             <DialogContent>
//               <Grid container spacing={4}>
//                 {/* Left Section: Complete your payment */}
//                 <Grid item xs={12} md={6}>
//                   <Box
//                     sx={{
//                       p: 3,
//                       border: "1px solid #D9D9D9",
//                       borderRadius: "12px",
//                       backgroundColor: "#F8F8F8",
//                     }}
//                   >
//                     <Typography variant="h6" fontWeight="bold" mb={2}>
//                       Complete your payment
//                     </Typography>
//                     <Grid container justifyContent="space-between" mb={2}>
//                       <Typography variant="body1" color="textSecondary">
//                         Plan Amount
//                       </Typography>
//                       <Typography variant="body1" fontWeight="bold">
//                         ₹249
//                       </Typography>
//                     </Grid>
//                     <Grid container justifyContent="space-between" mb={2}>
//                       <Typography variant="body1" color="textSecondary">
//                         Taxes
//                       </Typography>
//                       <Typography variant="body1" fontWeight="bold">
//                         ₹2
//                       </Typography>
//                     </Grid>
//                     <Divider sx={{ my: 2 }} />
//                     <Grid container justifyContent="space-between">
//                       <Typography variant="h6" fontWeight="bold">
//                         Total
//                       </Typography>
//                       <Typography variant="h6" fontWeight="bold">
//                         ₹251
//                       </Typography>
//                     </Grid>
//                     <Button
//                       fullWidth
//                       sx={{
//                         mt: 3,
//                         color: "white",
//                         backgroundColor: "#027BFE",
//                         textTransform: "none",
//                         fontWeight: "bold",
//                         fontSize: "16px",
//                       }}
//                     >
//                       Proceed to Pay
//                     </Button>
//                   </Box>
//                 </Grid>

//                 {/* Right Section: Wallet Information */}
//                 <Grid item xs={12} md={6}>
//                   <Card
//                     sx={{
//                       borderRadius: "12px",
//                       border: "1px solid #D9D9D9",
//                       backgroundColor: "#027BFE",
//                       color: "white",
//                     }}
//                   >
//                     <CardContent sx={{ textAlign: "center" }}>
//                       <Typography variant="h6" fontWeight="bold">
//                         {userName}
//                       </Typography>
//                       <Typography variant="body1" mt={1}>
//                         Wallet Balance
//                       </Typography>
//                       <Typography variant="h4" fontWeight="bold" mt={1}>
//                         ₹{walletBalance}
//                       </Typography>
//                       <Button
//                         sx={{
//                           mt: 3,
//                           backgroundColor: "white",
//                           color: "#027BFE",
//                           textTransform: "none",
//                           fontWeight: "bold",
//                           width: "100%",
//                         }}
//                       >
//                         Update Wallet
//                       </Button>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               </Grid>
//             </DialogContent>
//           </Dialog>
//         </>
//       )}
//     </Box>
//   );
// }
import React, { useState } from "react";
import { Box, RadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import PrepaidRecharge from "./PrepaidRecharge";
import PostpaidRecharge from "./PostpaidRecharge";

export default function MobileRecharge() {
  const [rechargeType, setRechargeType] = useState("PREPAID");

  const handleChange = (event) => {
    setRechargeType(event.target.value);
  };

  return (
    <Box sx={{padding:'2rem'}}>
      <FormLabel
        component="legend"
        style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
      >
        Mobile Recharge or Bill Payment
      </FormLabel>
      <RadioGroup
        row
        value={rechargeType}
        onChange={handleChange}
        sx={{ mb: 2, mt: 1 }}
      >
        <FormControlLabel value="PREPAID" control={<Radio />} label="Prepaid" />
        <FormControlLabel value="POSTPAID" control={<Radio />} label="Postpaid" />
      </RadioGroup>

      {/* Render components based on selection */}
      {rechargeType === "PREPAID" && <PrepaidRecharge />}
      {rechargeType === "POSTPAID" && <PostpaidRecharge />}
    </Box>
  );
}
