import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

const DetailedFlightComponent = () => (
    <Box sx={{ p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Box>
                <Typography variant="body1">Kolhapur</Typography>
                <Typography variant="h6">02:40 PM</Typography>
                <Typography variant="body2">Mon, 11 Nov</Typography>
                <Typography variant="body2">Kolhapur Airport</Typography>
            </Box>
            <Box textAlign="center">
                <Typography variant="body2">1h 20m</Typography>
            </Box>
            <Box textAlign="right">
                <Typography variant="body1">Hyderabad</Typography>
                <Typography variant="h6">04:00 PM</Typography>
                <Typography variant="body2">Mon, 11 Nov</Typography>
                <Typography variant="body2">Rajiv Gandhi Airport</Typography>
            </Box>
        </Box>
        <Divider />
        <Box textAlign="center" sx={{ my: 2, backgroundColor: '#FFF7E0', p: 1, borderRadius: 1 }}>
            <Typography variant="body2"><strong>2h 20m layover in Hyderabad.</strong></Typography>
            <Typography variant="body2">You need to change the flight during the layover.</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Box>
                <Typography variant="body1">Hyderabad</Typography>
                <Typography variant="h6">06:20 PM</Typography>
                <Typography variant="body2">Mon, 11 Nov</Typography>
                <Typography variant="body2">Rajiv Gandhi Airport</Typography>
            </Box>
            <Box textAlign="center">
                <Typography variant="body2">1h 5m</Typography>
            </Box>
            <Box textAlign="right">
                <Typography variant="body1">Tirupati</Typography>
                <Typography variant="h6">07:25 PM</Typography>
                <Typography variant="body2">Mon, 11 Nov</Typography>
                <Typography variant="body2">Tirupati Airport</Typography>
            </Box>
        </Box>
    </Box>
);

export default DetailedFlightComponent;
