import React from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';

export default function PrivacyPolicyy() {
  return (
    <Container sx={{ mt: 10, mb: 12 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Typography variant="h5" component="p" fontWeight="bold" color="black">
          Privacy & Policy
        </Typography>

        <Typography variant="body1" color="black">
          iPaisa respects your privacy and desires to promote trust and confidence on the Internet. As such iPaisa feels it is necessary to disclose to you what information iPaisa collects and tracks and what we do with the information that we collect.
        </Typography>

        <Typography variant="body1" color="black">
          iPaisa is a mobile application developed by Edsom Fintech Private Limited.
          <br />
          We value the trust you placed in us. Your privacy is of the utmost importance to us and the protection of your personal data is a key commitment for us. Hence we maintain the highest standards for secure transactions and user information privacy.
          <br />
          We are governed by the provisions of applicable law in India including the Information Technology Act 2000 and the Information Technology Rules 2011 to maintain the privacy of your personal data.
          <br />
          Please read the following statement to learn about our information-gathering and dissemination practices.
        </Typography>

        <Typography variant="body1" color="black">
          <b>Note:</b> <br />
          By downloading /using /accessing the iPaisa platform, as defined for business and or personal use, you indicate, understand, agree, and consent to this privacy policy.
          You hereby provide your unconditional consent or agreements to Edsom Fintech Pvt. Ltd., as provided under the Information Technology Act 2000 and the rules and regulations made therein for accessing the iPaisa platform.
          <br />
          This privacy policy is a legally binding contract between you and the iPaisa.
          <br />
          This privacy policy does not require any physical, electronic, or digital signature.
          <br /><br />
          The terms and conditions of this privacy policy as defined below will be effective upon your registration on the iPaisa platform and will govern the relationship between you and iPaisa for your use of the iPaisa platform.
          <br />
          This privacy policy shall be read together with respect to terms of use or other terms and conditions of the respective iPaisa product offering on the iPaisa platform.
        </Typography>

        <Typography variant="body1" color="black">
          <b>Data and information</b>
          <List sx={{ listStyleType: 'disc', ml: '10%' }}>
            <ListItem>
              We collect, process, transfer, store, and/or record your data and information while you visit any of the iPaisa platforms or avail of its services.
              Information may be collected in various ways and formats during the course of your visit to
            </ListItem>
          </List>
          <List sx={{ listStyleType: 'decimal', ml: '10%' }}>
            <ListItem>Registering on iPaisa platforms as a user Or</ListItem>
            <ListItem>Registering on iPaisa platform as a merchant Or</ListItem>
            <ListItem>Any other relationship that may be governed by terms and conditions listed on iPaisa platform</ListItem>
            <ListItem>Access iPaisa platform by the ways of visiting iPaisa platform, accessing links, emails, notifications sent by iPaisa platforms</ListItem>
          </List>
        </Typography>

        <Typography variant="body1" color="black">
          <List sx={{ listStyleType: 'disc', ml: '10%' }}>
            <ListItem>
              We and our service providers or business partners may also collect information made publicly available through other third-party platforms through online or offline databases that are otherwise legitimately obtained and are subject to the third party’s terms of use and privacy statement.
            </ListItem>
            <ListItem>
              We automatically track certain information about you, based upon your behaviour on our app, we use this information to do internal research, interests and behaviour to better understand, protect and serve you better. This information is compiled and analysed on an aggregated basis.
            </ListItem>
          </List>
        </Typography>

        <Typography variant="body1" color="black">
          <b>Information / Data we collect -</b>
          <List sx={{ listStyleType: 'disc', ml: '10%' }}>
            <ListItem>
              Personal details for example name, contact details -including residential address, Date of birth, documents such as Identity card, Passport details, Aadhaar details, PAN, Voter ID, driving licence and /or education details provided by you to us to avail various products or services from us.
            </ListItem>
            <ListItem>
              Your details including transaction History, Balances, payment details for effecting transfer of monies to various payment channels provided by us.
            </ListItem>
            <ListItem>
              Information obtained from your mobile device by way of using our app like device location, communication information including Contacts, Storage, Model, mobile network, transactional and promotional SMS or app notifications.
            </ListItem>
            <ListItem>
              Financial details for example, Income, Expenses and our credit history needed as part of request for some of our products or services.
            </ListItem>
            <ListItem>
              Images of documents, photos required to avail any of our services or products.
            </ListItem>
            <ListItem>
              Voice recordings of your conversations with our customer care agent with you to address your queries / grievances
            </ListItem>
            <ListItem>
              Employment details like occupation, positions held, employment history, salary and/ or benefits as a part of your record retention for credit for various product evaluations are required under applicable law including prevention of money laundering rules 2005.
            </ListItem>
            <ListItem>
              Your messages on message boards, chats or any other message or feedback areas shared on iPaisa platforms and social media.
            </ListItem>
            <ListItem>
              The devices and identification numbers of devices used by you to access iPaisa platform.
            </ListItem>
            <ListItem>
              Your location stored on your browser or devices and behaviour on iPaisa platform.
            </ListItem>
            <ListItem>
              The operating system of your device browser, browsing behaviour, or any other data as permitted to be stored by mobile app stores.
            </ListItem>
          </List>
        </Typography>

        <Typography variant="body1" color="black">
          <b>Usage and purpose of data collection</b>
          iPaisa always desires to provide an efficient, safe, smooth and customised experience. Also to provide services features that are most likely meet your needs and to customize our application to make your user experience safer and easier, we may collect, store, use and transfer your information including personal information and non -personal information for the following purpose:
          <br /><br />
          We use your personal data in our business activities for providing our and/or our business partners’ products / services and to perform among other actions the following
          <List sx={{ listStyleType: 'decimal', ml: '10%' }}>
            <ListItem>To verify your identity, access privilege assigned in order to provide the services you request, or we intend to offer.</ListItem>
            <ListItem>To facilitate the transactions or report on these transactions.</ListItem>
            <ListItem>To detect and protect us against error, fraud and other criminal activity, enforce our terms and conditions and otherwise describe to you at the time of collection.</ListItem>
            <ListItem>To resolve disputes, troubleshoot problems, help promote safe service, collect money, major customer interest in our products and services.</ListItem>
            <ListItem>To inform you about offline or online offers, products, services, updates, customize your experience and improving your experience by using the application by presenting advertisement products and offer.</ListItem>
            <ListItem>To undertake research and analytics for offering or improving our products or services and their security and service quality.</ListItem>
            <ListItem>To respond to your queries or feedback submitted by you.</ListItem>
            <ListItem>To verify your identity for us to provide products or services to you.</ListItem>
            <ListItem>To monitor and review products or services from time to time.</ListItem>
            <ListItem>Providing information to law enforcement agencies or government agencies in connection with a query or investigation on matters as permitted by law.</ListItem>
            <ListItem>To help diagnose problems with our server and to administer our app. Your IP address is also used to help identify you and to gather broad demographic information.</ListItem>
            <ListItem>To identify security breaches and attacks, investigating, preventing and taking actions on illegal or suspected fraud or money laundering activities and conducting forensic audits as part of internal or external audit or investigation by any of the iPaisa entities or government agencies.</ListItem>
            <ListItem>For designing and marketing our products or services and its affiliates products and services.</ListItem>
          </List>
        </Typography>

        <Typography variant="body1" color="black">
          <b>Disclosures</b>
          iPaisa uses reasonable precautions to keep the information disclosed to us secure and such information will not be disclosed to unaffiliated third parties for the marketing of their own products or services, however, we may disclose the information shared by you with our business partners, or third parties that we have engaged with to perform services or functions on our behalf and under our instruction in order to provide you with better services, a few illustrations where your information may be used and shared are as below:
          <List sx={{ listStyleType: 'decimal', ml: '10%' }}>
            <ListItem>To business partners to provide services or any other services you have requested</ListItem>
            <ListItem>
              To business partners to authenticate the details provided by you and to provide specific services
            </ListItem>
            <ListItem>
              To business partners to enable them to offer their products and services, to evaluate and fulfil your requests or to provide various services on or through the iPaisa platform.
            </ListItem>
            <ListItem>We may share your information with government entities or other regulatory bodies as required under laws to respond to subpoenas, court orders or legal process or to establish or exercise our legal rights or defend against legal claims.</ListItem>
            <ListItem>We may share your information to prevent, investigate or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violation of our terms and other policies or as otherwise required by law.</ListItem>
            <ListItem>We may share your information to provide you with superior service and a range of offers.</ListItem>
            <ListItem>We may share your information to help investigate, prevent or take action regarding unlawful and suspected unlawful activities, suspected fraud, potential threats to the safety or physical well-being of any person, violations of iPaisa terms and conditions and to verify your information with third parties as part of the Know Your Customer process.</ListItem>
          </List>
        </Typography>

        <Typography variant="body1" color="black">
          <b>Third-Party Products or services</b>
          Please note that your mobile service provider, mobile operating system provider, third-party applications (including the applications pre-loaded on your mobile device) and social media platforms may also collect, use and share information about you and your usage. We do not control how these third parties collect, use, share or secure this information. For information about third party privacy practices, please consult their respective privacy policies.
        </Typography>
      </Box>
    </Container>
  );
}
