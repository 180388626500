import React, { useEffect } from 'react';
import { Box, Typography, Grid, Card, Paper, Button, TextField, Input, Container , useMediaQuery} from '@mui/material';
import cardImage1 from '../Assets/BlogsImage/BlogsImage/cardImg1.jpg';
import cardImage2 from '../Assets/BlogsImage/BlogsImage/cardImg2.jpg';
import blogimage1 from '../Assets/BlogsImage/BlogsImage/BlogsImage/blogsImage2.jpg';
import ResumeCareerImg from "../Assets/CareerImg/CareerImg/CareerImg/applyNowImg.png";
import CareerMainImg from '../Assets/website/CareerMainImg/CareerMainImg.png';
import DotCareerImg from "../Assets/CareerImg/CareerImg/CareerImg/DotCareer.png";
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Footer from "./Footer";
import Header from "./Header";



const CustomCard = styled(Card)({
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: 'none',
});

const cardContent = [
  {
    date: 'January 01, 2021',
    title: 'How to Start a Successful in 2020',
    description: 'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia.',
    image: cardImage1,
    link: '#'
  },
  {
    date: 'January 01, 2021',
    title: 'Grow Your Business with Email Marketing',
    description: 'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia.',
    image: cardImage2, 
    link: '#'
  },
  {
    date: 'January 01, 2021',
    title: 'The 4 Pillars Every Online Business',
    description: 'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia.',
    image: blogimage1,
    link: '#'
  }
];

const inputHeight = '56px'; 


const Career = () => {
  
const theme = useTheme();
const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

  return (
    <>
    <Header />
    <Grid style={{backgroundColor:'white'}}>
    <Box  sx={{borderRadius: '16px', marginTop:'3%', xs: '0%', sm: '0% 15%'  }}>
    <Container
        sx={{
          backgroundColor: "white",
          padding: "2rem",
        }}
      >
        <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { xs: "25px", sm: "30px", md: "45px" },
              textAlign: "center",
              color: "#0B1131",
              marginBottom: "2rem",
            }}
        >
          Career
        </Typography>
        <Grid container justifyContent="center">
          <Typography
            variant="h5"
            sx={{
              color: "#4D5156",
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "500",
              lineHeight: "28px",
              maxWidth: "700px",
              textAlign: "start",
            }}
          >
            At Edsom Fintech Private Limited, we're pioneering the future of financial technology, including the innovative world of UPI (Unified Payments Interface). Our mission is to create seamless, secure, and user-friendly financial experiences that empower users to manage their money effortlessly. We’re looking for passionate and talented individuals to join our team and help shape the future of financial transactions. If you’re excited about designing cutting-edge UPI solutions and financial applications, we want to hear from you!
          </Typography>
        </Grid>
         <Grid style={{ display:'flex' , justifyContent:'center', marginTop:'3rem'}}>
        <img src={CareerMainImg} style={{width:'70%'}} alt=''/>
        </Grid>
      </Container>
 </Box>

 <Box sx={{ backgroundColor: '#3029D9', padding: { xs: '4%', md: '9%' }, maxWidth: '100%', marginTop: '4%' }}>
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5975FF' }}>
        Why Join Us
      </Typography>
      <Typography variant="h3" sx={{ mt: 2, fontWeight: 'bold', color: 'white' }}>
        Manage Your <br /> Finances From<br /> One Place
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, maxWidth: 400, color: 'white', opacity: 0.7 }}>
        At iPaisa, we believe in empowering your financial journey with unparalleled expertise and innovative solutions. When you join us, you gain access to a team of dedicated professionals committed to helping you achieve your financial goals. Here's why you should choose us:
      </Typography>
    </Grid>

    <Grid container item xs={12} md={6} spacing={2}>
      <Grid item xs={12} sm={6} textAlign="center" style={{marginBottom:'8%'}}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Professional <br /> Team
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Our experienced advisors provide personalized financial strategies tailored to your unique needs.
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="center" style={{marginBottom:'8%'}}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              SEO <br /> Optimization
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              We implement best practices for search engine optimization, helping your site rank higher and attract more organic traffic.
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="center" sx={{ mt: { xs: 3, sm: 0 } }} style={{marginBottom:'8%'}}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Innovative <br/> Solutions
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              We leverage cutting-edge technology to offer efficient and effective financial management tools.
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="center" sx={{ mt: { xs: 3, sm: 0 } }} style={{marginBottom:'8%'}}>
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, height: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Marketing <br /> Consulting
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Our marketing experts will help you create effective strategies to promote your brand and drive growth.
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </Grid>
</Box>


   
    <Grid container padding="6%">
      <Grid item xs={12} md={6} style={{ padding: '20px' }}>  {/* Form Section */}
        <Grid style={{ padding: '20px', borderRadius:'15px' }}>
          <Grid color='cyan'>
            <Typography variant="h5" style={{ marginBottom: '9px', color: '#0078FF', fontWeight: 'bold', textAlign: 'start' }}>
              Apply Now
            </Typography>
            <Typography color="textSecondary" fontSize='0.8rem'>
              If you are passionate and dare to dream and achieve Big, drop your details
            </Typography>
            <Typography color="textSecondary" fontSize='0.8rem'>to our HR</Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="contactNo"
                    label="Contact No"
                    name="contactNo"
                    autoComplete="tel"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="linkedInUrl"
                    label="LinkedIn URL"
                    name="linkedInUrl"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="portfolioUrl"
                    label="Portfolio URL"
                    name="portfolioUrl"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="dropResume"
                    name="dropResume"
                    type="file"
                    inputProps={{ accept: '.pdf,.doc,.docx' }}
                  />
                  <label htmlFor="dropResume">
                    <Typography variant="caption" display="block" color="textSecondary">
                      Drop Resume
                    </Typography>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="dropPhoto"
                    name="dropPhoto"
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                  />
                  <label htmlFor="dropPhoto">
                    <Typography variant="caption" display="block" color="textSecondary">
                      Drop Latest Photo
                    </Typography>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="employerDetails"
                    label="Employer Details"
                    name="employerDetails"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained" style={{ backgroundColor: '#0078FF', borderRadius: '8px', textTransform: 'none', height: inputHeight, fontSize: 15}}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  {/* Image Section */}
        <img src={ResumeCareerImg} alt="Apply Now" style={{ maxWidth: '100%', height: '60%' }} />
      </Grid>
    </Grid>
            
    </Grid>
<Footer/>
    </>

  );
};

export default Career;
