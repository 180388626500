// redux/reducers/index.js
import { combineReducers } from 'redux';
import partnerReducer from './partnerReducer'; // Import your specific reducers
import dashboardReducer from './dashboardReducer';
import walletReducer from './walletReducer';
import bankReducer from './bankReducer';
import payoutReducer from './payoutReducer';
import bulkPayoutReducer from './bulkPayoutReducer';


const rootReducer = combineReducers({
  partner: partnerReducer,
  dashboard: dashboardReducer,
  wallet: walletReducer,
  bank: bankReducer,
  payout: payoutReducer,
  bulkPayout: bulkPayoutReducer,
  // Add more reducers as needed
});

export default rootReducer;
