import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Validation schemas
const mobileNumberSchema = Yup.object({
  mobileNumber: Yup.string()
    .required('WhatsApp Number is required')
    .matches(/^\d+$/, 'WhatsApp Number must be digits only')
    .length(10, 'WhatsApp Number must be exactly 10 digits'),
  otp: Yup.string()
    .required('OTP is required')
    .length(6, 'OTP must be 6 digits'),
});

const mpinSchema = Yup.object({
  newMpin: Yup.string()
    .required('New MPIN is required')
    .matches(/^\d{0,4}$/, 'MPIN must be 0-4 digits long')
    .max(4, 'MPIN must be at most 4 digits long'),
  confirmMpin: Yup.string()
    .oneOf([Yup.ref('newMpin'), null], 'MPINs must match')
    .required('Confirm MPIN is required')
});

const ForgotMpin = ({ handleMpinSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(null);
  const [currentStep, setCurrentStep] = useState('mobileNumber');
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();

  // Handle mobile number input change
  const handleMobileNumberChange = async (e, setFieldValue) => {
    const { name, value } = e.target;
  
    // Ensure only numbers are entered
    const numericValue = value.replace(/\D/g, "");
  
    // Restrict the input to a maximum of 10 digits
    if (numericValue.length <= 10) {
      setFieldValue(name, numericValue);
      setMobileNumber(numericValue); // Save the mobile number
  
      if (name === 'mobileNumber' && numericValue.length === 10) {
        try {
          const response = await axios.post(
            "https://testapi.ipaisa.co.in/myapp/auth/validatemobilenumber",
            { mobileno: numericValue }
          );
          if (response.data.mobileValidate) {
            setIsMobileValid(true);
            setCurrentStep('otp'); // Move to OTP step
            toast.success("Mobile number is valid. Please enter OTP.");
            
            // Send OTP to the mobile number
            await axios.post(
              "https://testapi.ipaisa.co.in/myapp/auth/otpmsg",
              { mobileNumber: numericValue }
            );
            toast.success("OTP has been sent to your WhatsApp Number");
          } else {
            setIsMobileValid(false);
            // toast.error("Mobile number is already registered");
          }
        } catch (error) {
          console.error("Error validating mobile number:", error);
          setIsMobileValid(false);
          toast.error("Error validating mobile number");
        }
      }
    }
  };

  const handleMpinChange = (e, setFieldValue) => {
    const { name, value } = e.target;
  
    // Ensure only numbers are entered
    const numericValue = value.replace(/\D/g, "");
  
    // Restrict the input to a maximum of 4 digits
    if (numericValue.length <= 4) {
      setFieldValue(name, numericValue);
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/validateotp",
        { mobileno: mobileNumber, otp: values.otp } // Use the saved mobile number
      );
      if (response.data.status === "success" && response.data.data) {
        setCurrentStep('mpin'); // Move to MPIN reset step
        toast.success("OTP verified successfully.");
      } else {
        toast.error("Invalid OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP");
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  // Handle MPIN reset
  const handleForgotMpin = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/auth/setmpin",
        { mobileno: mobileNumber, mpin: values.newMpin } // Use the saved mobile number
      );
  
      if (response.data.success) {
        toast.success("MPIN Reset Successfully.");
        handleMpinSuccess(false); // Set ForgotMpin state to false
        navigate('/signin'); // Redirect to sign-in page
      } else {
        throw new Error("Error resetting MPIN"); // Trigger the catch block if the response isn't successful
      }
    } catch (error) {
      console.error("Error resetting MPIN:", error);
      toast.error("Error resetting MPIN");
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };
  

  return (
    <div style={{ width: '90%', margin: 'auto' }}>
      {currentStep === 'mobileNumber' && (
        <Formik
          initialValues={{ mobileNumber: '' }}
          validationSchema={mobileNumberSchema.pick(['mobileNumber'])}
          onSubmit={handleMobileNumberChange}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form style={{ width: '100%' }} autoComplete="off">
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobileNumber"
                label="WhatsApp Number"
                name="mobileNumber"
                autoComplete="off"
                autoFocus
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="mobileNumber" />}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                onChange={(e) => handleMobileNumberChange(e, setFieldValue)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ height: '50px', backgroundColor: '#0078FF', borderRadius: '5px', fontWeight: '1000', textTransform: 'none' }}
                disabled={isSubmitting}
              >
                Send OTP
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {currentStep === 'otp' && (
        <Formik
          initialValues={{ mobileNumber: mobileNumber, otp: '' }}
          validationSchema={mobileNumberSchema}
          onSubmit={handleOtpSubmit}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form style={{ width: '100%' }} autoComplete="off">
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                autoComplete="off"
                autoFocus
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="mobileNumber" />}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                value={mobileNumber} // Show the saved mobile number
                disabled
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                type="text"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="otp" />}
                error={touched.otp && Boolean(errors.otp)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ height: '50px', backgroundColor: '#0078FF', borderRadius: '5px', fontWeight: '1000', textTransform: 'none' }}
                disabled={isSubmitting || isMobileValid === false}
              >
                Verify OTP
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {currentStep === 'mpin' && (
        <Formik
          initialValues={{ mobileNumber: mobileNumber|| '', newMpin: '', confirmMpin: '' }}
          validationSchema={mpinSchema}
          onSubmit={handleForgotMpin}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form style={{ width: '100%' }} autoComplete="off">
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                autoComplete="off"
                autoFocus
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="mobileNumber" />}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                value={mobileNumber} // Show the saved mobile number
                disabled
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newMpin"
                label="New MPIN"
                name="newMpin"
                type="password"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="newMpin" />}
                error={touched.newMpin && Boolean(errors.newMpin)}
                onChange={(e) => handleMpinChange(e, setFieldValue)}
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirmMpin"
                label="Confirm MPIN"
                name="confirmMpin"
                type="password"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                helperText={<ErrorMessage name="confirmMpin" />}
                error={touched.confirmMpin && Boolean(errors.confirmMpin)}
                onChange={(e) => handleMpinChange(e, setFieldValue)}

              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ height: '50px', backgroundColor: '#0078FF', borderRadius: '5px', fontWeight: '1000', textTransform: 'none' }}
                disabled={isSubmitting}
              >
                Reset MPIN
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ForgotMpin;
