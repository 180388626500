import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Link,
  IconButton,
} from "@mui/material";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import logo from "../Assets/login/logo.png";
import { LocationIcon, LinkedInIcon, FacebookIcon, EmailIcon, InstagramIcon } from "../../res/icons";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        color: "black",
        paddingTop: "2rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={5} alignItems="flex-start" style={{padding:'2%'}}>
          <Grid item xs={12} sm={6} md={2} id='0'>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box display="flex" alignItems="center" marginBottom="10px">
                <img src={logo} alt="logo" style={{ height: "55px", marginRight: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  iPaisa
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  lineHeight: "20px",
                  color: '#A0ABB8'
                }}
              >
                iPaisa – Your trusted partner for all your digital payments 
              </Typography>
            </Box>
          </Grid>
                
          <Grid item xs={12} sm={6} md={2} id='1'>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="5px" sx={{ marginTop: { xs: '1rem', md: '1rem' } }}>
              <Link
                href="/"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Home
              </Link>
              <Link
                href="/Aboutus"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                About Us
              </Link>
              <Link
                href="/Career"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                How it Works
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2} id='2'>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="5px" sx={{ marginTop: { xs: '1rem', md: '1rem'} }}>
              <Link
                href="/Services"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Our Services
              </Link>
              <Link
                href="/ContactUs"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Contact Us
              </Link>
              <Link
                href='/faq'
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                FAQ
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2} id='3'>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="5px" sx={{ marginTop: { xs: '1rem', md: '1rem'} }}>
              <Link
                href="/ourteam"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Our Team
              </Link>
              
              <Link
                href="/Career"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Career
              </Link>
              <Link
                href="/ContactUs"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                 Help
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2} id='4'>
            <Box display="flex" flexDirection="column" alignItems="flex-start" gap="5px" sx={{ marginTop: { xs: '1rem', md: '1rem'} }}>
              <Link
                href="/termsandconditions"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Terms & Condition
              </Link>
              
              <Link
                href="/CancellationandRefund"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                Cancellation & Refund
              </Link>
              <Link
                href="/privacypolicy"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                 Privacy & Policy
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2} id='5'>
            <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }} width="100%">
              <IconButton color="inherit" href="https://www.linkedin.com/company/edsom-fintech-pvt-ltd/">
                <LinkedInIcon />
              </IconButton>
              <IconButton color="inherit" href="https://www.instagram.com/ipaisa07?igsh=bmk2dWhlNDloZzcz">
                <InstagramIcon />
              </IconButton>
              <IconButton color="inherit" href="https://www.facebook.com/share/sWt1cQzoRjGLNYDQ/?mibextid=qi2Omg">
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href="mailto:care@ipaisa.co.in"
              >
                <EmailIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent='space-between' alignItems='flex-start' marginTop="1rem" style={{padding:'0% 2%'}}>
          <Box display="flex" alignItems="center">
            <LocationIcon />
            <Typography variant="body2" color='#A0ABB8' style={{ fontSize: '12px', marginLeft: "4px" }}>
              Edsom Fintech Private Limited, Second Floor,
              <br />
              Golden Empire, Baner Pune - 411045
            </Typography>
          </Box>

          <Typography variant="body2" color='#A0ABB8' style={{ fontSize: '12px', marginTop: { xs: '1rem', md: 0 }, textAlign:'center'}}>
            All Copyrights 2024 Are reserved by ©iPaisa Edsomfintech Pvt.Ltd.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
