import React, {useEffect} from 'react';
import { Box, Typography, Grid, Card, CardMedia, Container, IconButton, Avatar, Divider } from '@mui/material';
import OurTeamImage from '../Assets/website/OurTeamImages/OurTeamImg.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import aboutuss1 from "../Assets/website/aboutus/aboutuss1.png";
import DotCareerImg from '../Assets/CareerImg/CareerImg/CareerImg/DotCareer.png';
import Footer from "./Footer";
import Header from "./Header";
import { LinkedInIcon } from '../../res/icons';
//OurTeam Images
import RamJaiswal from "../Assets/website/OurTeamImages/RamJaiswalImg.png";
import UttamlaalRamdhani from "../Assets/website/OurTeamImages/UttamlaalRamdhani.png";
import AnitaJaiswal from "../Assets/website/OurTeamImages/AnitaJaiswalImg.png";
//Our Advisory Images
import GitaKashyapPerti from '../Assets/website/OurTeamImages/Adv. Gita Kashyap Perti.png';
import SatishBhattu from '../Assets/website/OurTeamImages/CS. Satish Bhattu.jpeg';
import DilipChate from '../Assets/website/OurTeamImages/Dr. Dilip Chate.jpeg';
import RaghavKulkarni from '../Assets/website/OurTeamImages/CS. Raghav Kulkarni.png';
import RajanBhatia from '../Assets/website/OurTeamImages/CA. Rajan Bhatia.jpeg';
import SantoshKushe from '../Assets/website/OurTeamImages/Santosh Kushe.jpeg';
import VikramsinhDesai from '../Assets/website/OurTeamImages/Vikramsinh Desai.jpg'; 
import SheetalAnandPatil from '../Assets/website/OurTeamImages/Adv. Sheetal Anand Patil.jpeg'
import KalpanaJaiswal from '../Assets/website/OurTeamImages/Kalpana Jaiswal.jpg';
import SonalRamdhani from '../Assets/website/OurTeamImages/Sonal Ramdhani.jpg';
import RameshPrasadSharma from '../Assets/website/OurTeamImages/Col.Ramesh Prasad Sharma.jpg';



const inspirationImages = [
  { src: RamJaiswal, name: 'Ram Jaiswal', post: '(Founder and CEO)' },
  { src: UttamlaalRamdhani, name: 'Uttamlal Ramdhani', post: '' },
  { src: AnitaJaiswal, name: 'Anita Jaiswal', post: '(Co-Founder and COO)' }
];

const cardData1 = [
  {
      name: "Adv. Gita Kashyap Perti",
      title: "Legal Advisor",
      description: "Honourable Adv Gita Kashyap Perti, M.A. (Philosophy), Bombay University, L.L.B. (Law), Pune University, DIPLOMA in Education EX Principal Director Defence Estates, Ministry of Defence, Southern Command Pune, and alumni of the National Defence College.",
      linkedInUrl: "https://www.linkedin.com/in/gita-perti/",
      imageUrl: GitaKashyapPerti   
  },
  {
      name: "CS Satish Bhattu",
      title: "Legal Advisor",
      description: "Teach corporate law and corporate governance as visiting faculty regularly in the Western India Regional Council (WIRC) and the Centre for Corporate Governance Research  and Training (CCGRT) of the Institute of Company Secretaries of India (ICSI) and the Institute of Costs Accountants of India (ICAI).",
      linkedInUrl: "https://www.linkedin.com/in/cs-satish-bhattu-67583a18/",
      imageUrl: SatishBhattu   
  },
  {
      name: "Dr. Dilip Chate",
      title: "Sr Advisor",
      description: "Board of Studies, Environmental Sciences, SP Pune University, India and Atmospheric and Space Technology, SP Pune University. Dr. Chate served as Senior Consultant-Environment for on-going NSM’s  Urban Modelling Project at C-DAC, Pune with consortia partnership with IISc, IITM, and IITBBS.",
      linkedInUrl: "https://www.linkedin.com/in/dr-dilip-chate-49749b31/",
      imageUrl: DilipChate  
  },
  {
    name: "CA Rajan Bhatia",
    title: "Chief Financial Officer",
    description: "Mr. Rajan Bhatia is a renowned, experienced Chartered Accountant and B. Com. (Hons.) from India’s premier Shri Ram College of Commerce, a Business Coach, an international professional keynote speaker, and a Distinguished Toastmaster (the highest award from Toastmasters International).",
    linkedInUrl: "https://www.linkedin.com/in/rajan-bhatia-fca-587b1a6/",
    imageUrl: RajanBhatia   
  }
];

const cardData2 = [
  {
    name: "CS Raghvendra Kulkarni",
    title: "Legal Advisor",
    description: "Raghvendra Kulkarni is a practising Company Secretary and co-founder of RBKRS and  Associates LLP. A commerce and law graduate, he is a Certified CSR Professional as well as a Registered Trade Mark Agent. His quest for learning always drives him to explore the dynamism of various industries.",
    // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
    imageUrl: RaghavKulkarni 
},
  {
      name: "Santosh Kushe",
      title: "Business Consultant",
      description: "Santosh Kushe is a skilled and expert personality and has served many renowned platforms in his Career like Punwire mobile communications Ltd., Page Me, ICICI Home finance for Operations and Marketing, HBL Global HDFC subsidiary and Tata Tele services Group leader.",
      // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
      imageUrl: SantoshKushe   
  },
  // {
  //     name: "Vikramsinh Desai",
  //     title: "Business Consultant",
  //     description: " ",
  //     linkedInUrl: "https://www.linkedin.com/in/yourprofile",
  //     imageUrl:  VikramsinhDesai 
  // },
  {
      name: "Adv. Shietal Patil",
      title: "Legal Consultant",
      description: "Adv. Shietal   Patil  is a practicing advocate with 9+ years of experience in Banking and Finance,  including 5 years in managerial cadre at HDFC Bank. She had also work as a legal consultant for Mudra Sahakari Sanstha and Ramanand Urban Nidhi Ltd. She has specialized in banking law, finance law, and intellectual property law.",
      // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
      imageUrl: SheetalAnandPatil 
  }
];

const cardData3 = [
  {
      name: "Col.Ramesh Prasad Sharma",
      title: "Business Consultant",
      description: " Col. Ramesh Prasad Sharma is an Ex  Army Officer with an experience of working as a Communication and Networking Expert and a Techno Enthusiast with a career span of over 34 years in the Army. ",
      // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
      imageUrl: RameshPrasadSharma   
  },
  {
      name: "Kalpana Jaiswal",
      title: "Director",
      description: "The director of the company, she holds a master's degree in English and an LLM. She heads the department of compliance, legal, and regulatory matters.",
      // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
      imageUrl: KalpanaJaiswal 
  },
  {
      name: "Sonal Ramdhani",
      title: "Director",
      description: "Sonal, a woman entrepreneur, started her journey with an online lifestyle accessory marketplace. She also holds a directorship in other financial organisations, including Siddhi Mahila Urban Nidhi Limited, Siddhi Urban Hyderabad Nidhi Limited, and Siddhi Urban Pune Nidhi Limited.",
      // linkedInUrl: "https://www.linkedin.com/in/yourprofile",
      imageUrl: SonalRamdhani
  }
];

const cardData = [...cardData1, ...cardData2, ...cardData3];

const OurTeam = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Header />
      <Box sx={{ p: 4, borderRadius: '16px', marginTop: '1%' }}>
        <Container
          sx={{
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { xs: "25px", sm: "30px", md: "45px" },
              textAlign: "center",
              color: "#0B1131",
              marginBottom: "2rem",
            }}
          >
            Our Team
          </Typography>
          <Grid container justifyContent="center">
            <Typography
              variant="h5"
              sx={{
                color: "#4D5156",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "500",
                lineHeight: "28px",
                maxWidth: "700px",
                textAlign: "start",
              }}
            >
              Welcome to Our Team!
              At our core, we are committed to empowering you with valuable knowledge and strategies to navigate the financial landscape. Our experts are here to break down complex topics and provide actionable insights tailored to your needs. From investment strategies to wealth management tips, we’re dedicated to helping you make informed decisions that drive your financial success. Trust our team to keep you updated and supported on your journey toward financial literacy and growth!
            </Typography>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
            <img src={OurTeamImage} style={{ width: '70%' }} alt='' />
          </Grid>
        </Container>

        <Grid container style={{ display: 'flex', justifyContent: 'space-around', marginTop: '6%', alignItems: 'center' }}>
          <Grid>
            <Typography style={{ color: '#3029D9', fontWeight: 'bold', fontSize: '25px' }}>Our Mission</Typography>
            <Typography style={{ fontWeight: 'bold', fontSize: '40px' }}>Empowering Your Financial </Typography>
            <Typography style={{ fontWeight: 'bold', fontSize: '40px' }}>Future with Trust & Innovation</Typography>
            <Typography color='textSecondary' style={{ fontSize: '15px', paddingTop: '1.2rem', lineHeight: '28px' }}>
              Our Mission is to create a fast and secure payment system<br /> that enables merchants and the user to make or receive<br /> payments, with convenience and trust through continues<br /> innovation leveraging technology.
            </Typography>
          </Grid>

          <img src={aboutuss1} alt="AboutUsImg1" style={{ width: '35%', display: 'flex' }} />
        </Grid>
      </Box>

      <Box
        sx={{
          padding: "2rem",
          backgroundColor: "#3029D9",
        }}
      >
        <Grid container justifyContent="center" textAlign="center">
          <Typography sx={{ color: '#5975FF', fontWeight: 'bold', fontSize: '20px' }}>OUR INSPIRATION</Typography>
        </Grid>
        <Grid container justifyContent="center" paddingTop="1rem" textAlign="center">
          <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '38px' }}>The great source of Our Inspiration</Typography>
        </Grid>

        <Grid container spacing={7} justifyContent="center" sx={{ marginTop: '1rem', padding: '0% 15% 0% 15%' }}>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center', mt: { xs: '0rem', md: '-3rem' } }}>
            <Card sx={{ borderRadius: '16px', margin: 'auto', width: '100%', height: '80%' }}>
              <CardMedia
                component="img"
                height="100%"
                image={UttamlaalRamdhani}
                alt="Uttamlal Ramdhani"
                sx={{ borderRadius: '16px', objectFit: 'cover' }}
              />
            </Card>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white', marginTop: '1rem' }}>
              Uttamlal Ramdhani
            </Typography>
            <Typography variant="body2" sx={{ color: '#B0B3B8' }}>
               The cornerstone of Our Inspiration
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography style={{color:'#3029D9', fontWeight:'bold', fontSize:'25px', display:'flex', marginTop: '3rem',justifyContent:'center'}}>Leadership Team</Typography>

      <Grid container spacing={10} justifyContent="center" sx={{ padding: '0% 15% 0% 15%', marginTop:'0.5%' }}>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
            <Card sx={{ borderRadius: '16px', margin: 'auto', width: '100%', height: '80%' }}>
              <CardMedia
                component="img"
                height="100%"
                image={RamJaiswal}
                alt="Ram Jaiswal"
                sx={{ borderRadius: '16px', objectFit: 'cover' }}
              />
            </Card>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'black', marginTop: '1rem' }}>
              Ram Jaiswal
            </Typography>
            <Typography variant="body2" sx={{ color: '#B0B3B8' }}>
              (Founder and CEO)
            </Typography>
            <IconButton color="inherit" href={"https://www.linkedin.com/in/ram-shakun-uttamlal-jaiswal-b161a575/"} style={{ marginTop: '2%' }}>
              <LinkedInIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
            <Card sx={{ borderRadius: '16px', margin: 'auto', width: '100%', height: '80%' }}>
              <CardMedia
                component="img"
                height="100%"
                image={AnitaJaiswal}
                alt="Anita Jaiswal"
                sx={{ borderRadius: '16px', objectFit: 'cover' }}
              />
            </Card>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'black', marginTop: '1rem' }}>
              Anita Jaiswal
            </Typography>
            <Typography variant="body2" sx={{ color: '#B0B3B8' }}>
              (Co-Founder and COO)
            </Typography>
            <IconButton color="inherit" href={"https://www.linkedin.com/in/anita-jaiswal-a7861076/"} style={{ marginTop: '2%' }}>
              <LinkedInIcon />
            </IconButton>
          </Grid>
        </Grid>
        
      <Grid container style={{ marginBottom: '2rem', marginTop: '7%' }}>
        <Grid style={{ textAlign: 'start', padding: '0% 0% 0% 8%' }}>
          <Typography style={{ color: '#3029D9', fontWeight: 'bold', fontSize: '25px' }}>Meet Our Team</Typography>
          <Typography style={{ fontWeight: 'bold' }} sx={{ fontSize: { xs: "25px", sm: "12px", md: "28px" } }}>Dedicated and Experienced<br /> Professionals</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center" style={{ marginTop: '6%', padding: '2rem' }}>
    {cardData.map((card, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ textAlign: 'center', position: 'relative', paddingBottom: '4rem' }}>
        <Grid container direction="column" alignItems="center" style={{ backgroundColor: '#D6D4F7', borderRadius: '18px', padding: '1rem', height: '100%', position: 'relative' }}>
          <Avatar
            alt={card.name}
            src={card.imageUrl}
            style={{
              width: '100px',
              height: '100px',
              border: '5px solid white',
              position: 'absolute',
              top: '-50px',
              left: 'calc(50% - 50px)'
            }}
          />
          <Grid item style={{ paddingTop: '60px', width: '100%' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              {card.name}
            </Typography>
            <Typography color="textSecondary" variant="body2" style={{ marginTop: '1.5%', marginBottom: '10%' }}>
              {card.title}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '11px', textAlign: 'center' }}>
              {card.description}
            </Typography>
            {card.linkedInUrl && (
            <IconButton color="inherit" href={card.linkedInUrl} style={{ marginTop: '10%' }}>
              <LinkedInIcon />
            </IconButton>
          )}
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
      <Footer />
    </>
  );
};

export default OurTeam;
