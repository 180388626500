import { Container, Paper, Typography } from "@mui/material";
import VirtualAccountReady from "./VirtaulAccountReady";
import { useEffect, useState } from "react";
import axios from "axios";
import VirtualAccountNotReady from "./VirtualAccountNotReady";

const QRCode = () => {
  const [virtualAccount, setVirtualAccount] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchMyAccount = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    try {
      const response = await axios.get(
        // "https://testapi.ipaisa.co.in/myapp/virtual/myaccount",
        "https://testapi.ipaisa.co.in/myapp/virtual/myaccount",

        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      //   console.log(response.data);

      setVirtualAccount(response.data);
      //   console.log(response.data);
      setStatus(response.status);
    } catch (error) {
      console.error("Error fetching PayIn data:", error);
    }
  };

  useEffect(() => {
    fetchMyAccount();
  }, []);

  return (
    <>
      <Container
        style={{
          marginTop: "10%",
          marginBottom: "4%",
          borderRadius: "8px",
          padding: "2rem",
          marginTop: "calc(3% + 56px)",
        }}
        sx={{ backgroundColor: "#background: #F5F7FA" }}
      >
        <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
          My QR Code Info
        </Typography>
        {status === 200 ? (
          <VirtualAccountReady virtualAccount={virtualAccount} />
        ) : (
          <VirtualAccountNotReady />
        )}
      </Container>
    </>
  );
};

export default QRCode;
