import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  InputBase,
  IconButton,
} from '@mui/material';
import DownloadIcon1 from "@mui/icons-material/Download";
import { Grid } from '@material-ui/core';
import HiePayInInvoice from './HiePayInInvoice';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import HiePayoutInvoice from './HiePayoutInvoice';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchIcon from '@mui/icons-material/Search';


const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: '#D0EFFF',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
}));

const TransactionTable = ({ transactionType, userRecords, page, rowsPerPage }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [payInData, setPayInData] = useState([]);
  const [payOutData, setPayOutData] = useState([]);
  const classes = useStyles();

  // Derived data for the tableuserRecords
  const tableData = searchQuery.trim()
    ? transactionType === 'Pay-In'
      ? payInData
      : payOutData
    : userRecords;

  // Search handler
  const handleSearchIcon = async () => {
    if (!searchQuery.trim()) {
      toast.warning('Search query is empty!');
      return; // No need to call search if the query is empty
    }

    setLoading(true);
    const jwtToken = localStorage.getItem('jwtToken');

    try {
      // Define the API URL based on transaction type
      let apiUrl =
        transactionType === 'Pay-In'
          ? `https://testapi.ipaisa.co.in/myapp/admin/searchInPayInReport`
          : `https://testapi.ipaisa.co.in/myapp/admin/getAllPayoutTxnsByUserId`;

      // Call the API with headers and search query parameters
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: {
          searchBy: searchQuery,
        },
      });

      // For 'Pay-In' transaction type
      if (transactionType === 'Pay-In') {
        const data = response.data.body.data || [];
        if (data.length === 0) {
          toast.info('No Pay-In transactions found for the given search query.');
        } else {
          setPayInData(data);
        }
      } else {
        // For 'Pay-Out' transaction type
        const data = response.data.body.data.transaction || [];

        // Handle three conditions for 'Pay-Out' response
        if (data.length === 0) {
          toast.info('No Pay-Out transactions found for the given search query.');
        } else if (data.length > 0 && data[0].status === 'error') {
          // If transactions are present but have errors
          toast.error('There was an issue with the Pay-Out transaction data.');
        } else {
          // Successful data for Pay-Out
          setPayOutData(data);
        }
      }

    } catch (error) {
      console.error('Error fetching search results:', error);
      toast.error('Failed to fetch search results. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  // Payin Invoice
  const handleDownloadPayinInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.txnid}`);

    if (!receiptElement) {
      console.error('Receipt element not found:', `receipt-${data.txnid}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Hierarchy Payin Receipt-${data.txnid}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };

  // Payout Invoice
  const handleDownloadPayoutInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.externalRef}`);

    if (!receiptElement) {
      console.error('Receipt element not found:', `receipt-${data.externalRef}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Hierarchy Payout Receipt-${data.externalRef}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };


  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value); // Update search query state
  };
  useEffect(() => {
    if (searchQuery.trim() === '') {
      // If the search query is empty, fetch the default payout data
      // fetchPayOutData(0, '', '');
    }
  }, [searchQuery]);  // Only runs when searchQuery changes


  return (
    <>
      <ToastContainer />
      <Grid style={{ marginTop: '3%' }}>
        {/* Search Field */}
        <Grid style={{ marginTop: '3%' }}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              maxWidth: { xs: '100%', sm: '20%' },
              borderRadius: '10px',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '0.5rem',
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInput}  // Updates search query
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchIcon(); // Trigger search when Enter is pressed
                }
              }}
            />
            <IconButton color="primary" onClick={handleSearchIcon}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: '2%', boxShadow: 'none', borderRadius: '12px 12px 0px 0px' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
              <TableRow>
                {/* Common Columns */}
                <TableCell style={{ color: 'white' }} align="start">Sr. No</TableCell>
                {transactionType === 'Pay-In' ? (
                  <>
                    <TableCell style={{ color: 'white' }} align="start">Transaction ID</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">User ID</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Phone</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Mode</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                    {/* <TableCell style={{ color: 'white' }} align="start">Error Message</TableCell> */}
                    <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">PayIn Amount</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Settlement</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Invoice</TableCell>

                  </>
                ) : (
                  <>
                    <TableCell style={{ color: 'white' }} align="start">External Ref</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Payee Account</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Payee Name</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Order ID</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Wallet Opening Balance</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Wallet Closing Balance</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Transfer Mode</TableCell>
                    <TableCell style={{ color: 'white' }} align="start">Invoice</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length > 0 ? (
                tableData.map((record, index) => (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow
                      }`}
                  >
                    <TableCell>{(page) * rowsPerPage + index + 1}</TableCell>
                    {/* Pay-In or Pay-Out specific rendering */}
                    {transactionType === 'Pay-In' ? (
                      <>
                        <TableCell>{record.txnid || 'N/A'}</TableCell>
                        <TableCell>{record.userId || 'N/A'}</TableCell>
                        <TableCell>{record.phone || 'N/A'}</TableCell>
                        <TableCell>{record.mode || 'N/A'}</TableCell>
                        <TableCell>{new Date(record.addedon || '').toLocaleString()}</TableCell>
                        <TableCell
                          style={{
                            color: record.status === 'success' ? 'green' :
                              record.status === 'pending' ? 'orange' :
                                record.status === 'initiated' ? 'blue' :
                                  record.status === 'bounced' ? 'palevioletred' :
                                    record.status === 'userCancelled' ? 'red' :
                                      record.status === 'preinitiated' ? 'rebeccapurple' :
                                        record.status === 'dropped' ? 'darkorchid' :
                                          record.status === 'REFUND' ? 'darkgoldenrod' :
                                            record.status === 'failure' ? 'red' :
                                              record.status === 'Refund' ? 'darkgoldenrod' :
                                                record.status === 'Failed' ? 'red' : 'inherit',
                            fontWeight: 'bold',
                            textTransform: 'capitalize'
                          }}
                        >
                          {record.status === 'success' ? 'Successful'
                            : record.status === 'pending' ? 'Pending'
                              : record.status === 'failure' ? 'Failed'
                                : record.status === 'initiated' ? 'Initiated'
                                  : record.status === 'preinitiated' ? 'Preinitiated'
                                    : record.status === 'dropped' ? 'Dropped'
                                      : record.status === 'bounced' ? 'Bounced'
                                        : record.status === 'REFUND' ? 'Refund'
                                          : record.status || 'N/A'} {/* Default value if no match */}
                        </TableCell>
                        <TableCell>{Number(record.netAmountDebit || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(record.amount || 0).toFixed(2)}</TableCell>
                        <TableCell>{record.settlement || 'N/A'}</TableCell>
                        <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadPayinInvoice(record)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                          <Grid id={`receipt-${record.txnid}`}>
                            <HiePayInInvoice data={record} />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <TableCell>{record.externalRef || 'N/A'}</TableCell>
                        <TableCell>{record.payeeAccount || 'N/A'}</TableCell>
                        <TableCell>{record.payeeName || 'N/A'}</TableCell>
                        <TableCell>{new Date(record.timestamp || '').toLocaleString()}</TableCell>
                        <TableCell>{record.orderid || 'N/A'}</TableCell>
                        <TableCell>{Number(record.walletOpeningBalance || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(record.txnValue || 0).toFixed(2)}</TableCell>
                        <TableCell>{Number(record.walletClosingBalance || 0).toFixed(2)}</TableCell>
                        <TableCell
                          style={{
                            color: record.status === 'Transaction Successful' ? 'green' :
                              record.status === 'Transaction Pending' ? 'orange' :
                                record.status === 'REFUND' ? 'darkgoldenrod' :
                                  record.status === 'Refund' ? 'darkgoldenrod' :
                                    record.status === 'Transaction Failed' ? 'red' : 'inherit',
                            fontWeight: 'bold',
                            textTransform: 'capitalize'
                          }}
                        >
                          {record.status === 'Transaction Successful' ? 'Successful'
                            : record.status === 'Transaction Pending' ? 'Pending'
                              : record.status === 'REFUND' ? 'Refund'
                                : record.status === 'Transaction Failed' ? 'Failed'
                                  : record.status || 'N/A'} {/* Default value if no match */}
                        </TableCell>
                        <TableCell>{record.transfer_mode || 'N/A'}</TableCell>
                        <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadPayoutInvoice(record)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                          <Grid id={`receipt-${record.externalRef}`}>
                            <HiePayoutInvoice data={record} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default TransactionTable;
