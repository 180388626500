import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { MobileFriendly, Tv, Lightbulb, Phone, Router, Flight, WaterDrop, GasMeterRounded, HealthAndSafety, VolunteerActivism } from "@mui/icons-material";

const serviceList = [
  { label: "Mobile", icon: <MobileFriendly />, component: "MobileRecharge" },
  { label: "Electricity", icon: <Lightbulb />, component: "NewElectricity2" },
  { label: "Tv", icon: <Tv />, component: "NewTVRecharge" },
  { label: "Water", icon: <WaterDrop />, component: "NewWaterBill" },
  { label: "Gas Billing", icon: <GasMeterRounded />, component: "GasBillingScreen" },
  { label: "Pay EMI Loan", icon: <VolunteerActivism />, component: "NewPayLoan" },
  { label: "Broadband/Landline", icon: <Phone />, component: "NewBroadbandlandline" },
  { label: "Insurance", icon: <HealthAndSafety/>, component: "NewInsurance" },
  { label: "Router", icon: <Router />, component: "RouterRecharge" },
  { label: "Flight", icon: <Flight />, component: "FlightRecharge" },
];

export default function ServiceTabs({ setSelectedService }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedService(serviceList[newValue].component);
  };

  return (
    <Box
      sx={{
        borderRadius: "18px 18px 0px 0px",
        borderBottom: 1,
        borderColor: "divider",
        overflowX: "auto",
        backgroundColor: "#027BFE",
        padding: "2rem",
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="service tabs"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "white", // White underline for active tab
          },
        }}
      >
        {serviceList.map((service, index) => (
          <Tab
            key={index}
            label={service.label}
            icon={React.cloneElement(service.icon, {
              style: { color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)" },
            })}
            sx={{
              color: activeTab === index ? "white" : "rgba(255, 255, 255, 0.6)",
              textTransform: "capitalize",
              "&.Mui-selected": {
                color: "white", // Ensure text stays white on active tab
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
