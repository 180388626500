import { Grid, Container, Paper } from '@material-ui/core';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import html2pdf from 'html2pdf.js'; // Ensure html2pdf is imported if used here
import logo from "../../Assets/iPaisaLogo/iPaisaLogo2.png";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import  {Colors}  from '../../Reports/Colors'; // colors.js ka path adjust karein


const HiePayoutInvoice = ({ data }) => {
    const userID = localStorage.getItem('userId');
    const userName = localStorage.getItem('username');

    if (!data) return null;

    console.log('Generating PDF for transaction:', data.txnReferenceId);

    const formatAddress = (address) => {
        return address 
            ? `${address?.address || ''}, ${address?.city || ''}, ${address?.state || ''} - ${address?.pincode || ''}, ${address?.country || ''}` 
            : 'Address not available';
    };
    const formatName = (address) => {
        return `${address?.senderName || ''}`;
    };
    const formatId = (address) => {
        return `${address?.id || ''}`;
    };

    // const textColor = 'black';

    return (
        <Container 
            style={{ 
                padding: '1rem', 
                width: '100%', 
                maxWidth: '1000px', 
                boxSizing: 'border-box',
                backgroundColor: '#f0f0f0', // Set the background color here
                minHeight: '90vh', // Ensure the background color covers the full height
                borderRadius:'14px'
            }}
        >
                <Paper 
                    elevation={3}
                    style={{
                        paddingTop: '5%',
                        padding: '2rem',
                        backgroundColor: Colors.bgColor,
                        boxSizing: 'border-box',
                        color: Colors.textColor,
                        borderRadius:'14px'
                    }}
                >
                <Grid style={{display:'flex', justifyContent:'center'}}>
                    <Typography style={{fontSize:'18px', fontWeight:"bold", marginTop:'-2%'}}>Hierarchy Payout Receipt </Typography>
                </Grid>
                <Grid style={{marginTop:'1%'}}>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid>
                            <Grid style={{display:'flex', alignItems:'center', gap:'6%'}}>
                                <img src={logo} alt="iPaisa Logo" style={{ height: 65, width: 65, backgroundColor: 'transparent', borderRadius: '50%' }} />
                                <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>iPaisa</Typography>
                            </Grid> 
                            <Grid style={{marginTop:'2%'}}>
                                <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                                    {new Date(data.timestamp).toLocaleDateString() || 'N/A'}
                                </Typography>
                                <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                                {new Date(data.timestamp).toLocaleTimeString() || 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>                   
                        <Grid style={{display:'flex', alignItems:'center', marginTop:"3%"}}>
                            <Grid style={{textAlign:'end'}}>
                                <Typography style={{fontSize:"16px", fontWeight:'bold' }}>From :</Typography>
                                <Typography style={{ fontSize: '20px', fontWeight:'bold'}}>
                                    {data.userdetails.name}
                                </Typography>
                                <Typography style={{ fontSize: '16px'}}>
                                    {data.userdetails.userid}
                                </Typography>
                                <Typography style={{ fontSize: '14px' }}>{formatAddress(data.sender_address)}</Typography>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:"3%"}}>
                        <Grid style={{textAlign:'start'}}>
                            <Typography style={{fontSize:"16px", fontWeight:'bold' }}>To :</Typography>
                            <Typography style={{fontSize:'18px'}}>{data.payeeName}</Typography>
                        </Grid>
                    </Grid>

                    <TableContainer style={{ marginTop: '5%' }}>
                        <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight:"bold", width: '15%', fontSize:'10px', textAlign:'start', color:Colors.textColor }}>Externel Ref</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '15%', fontSize:'10px', paddingLeft:'5%', textAlign:'start', color:Colors.textColor }}>Order ID</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '15%', fontSize:'10px', paddingLeft:'7%',textAlign:'start', color:Colors.textColor}}>Payee Account</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '12%', fontSize:'10px', paddingLeft:'7%', color:Colors.textColor}}>Payee Name</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px', paddingLeft:'7%', color:Colors.textColor}}>Amount</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '18%', fontSize:'10px', paddingLeft:'9%', color:Colors.textColor}}>Status</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '18%', fontSize:'10px', paddingLeft:'5%', color:Colors.textColor}}>Transfer Mode</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{fontSize:'10px', color:Colors.textColor}}>{data.externalRef || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', color:Colors.textColor, paddingLeft:'5%'}}>{data.orderid || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', paddingLeft:'7%', color:Colors.textColor}}>{data.payeeAccount || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', paddingLeft:'7%', color:Colors.textColor}}>{data.payeeName || 'N/A'}</TableCell>                                                          
                                    <TableCell style={{fontSize:'10px', paddingLeft:'7%', color:Colors.textColor}}>{data.txnValue || 'N/A'}</TableCell>                                  
                                    <TableCell style={{fontSize:'10px', paddingLeft:'9%', textAlign: 'start', color:Colors.textColor}}>
                                        {data.status === 'Transaction Successful' ? 'Successful'
                                            : data.status === 'Transaction Pending' ? 'Pending'
                                                : data.status === 'REFUND' ? 'Refund'
                                                    : data.status === 'Transaction Failed' ? 'Failed'
                                                        : data.status || 'N/A'} {/* Default value if no match */}
                                    </TableCell>
                                    <TableCell style={{fontSize:'10px', paddingLeft:'5%', color:Colors.textColor}}>{data.transfer_mode || 'N/A'}</TableCell>                                  
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid style={{ marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                    <Grid>
                        <Typography style={{ fontWeight:"bold", fontSize:"22px" }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                        <Typography style={{ fontWeight:'bold', fontSize:'14px' }}>
                            Thank you for transacting at iPaisa!
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color:Colors.textColor}}>
                            This is a system generated receipt hence does not require any signature.
                        </Typography>
                        <Typography color='textSecondary' style={{fontSize:'12px', color:Colors.textColor}}>
                            Queries? Write to care@ipaisa.co.in
                        </Typography>
                    </Grid>
                    <Grid>
                        <Divider/>
                        <Grid>
                            <Typography style={{ fontWeight:'bold' }}>Total Amount : {data.txnValue}</Typography>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default HiePayoutInvoice
