import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BalanceTopComponent from './BalanceTopComponent'
import { Button, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

const NewBankCharges = () => {

    const [rows, setRows] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('INSTANTPAY');
  
    // Fetch the token from localStorage or your preferred method
    const token = localStorage.getItem('jwtToken');
  
    // Axios instance with the token in the headers
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    useEffect(() => {
      fetchProviders();
      handleProviderChange({ target: { value: 'INSTANTPAY' } });
    }, []);
  
    const fetchProviders = async () => {
      try {
        const response = await axiosInstance.get('https://testapi.ipaisa.co.in/myapp/admin/getproviderlist');
        setProviders(response.data.data || []); // Adjusted based on the API response structure
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    };
  
    const handleProviderChange = async (event) => {
      const provider = event.target.value;
      setSelectedProvider(provider);
  
      try {
        const response = await axiosInstance.get(`https://testapi.ipaisa.co.in/myapp/admin/getprovider/${provider}`);
        
        const fetchedRows = response.data.map((item, index) => ({
          srNo: index + 1,
          transactionType: item.service.transactionType,
          transactionSlab: item.service.transactionSlab,
          bankCharges: item.bankChargesl, // Corrected from bankCharges to bankChargesl
          provider: item.provider.providerName,
          isEditing: false,
        }));
  
        setRows(fetchedRows);
      } catch (error) {
        console.error('Error fetching data for selected provider:', error);
      }
    };
  
    const handleEdit = (srNo) => {
      setRows(rows.map(row => row.srNo === srNo ? { ...row, isEditing: true } : row));
    };
  
    const handleSave = async (srNo, newUserCharges, newProvider) => {
      try {
        const selectedRow = rows.find(row => row.srNo === srNo);
        
        const payload = {
          provider: newProvider, 
          transactionType: selectedRow.transactionType, 
          transactionSlab: selectedRow.transactionSlab, 
          amount: newUserCharges
        };
    
        // Make the PUT request to update the charges
        const response = await axiosInstance.put('https://testapi.ipaisa.co.in/myapp/admin/setcharge', payload);
    
        // Update the local state with the new charges and provider
        if (response.data.status === "Charges set successfully") {
          // Show success toast notification
          toast.success("Charges set successfully!");
    
          // Update the local state with the new charges and provider
          setRows(rows.map(row => row.srNo === srNo ? { ...row, bankCharges: newUserCharges, provider: newProvider, isEditing: false } : row));
        } else {
          // Show error toast notification if something went wrong
          toast.error("Failed to set charges. Please try again.");
        }
      } catch (error) {
        console.error('Error updating user charges:', error);
        // Show error toast notification in case of an exception
        toast.error("An error occurred. Please try again.");
      }
    };
    
  
    const handleChange = (srNo, key, value) => {
      setRows(rows.map(row => row.srNo === srNo ? { ...row, [key]: value } : row));
    };

    const lineHeight = '40px';
  
  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <BalanceTopComponent />

        <Grid spacing={2} style={{ marginTop: '3%' }}>
            <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                <Paper 
                    style={{ 
                        padding: '2rem', 
                        borderRadius: '8px',
                        width: '100%',
                        boxSizing: 'border-box'
                    }}
                >
                    <Grid container justifyContent="start" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={5}>
                            <FormControl 
                                variant="outlined" 
                                size="small" 
                                fullWidth
                            >
                                <InputLabel>Provider</InputLabel>
                                <Select
                                    value={selectedProvider}
                                    onChange={handleProviderChange}
                                    label="Provider"
                                >
                                    {providers.map(provider => (
                                        <MenuItem key={provider.id} value={provider.providerName}>{provider.providerName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}> 
                            <Button 
                                type="search" 
                                sx={{
                                    backgroundColor: "#027BFE",
                                    color: "white",
                                    fontSize: '16px',
                                    height: lineHeight,
                                    textTransform: "none",
                                    width: '100%',  // Use '100%' to occupy the full width of the Grid item
                                    maxWidth: { xs: '100%', md: '170px' },  // Set a specific maxWidth for md screens
                                    '&:hover': {
                                        backgroundColor: "#027BFE",
                                        color: '#FFFFFF',
                                    },
                                    borderRadius: "6px",
                                }}
                            >
                                Search
                            </Button>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>



            <Grid style={{marginTop:'4%'}}>
                <TableContainer component={Paper} style={{ backgroundColor: '#027BFE', borderRadius: '10px' }}>
                    <Table>
                        <TableHead>
                        <TableRow style={{ backgroundColor: '#027BFE' }}>
                            <TableCell style={{ color: '#fff' }}>Sr No</TableCell>
                            <TableCell style={{ color: '#fff' }}>Transaction Type</TableCell>
                            <TableCell style={{ color: '#fff' }}>Transaction Slab</TableCell>
                            <TableCell style={{ color: '#fff' }}>Bank Charges</TableCell>
                            <TableCell style={{ color: '#fff' }}>Action</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                         {rows.map((row, index) => (
                            <TableRow key={row.srNo} style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#D0EFFF' }}>
                            <TableCell>{row.srNo}</TableCell>
                            <TableCell>{row.transactionType}</TableCell>
                            <TableCell>{row.transactionSlab}</TableCell>
                            <TableCell>
                                {row.isEditing ? (
                                <TextField
                                    type="number"
                                    value={row.bankCharges}
                                    onChange={(e) => handleChange(row.srNo, 'bankCharges', e.target.value)}
                                    size="small"
                                    variant="outlined"
                                    style={{ marginRight: '10px' }}
                                />
                                ) : (
                                row.bankCharges
                                )}
                            </TableCell>
                            <TableCell>
                                {row.isEditing ? (
                                <IconButton onClick={() => handleSave(row.srNo, row.bankCharges, row.provider)}>
                                    <SaveIcon color="primary" />
                                </IconButton>
                                ) : (
                                <IconButton onClick={() => handleEdit(row.srNo)}>
                                    <EditIcon color="action" />
                                </IconButton>
                                )}
                            </TableCell>
                            </TableRow>
                         ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

    </Container>
  )
}

export default NewBankCharges
