import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import forge from 'node-forge';

const publicKeyPem = `-----BEGIN PUBLIC KEY-----
YOUR_SPRINTNXT_PUBLIC_KEY_HERE
-----END PUBLIC KEY-----`;

const AES256ECBEncrypt = (data, key) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
};

const RSAEncrypt = (data, publicKey) => {
  const rsa = forge.pki.publicKeyFromPem(publicKey);
  const encrypted = rsa.encrypt(data, 'RSAES-PKCS1-V1_5');
  return forge.util.encode64(encrypted);
};

const App = () => {
  const [apiId, setApiId] = useState('');
  const [bankId, setBankId] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const symmetricKey = CryptoJS.lib.WordArray.random(32).toString();
    const requestBody = { apiId, bank_id: bankId };
    const payload = AES256ECBEncrypt(requestBody, symmetricKey);

    const encryptedSymmetricKey = RSAEncrypt(symmetricKey, publicKeyPem);
    const clientId = 'YOUR_CLIENT_ID';  // Replace with actual client id
    const partnerId = 'YOUR_PARTNER_ID';  // Replace with actual partner id

    const encryptedClientId = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(clientId));
    const encryptedPartnerId = RSAEncrypt(partnerId, publicKeyPem);

    const headers = {
      'client-id': encryptedClientId,
      'partnerId': encryptedPartnerId,
      'key': encryptedSymmetricKey,
      'Content-Type': 'application/json'
    };

    const body = {
      body: {
        payload,
        key: encryptedSymmetricKey,
        partnerId: encryptedPartnerId,
        clientid: encryptedClientId
      }
    };

    try {
      const response = await axios.post('YOUR_API_ENDPOINT', body, { headers });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{marginTop:'7%'}}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>API ID:</label>
          <input
            type="text"
            value={apiId}
            onChange={(e) => setApiId(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Bank ID:</label>
          <input
            type="number"
            value={bankId}
            onChange={(e) => setBankId(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default App;
