import React, { useState } from 'react';
import { Box, Typography, Grid, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const SeatSelection = ({ onClose }) => {
    const [selectedLowerSeats, setSelectedLowerSeats] = useState([]);
    const [selectedUpperSeats, setSelectedUpperSeats] = useState([]);
    const [showPickupDropoff, setShowPickupDropoff] = useState(false);
    const navigate = useNavigate();

    const lowerRows = 6; // Number of rows for Lower Berth
    const upperRows = 6; // Number of rows for Upper Berth
    const seatsPerRow = 3; // Number of seats per row
    const seatPrice = 500; // Price per seat
    const gapColumn = 0; // Column number for the gap

    // Array of pickup points
    const pickupPoints = [
        { name: "Mori get mandir vali parking get no.2 delhi", time: "5:05 PM, 15 June" },
        { name: "Shop n.39 Ambedkar market Delhi", time: "5:10 PM, 15 June" },
        { name: "St. Stephen Hospital gate no 2", time: "5:15 PM, 15 June" }
    ];

    // Array of drop-off points
    const dropoffPoints = [
        { name: "Sindhi Camp", location: "Gajraj travels metro pill.184 Sindhi Camp", time: "11:35 PM, 15 June" },
        { name: "200 feet bypass", location: "200 feet bypass", time: "11:55 PM, 15 June" }
    ];

    const handleLowerSeatSelect = (seatNumber) => {
        const isSeatSelected = selectedLowerSeats.includes(seatNumber);
        if (isSeatSelected) {
            setSelectedLowerSeats(selectedLowerSeats.filter(seat => seat !== seatNumber));
        } else {
            setSelectedLowerSeats([...selectedLowerSeats, seatNumber]);
        }
    };

    const handleUpperSeatSelect = (seatNumber) => {
        const isSeatSelected = selectedUpperSeats.includes(seatNumber);
        if (isSeatSelected) {
            setSelectedUpperSeats(selectedUpperSeats.filter(seat => seat !== seatNumber));
        } else {
            setSelectedUpperSeats([...selectedUpperSeats, seatNumber]);
        }
    };

    const calculateTotalAmount = (selectedSeats) => {
        return selectedSeats.length * seatPrice;
    };

    const handleNextClick = () => {
        setShowPickupDropoff(true);
    };

    const handlecloseClick = () => {
        navigate("/rbustickets");
    };

    return (
        <Box sx={{ p: 2, mt: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <Typography variant="h6">Gajraj Travels</Typography>
            </Box>

            <Typography variant="body1" sx={{ marginBottom: '10px' }}>Delhi(from)Jaipur(to)</Typography>
            <Typography variant="body1" sx={{ marginBottom: '10px' }}>05:05 PM | AC Sleeper 2+1 With Washroom</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, mr: 2, mb: 2, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    <Typography variant="h6">Select Your Seat - Lower Berth</Typography>
                    <Grid container direction="row" spacing={2} sx={{ mt: 2 }}>
                        {[...Array(lowerRows)].map((_, rowIndex) => (
                            <Grid item container key={rowIndex} spacing={1} justifyContent="center" alignItems="center">
                                {[...Array(seatsPerRow)].map((_, seatIndex) => (
                                    <Grid item key={rowIndex * seatsPerRow + seatIndex}>
                                        {seatIndex === gapColumn && rowIndex === 0 ? (
                                            <Box sx={{ width: '40px' }} /> 
                                        ) : (
                                            <Button
                                                variant={selectedLowerSeats.includes(rowIndex * seatsPerRow + seatIndex) ? 'contained' : 'outlined'}
                                                onClick={() => handleLowerSeatSelect(rowIndex * seatsPerRow + seatIndex)}
                                                sx={{ minWidth: '8px', minHeight: '60px', margin: '4px' }} // Adjusted seat size
                                            >
                                                {rowIndex * seatsPerRow + seatIndex}
                                            </Button>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#C6FCFF', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
                        <Typography variant="h6"> Seats: {selectedLowerSeats.join(', ')}</Typography>
                        <Typography variant="h6">Total Amount (Lower Berth): {calculateTotalAmount(selectedLowerSeats)} Rupees</Typography>
                    </Box>
                </Box>

                <Box sx={{ flex: 1, ml: 2, mb: 2, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    <Typography variant="h6">Select Your Seat - Upper Berth</Typography>
                    <Grid container direction="row" spacing={2} sx={{ mt: 2 }}>
                        {[...Array(upperRows)].map((_, rowIndex) => (
                            <Grid item container key={rowIndex} spacing={1} justifyContent="center" alignItems="center">
                                {[...Array(seatsPerRow)].map((_, seatIndex) => (
                                    <Grid item key={rowIndex * seatsPerRow + seatIndex}>
                                        {seatIndex === gapColumn && rowIndex === 0 ? (
                                            <Box sx={{ width: '40px' }} /> 
                                        ) : (
                                            <Button
                                                variant={selectedUpperSeats.includes(rowIndex * seatsPerRow + seatIndex) ? 'contained' : 'outlined'}
                                                onClick={() => handleUpperSeatSelect(rowIndex * seatsPerRow + seatIndex)}
                                                sx={{ minWidth: '8px', minHeight: '60px', margin: '4px' }} // Adjusted seat size
                                            >
                                                {rowIndex * seatsPerRow + seatIndex}
                                            </Button>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#C6FCFF', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
                        <Typography variant="h6"> Seats: {selectedUpperSeats.join(', ')}</Typography>
                        <Typography variant="h6">Total Amount (Upper Berth): {calculateTotalAmount(selectedUpperSeats)} Rupees</Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                <Typography variant="h6">Total Amount: {calculateTotalAmount([...selectedLowerSeats, ...selectedUpperSeats])} Rupees</Typography>
                <Button variant="contained" onClick={handleNextClick}>Next</Button>
                <IconButton >
                    <CloseIcon onClick={onClose} />
                </IconButton>
            </Box>

            {showPickupDropoff && (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ flex: 1, marginRight: 1 }}>
                            <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1 }}>
                                <Typography variant="h6">Pick-up points ({pickupPoints.length})</Typography>
                                <ul>
                                    {pickupPoints.map((point, index) => (
                                        <li key={index}>
                                            {point.name} <br /> {point.time}
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>

                        <Box sx={{ flex: 1, marginLeft: 1 }}>
                            <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1 }}>
                                <Typography variant="h6">Drop-off points ({dropoffPoints.length})</Typography>
                                <ul>
                                    {dropoffPoints.map((point, index) => (
                                        <li key={index}>
                                            {point.name} <br /> {point.location} <br /> {point.time}
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SeatSelection;




// import React, { useState } from 'react';
// import { Box, Typography, Grid, Button, IconButton, } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useNavigate } from 'react-router-dom';


// const SeatSelection = ({ onClose }) => {
//     const [selectedLowerSeats, setSelectedLowerSeats] = useState([]);
//     const [selectedUpperSeats, setSelectedUpperSeats] = useState([]);
//     const [showPickupDropoff, setShowPickupDropoff] = useState(false);
//     const navigate = useNavigate();

//     const loverRows = 6; // Number of rows for Lover Berth
//     const upperRows = 6; // Number of rows for Upper Berth
//     const seatsPerRow = 3; // Number of seats per row
//     const seatPrice = 500; // Price per seat

//     // Array of pickup points
//     const pickupPoints = [
//         { name: "Mori get mandir vali parking get no.2 delhi", time: "5:05 PM, 15 June" },
//         { name: "Shop n.39 Ambedkar market Delhi", time: "5:10 PM, 15 June" },
//         { name: "St. Stephen Hospital gate no 2", time: "5:15 PM, 15 June" }
//     ];

//     // Array of drop-off points
//     const dropoffPoints = [
//         { name: "Sindhi Camp", location: "Gajraj travels metro pill.184 Sindhi Camp", time: "11:35 PM, 15 June" },
//         { name: "200 feet bypass", location: "200 feet bypass", time: "11:55 PM, 15 June" }
//     ];

//     const handleLowerSeatSelect = (seatNumber) => {
//         const isSeatSelected = selectedLowerSeats.includes(seatNumber);
//         if (isSeatSelected) {
//             setSelectedLowerSeats(selectedLowerSeats.filter(seat => seat !== seatNumber));
//         } else {
//             setSelectedLowerSeats([...selectedLowerSeats, seatNumber]);
//         }
//     };

//     const handleUpperSeatSelect = (seatNumber) => {
//         const isSeatSelected = selectedUpperSeats.includes(seatNumber);
//         if (isSeatSelected) {
//             setSelectedUpperSeats(selectedUpperSeats.filter(seat => seat !== seatNumber));
//         } else {
//             setSelectedUpperSeats([...selectedUpperSeats, seatNumber]);
//         }
//     };

//     const calculateTotalAmount = (selectedSeats) => {
//         return selectedSeats.length * seatPrice;
//     };

//     const renderSeats = (totalRows, handleSeatSelect, selectedSeats) => {
//         const seatLayout = [];
//         for (let row = 0; row < totalRows; row++) {
//             const seatRow = [];
//             for (let seat = 0; seat < seatsPerRow; seat++) {
//                 const seatNumber = row * seatsPerRow + seat + 1;
//                 seatRow.push(
//                     <Grid item key={seatNumber}>
//                         <Button
//                             variant={selectedSeats.includes(seatNumber) ? 'contained' : 'outlined'}
//                             onClick={() => handleSeatSelect(seatNumber)}
//                             sx={{ minWidth: '8px', minHeight: '60px', margin: '4px' }} // Adjusted seat size
//                         >
//                             {seatNumber}
//                         </Button>
//                     </Grid>
//                 );
//             }
//             seatLayout.push(
//                 <Grid container item key={row} spacing={1} justifyContent="center" alignItems="center">
//                     {seatRow}
//                 </Grid>
//             );
//         }
//         return seatLayout;
//     };

//     const handleNextClick = () => {
//         setShowPickupDropoff(true);
//     };

//     const handlecloseClick = () => {
//         navigate("/rbustickets");
//       };

//     return (
//         <Box sx={{ p: 2, mt: 2, mb: 2 }}>
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
//                 <Typography variant="h6">Gajraj Travels</Typography>
//             </Box>
            
//             <Typography variant="body1" sx={{ marginBottom: '10px' }}>Delhi(from)Jaipur(to)</Typography>
//             <Typography variant="body1" sx={{ marginBottom: '10px' }}>05:05 PM | AC Sleeper 2+1 With Washroom</Typography>
            
//             <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                 <Box sx={{ flex: 1, mr: 2, mb: 2, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
//                     <Typography variant="h6">Select Your Seat - Lower Berth</Typography>
//                     <Grid container direction="row" spacing={2} sx={{ mt: 2 }}>
//                         {renderSeats(loverRows, handleLowerSeatSelect, selectedLowerSeats)}
//                     </Grid>
//                     <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#C6FCFF', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
//                         <Typography variant="h6"> Seats: {selectedLowerSeats.join(', ')}</Typography>
//                         <Typography variant="h6">Total Amount (Lower Berth): {calculateTotalAmount(selectedLowerSeats)} Rupees</Typography>
//                     </Box>
//                 </Box>

//                 <Box sx={{ flex: 1, ml: 2, mb: 2, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
//                     <Typography variant="h6">Select Your Seat - Upper Berth</Typography>
//                     <Grid container direction="row" spacing={2} sx={{ mt: 2 }}>
//                         {renderSeats(upperRows, handleUpperSeatSelect, selectedUpperSeats)}
//                     </Grid>
//                     <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#C6FcFF', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
//                         <Typography variant="h6"> Seats: {selectedUpperSeats.join(', ')}</Typography>
//                         <Typography variant="h6">Total Amount (Upper Berth): {calculateTotalAmount(selectedUpperSeats)} Rupees</Typography>
//                     </Box>
//                 </Box>
//             </Box>

//             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
//                 <Typography variant="h6">Total Amount: {calculateTotalAmount([...selectedLowerSeats, ...selectedUpperSeats])} Rupees</Typography>
//                 <Button variant="contained" onClick={handleNextClick}>Next</Button>
//                 <IconButton >
//                     <CloseIcon onClick={onClose} />
//                 </IconButton>
//             </Box>

//             {showPickupDropoff && (
//                 <Box>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <Box sx={{ flex: 1, marginRight: 1 }}>
//                             <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1 }}>
//                                 <Typography variant="h6">Pick-up points ({pickupPoints.length})</Typography>
//                                 <ul>
//                                     {pickupPoints.map((point, index) => (
//                                         <li key={index}>
//                                             {point.name} <br /> {point.time}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </Box>
//                         </Box>

//                         <Box sx={{ flex: 1, marginLeft: 1 }}>
//                             <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff', p: 2, borderRadius: 4, marginTop: 3, marginBottom: 1 }}>
//                                 <Typography variant="h6">Drop-off points ({dropoffPoints.length})</Typography>
//                                 <ul>
//                                     {dropoffPoints.map((point, index) => (
//                                         <li key={index}>
//                                             {point.name} <br /> {point.location} <br /> {point.time}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </Box>
//                         </Box>
//                     </Box>
//                 </Box>
//             )}
//         </Box>
//     );
// };

// export default SeatSelection;


