import React from 'react';
import { Grid, Container } from '@mui/material';
import Greeting from '../FinalDashboard/Greeting';
import Cards from '../FinalDashboard/Cards';
import Records from '../FinalDashboard/Records';
import TotalBalanceChart from '../FinalDashboard/TotalBalanceChart';
import PerformanceGraph from '../FinalDashboard/PerformanceGraph';
import MyCards from '../FinalDashboard/MyCards';
import RetailerTotalServices from './RetailerTotalServices';
import RetailersPerformanceGraph from './RetailersPerformanceGraph';
import RetailerCards from './RetailerCards';


const DashboardFinal = () => {
    const userType = localStorage.getItem("uType");

return (

    <Container sx={{ marginTop: 'calc(3% + 56px)', paddingTop:{xs:'3rem', md:'0rem'}, paddingBottom:'2%'}}>
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Greeting />
            </Grid>
            {userType === "RETAILER" ?
            <Grid item xs={12} sm={12}>
                <RetailerCards />
            </Grid> :
            <Grid item xs={12} sm={6}>
                <Cards />
            </Grid>
            }
            {userType !== "RETAILER" &&
            <Grid item xs={12} sm={6}>
                <Records />
            </Grid>
            }
            <Grid item xs={12} sm={6}>
            {userType === "RETAILER" ?
                <RetailerTotalServices/>:
                <TotalBalanceChart />
            }
            </Grid>
            {userType === "RETAILER" ?
                <Grid item xs={12} sm={6}>
                    <RetailersPerformanceGraph />
                </Grid> :
                <Grid item xs={12} sm={6}>
                    <PerformanceGraph />
                </Grid>
            }
            <Grid item xs={12}>
                <MyCards />
            </Grid>
        </Grid>
    </Container>

);
};

export default DashboardFinal;
