import React, { useState, useRef } from 'react';
import { Box, Button, Grid, Typography, Container, Dialog, TextField } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Assets/login/iPaisaLogo2.png";

const ResetPassword = ({ open, onClose }) => {
  const [newPassword, setNewPassword] = useState(['', '', '', '']);
  const [confirmPassword, setConfirmPassword] = useState(['', '', '', '']);
  const [passwordError, setPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const newPassRefs = useRef([]);
  const confirmPassRefs = useRef([]);

  const handleSetPassword = async () => {
    const mpin = newPassword.join('');
    const confirmMpin = confirmPassword.join('');

    if (mpin.length < 4) {
      setPasswordError('IPIN must be at least 4 digits long.');
      return;
    }

    if (mpin !== confirmMpin) {
      setPasswordError('IPIN must match');
      return;
    }

    setPasswordError('');
    const jwtToken = localStorage.getItem('jwtToken');

    try {
      const response = await axios.put(
        'https://testapi.ipaisa.co.in/myapp/auth/updateMpin',
        { mpin },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.data && response.data.body) {
        toast.success(response.data.body.message, {
          position: 'top-right',
          autoClose: 3000,
        });

        setTimeout(() => {
          onClose(); // Close the modal
        }, 3000);
      }
    } catch (error) {
      setApiError('Failed to set IPIN. Please try again.');
      console.error('API Error:', error);
      toast.error('Failed to set IPIN. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  const handleNewPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...newPassword];
      updatedPassword[index] = value;
      setNewPassword(updatedPassword);
      if (index < 3) {
        newPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleConfirmPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...confirmPassword];
      updatedPassword[index] = value;
      setConfirmPassword(updatedPassword);
      if (index < 3) {
        confirmPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, passwordType) => {
    const updatedPassword = passwordType === 'new' ? [...newPassword] : [...confirmPassword];
    
    if (updatedPassword[index] !== '') {
      updatedPassword[index] = '';
      if (passwordType === 'new') {
        setNewPassword(updatedPassword);
        newPassRefs.current[index].focus();
      } else {
        setConfirmPassword(updatedPassword);
        confirmPassRefs.current[index].focus();
      }
    } else if (index > 0) {
      if (passwordType === 'new') {
        newPassRefs.current[index - 1].focus();
      } else {
        confirmPassRefs.current[index - 1].focus();
      }
    }
  };

  const renderPasswordFields = (password, handleChange, handleBackspace, refs) => (
    <Grid container spacing={1}>
      {password.map((value, index) => (
        <Grid item xs={3} key={index}>
          <TextField
            inputRef={(el) => (refs.current[index] = el)}
            type="password"
            variant="outlined"
            value={value}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                handleBackspace(index, password === newPassword ? 'new' : 'confirm');
              }
            }}
            fullWidth
            required
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Container>
        <Typography variant="h5" align="center" gutterBottom>
          Set IPIN
        </Typography>
        <img src={logo} alt="Logo" style={{ width: '100%', marginBottom: '16px' }} />
        <Box mb={2}>
          {renderPasswordFields(newPassword, handleNewPasswordChange, handleBackspace, newPassRefs)}
        </Box>
        <Box mb={2}>
          {renderPasswordFields(confirmPassword, handleConfirmPasswordChange, handleBackspace, confirmPassRefs)}
        </Box>
        {passwordError && (
          <Typography variant="body2" color="error" align="center">{passwordError}</Typography>
        )}
        {apiError && (
          <Typography variant="body2" color="error" align="center">{apiError}</Typography>
        )}
        <Button variant="contained" color="primary" onClick={handleSetPassword} fullWidth>
          Confirm
        </Button>
        <ToastContainer />
      </Container>
    </Dialog>
  );
};

export default ResetPassword;
