import React from 'react';
import { Grid } from '@mui/material';
import loader from '../res/NewWebIcons/loader.svg';

const SvgImageComponent = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ position: 'relative', height: '400px', marginTop: '20%' }}>
            <Grid item>
                <img
                    src={loader}
                    alt="Second Loader"
                    style={{ width: '240px', height: '240px', objectFit: 'cover' }}
                />
            </Grid>

            <Grid
                item
                sx={{
                    position: 'absolute',
                    animation: 'orbit 1s linear infinite',
                    width: '10px',
                    height: '10px',
                    background: 'conic-gradient(from 180deg at 50% 50%, rgba(92, 225, 230, 1) 0deg, rgba(92, 225, 230, 0.5) 180deg, rgba(217, 217, 217, 0) 360deg)',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 1,

                }}
            >
                <img
                    src={loader}
                    alt="First Loader"
                    style={{ width: '20px', height: '20px', objectFit: 'cover', position: 'absolute' }} // Loader size
                />
            </Grid>

            <style>
                {`
          @keyframes orbit {
            0% {
              transform: rotate(0deg) translateX(150px) rotate(0deg);
            }
            100% {
              transform: rotate(360deg) translateX(150px) rotate(-360deg);
            }
          }
             @keyframes gradientRotate {
            0% {
              background: conic-gradient(from 0deg at 50% 50%, rgba(92, 225, 230, 1), rgba(92, 225, 230, 0.5), rgba(217, 217, 217, 0));
            }
            100% {
              background: conic-gradient(from 360deg at 50% 50%, rgba(92, 225, 230, 1), rgba(92, 225, 230, 0.5), rgba(217, 217, 217, 0));
            }
          }
        `}
            </style>
        </Grid>
    );
};

export default SvgImageComponent;
