import React from 'react';
import { Button, TextField, MenuItem, Grid, Box } from '@mui/material';

const Form3 = ({ handleBack }) => {
  return (
    <Box component="form" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="Select Company"
            defaultValue=""
          >
            <MenuItem value="Company1">Company1</MenuItem>
            <MenuItem value="Company2">Company2</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Depositor Type" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Lorem ipsum" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Lorem ipsum" />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'2%' }}>
        <Button onClick={handleBack} sx={{color:'white', backgroundColor:'#4034AB', width:'14%'}}>
          Back
        </Button>
        <Button sx={{color:'white', backgroundColor:'#4034AB', width:'14%'}}>
          Finish
        </Button>
      </Box>
    </Box>
  );
};

export default Form3;
