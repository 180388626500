import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Container, Typography } from '@mui/material';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    evenRow: {
      backgroundColor: '#D0EFFF',
    },
    oddRow: {
      backgroundColor: '#FFFFFF',
    }
  }));

const DeepLinkClientReg = () => {
  const [clientData, setClientData] = useState([]);
  const classes = useStyles();


  // Fetch client data from the API
  const fetchClientData = async () => {
    const Token = localStorage.getItem('jwtToken'); // Assuming the token is stored in localStorage
    try {
      const response = await axios.get('https://testapi.ipaisa.co.in/myapp/admin/getAllClients', {
        headers: {
          Authorization: `Bearer ${Token}` // Set the bearer token in the headers
        }
      });
  
      // Check if the response has content
      if (response.status === 204 || !response.data.body || response.data.body.data.length === 0) {
        setClientData([]); // No data, set empty array
      } else {
        setClientData(response.data.body.data); // Populate with data
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };
  
  useEffect(() => {
    fetchClientData(); // Fetch client data on component mount
  }, []);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field')
      .required('First Name is required'),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field')
      .required('Last Name is required'),
    contactNo: Yup.string()
      .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
      .min(10, 'Contact number must be at least 10 digits')
      .max(10, 'Contact number cannot exceed 10 digits')
      .required('Contact Number is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    organisationName: Yup.string()
      .required('Organization Name is required'),
  });

  // Function to handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    const Token = localStorage.getItem('jwtToken');
    
    try {
      const response = await axios.post('https://testapi.ipaisa.co.in/myapp/admin/create_client', {
        firstName: values.firstName,
        lastName: values.lastName,
        contactNo: values.contactNo,
        email: values.email,
        organisationName: values.organisationName,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`,
        }
      });

      toast.success(response.data.body.message); // Show success message
      resetForm(); // Reset form fields
      fetchClientData(); // Refresh the table data
    } catch (error) {
      console.error('Error creating client:', error);
      toast.error('Failed to create client');
    }
  };

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Balance
        </Typography>
        <PartnerArrowIcon />
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Deeplink Client Registration
        </Typography>
      </Grid>
      <Grid style={{ marginTop: '1%' }}>
        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
          Deeplink Client Registration
        </Typography>
      </Grid>

      <Grid style={{ marginTop: '2%' }}>
        <Paper elevation={2} style={{ padding: '2rem', borderRadius: '10px' }}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              contactNo: '',
              email: '',
              organisationName: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="firstName" component="div" style={{ color: 'red' }} />}
                    />
                  </Grid>
                  {/* Last Name */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="lastName" component="div" style={{ color: 'red' }} />}
                    />
                  </Grid>
                  {/* Contact Number */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label="Contact Number"
                      name="contactNo"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="contactNo" component="div" style={{ color: 'red' }} />}
                    />
                  </Grid>
                  {/* Email */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="email" component="div" style={{ color: 'red' }} />}
                    />
                  </Grid>
                  {/* Organization Name */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label="Organization Name"
                      name="organisationName"
                      variant="outlined"
                      fullWidth
                      helperText={<ErrorMessage name="organisationName" component="div" style={{ color: 'red' }} />}
                    />
                  </Grid>

                  {/* Buttons */}
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ borderColor: 'red', color: 'red' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ backgroundColor: '#007bff', color: 'white' }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      {/* Table to display client data */}
      <Grid style={{ marginTop: '2%' }}>
      <TableContainer component={Paper} sx={{ overflowX: 'auto', borderRadius: '12px 12px 0px 0px', marginTop: '2rem' }}>
      <Table>
        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
            <TableRow>
                <TableCell style={{color:'white'}}>ID</TableCell>
                <TableCell style={{color:'white'}}>Name</TableCell>
                <TableCell style={{color:'white'}}>Contact Number</TableCell>
                <TableCell style={{color:'white'}}>Email</TableCell>
                <TableCell style={{color:'white'}}>Organization Name</TableCell>
                <TableCell style={{color:'white'}}>Date & Time</TableCell>
                <TableCell style={{color:'white'}}>Client ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData.length > 0 ? (
                [...clientData].reverse().map((client, index) => (
                  <TableRow key={client.clientId}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                    <TableCell>{client.id}</TableCell>
                    <TableCell>{client.firstName} {client.lastName}</TableCell>
                    <TableCell>{client.contactNo}</TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell>{client.organisationName}</TableCell>
                    <TableCell>{new Date(client.dateTime).toLocaleString()}</TableCell>
                    <TableCell>{client.clientId}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No clients available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Container>
  );
};

export default DeepLinkClientReg;