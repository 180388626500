import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Typography } from '@mui/material';
import { fetchPartnerData } from '../../redux/actions/dashboardActions'; // Assuming you have an action for fetching partner data

const Records = () => {
    const dispatch = useDispatch();

    // Get partner count and user type from Redux or localStorage
    const partnerCount = useSelector((state) => state.dashboard.partnerCount);
    const userType = localStorage.getItem('uType') || 'guest'; // Default to 'guest' if no userType is found

    useEffect(() => {
        // Fetch partner count data when the component mounts
        dispatch(fetchPartnerData());
    }, [dispatch]);

    // Record data
    const recordData = [
        { label: 'Channel Partners', value: partnerCount.channelPartnerCount || 0, color: '#092E1F', uType: 'CHANNELPARTNER' },
        { label: 'Super Distributors', value: partnerCount.superDistributerCount || 0, color: '#8F00FF', uType: 'SUPERDISTRIBUTOR' },
        { label: 'Master Distributors', value: partnerCount.masterDistributerCount || 0, color: '#B25AEF', uType: 'MASTERDISTRIBUTOR' },
        { label: 'Area Distributors', value: partnerCount.areaDistributerCount || 0, color: '#FF7B31', uType: 'AREADISTRIBUTOR' },
        { label: 'Retailers', value: partnerCount.retailerCount || 0, color: '#00DDE5', uType: 'RETAILER' },
    ];

    // Filter records based on userType hierarchy
    const filterRecords = (uType) => {
        const hierarchy = {
            ADMIN: recordData,
            CHANNELPARTNER: recordData.filter((record) => ['SUPERDISTRIBUTOR', 'MASTERDISTRIBUTOR', 'AREADISTRIBUTOR', 'RETAILER'].includes(record.uType)),
            SUPERDISTRIBUTOR: recordData.filter((record) => ['MASTERDISTRIBUTOR', 'AREADISTRIBUTOR', 'RETAILER'].includes(record.uType)),
            MASTERDISTRIBUTOR: recordData.filter((record) => ['AREADISTRIBUTOR', 'RETAILER'].includes(record.uType)),
            AREADISTRIBUTOR: recordData.filter((record) => ['RETAILER'].includes(record.uType)),
        };

        return hierarchy[uType] || [];
    };

    const filteredRecords = filterRecords(userType);

    return (
        <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '18px', boxShadow:'none' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Records</Typography>
            <Grid container spacing={2} style={{ marginTop: '1%', display:'flex', justifyContent:'center' }}>
                {filteredRecords.map((record, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                        {/* Ensures 2 cards per row on all screen sizes */}
                        <Paper
                            style={{
                                padding: '1rem',
                                backgroundColor: record.color,
                                color: '#fff',
                                borderRadius: '10px',
                                textAlign: 'center',
                                boxShadow:'none'
                            }}
                        >
                            <Typography variant="body1" style={{ fontSize: '14px' }}>
                                {record.label}
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                {record.value}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default Records;
