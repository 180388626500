import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, TextField, Button, Box, IconButton, Link } from '@mui/material';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Height } from '@mui/icons-material';


const validationSchema = Yup.object({
  userId: Yup.string().required('IRCTC User ID is required'),
});

const handleClosePageClick = () => {
  window.location.href = '/passengerdetails';
};

const EditUserID = () => {
  const [userIdEntered, setUserIdEntered] = useState(false);

  const formik = useFormik({
    initialValues: {
      userId: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // handle form submission
    },
  });

  const handleUserIdChange = (e) => {
    formik.handleChange(e);
    setUserIdEntered(e.target.value !== '');
  };

  return (
    <Container maxWidth="sm">
      <Grid elevation={3} sx={{ padding: 3, borderRadius: 2, marginTop: 10 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Typography variant="h5" fontWeight="bold">Enter IRCTC User ID</Typography>
          <IconButton onClick={handleClosePageClick} size="small">
          <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={formik.handleSubmit} style={{marginTop:'10%'}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box position="relative" marginTop={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="IRCTC User ID"
                  name="userId"
                  value={formik.values.userId}
                  onChange={handleUserIdChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.userId && Boolean(formik.errors.userId)}
                  helperText={formik.touched.userId && formik.errors.userId}
                />
                  <IconButton
                    size="small"
                    sx={{ position: 'absolute', right: '10px', top: '50%',color: '#512DA8', transform: 'translateY(-50%)'}}
                  >
                    <Typography style={{fontWeight:'bold', textDecoration:'none'}}>Forgot ID</Typography>
                  </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
                   Your IRCTC Password will be required after payment to complete the booking. {''}
                  <Link href="#" style={{textDecoration:'none', color: '#512DA8'}} fontWeight="700">
                Forgot Password?
              </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  borderRadius: '20px',
                  bgcolor: '#512DA8',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#512DA8',
                  },
                }}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box marginTop={2} textAlign="center">
          <Link href="https://www.irctc.co.in/nget/profile/user-registration" target="_blank"  style={{textDecoration:'none', fontWeight:'700', color: '#512DA8'}}>
            Create New IRCTC Account
          </Link>
        </Box>
      </Grid>
    </Container>
  );
};

export default EditUserID;
