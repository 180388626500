import React from 'react'
import iPaisaQRCode from '../Assets/iPaisaQRCode/iPaisaQRCode.jpeg';
import { Container } from '@material-ui/core';

const QRCodeComp = () => {
  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)', justifyContent:'center', display:'flex' }}>
        <img style={{width:'70%'}} src={iPaisaQRCode} alt="iPaisa QR Code" />
    </Container>
  )
}

export default QRCodeComp
