import React from 'react'
import { Button, Grid, Paper, Typography, Dialog } from '@mui/material'
import { FailedPopIcon } from '../../res/icons'
import CloseIcon from '@mui/icons-material/Close';


const FailedPop = ({  handleClose, errorMessage }) => {
    const formatDate = (date) => {
        const day = new Intl.DateTimeFormat('en-GB', { day: 'numeric' }).format(date);
        const month = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(date);
        const year = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(date);
    
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const time = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
    
        return `${day} ${month} ${year} at ${time}`;
    };
    
    const currentDateTime = formatDate(new Date());
    console.log(currentDateTime); 

  return (
    <Dialog open={true} onClose={handleClose} errorMessage={errorMessage}>
        <Grid style={{ padding: '2rem' }}>
            <Grid>
            <Grid style={{display:'flex', justifyContent:'flex-end'}}>
                <CloseIcon style={{cursor:'pointer'}} onClick={handleClose}/>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', }}>
                <FailedPopIcon/>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
                <Typography style={{fontWeight:'bold', fontSize:'20px'}}>Fund Transfer Failed!</Typography>
            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '14px', color:'red' }}>
                  {currentDateTime}
                </Typography>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%'}}>
                <Typography color='textSecondary' style={{fontSize:'15px', textAlign:'center'}}>
                We regret to inform you that your fund transfer could not be completed.
                Reason: {errorMessage} 
                </Typography>
            </Grid>
            <Grid style={{display:'flex', justifyContent:'center', marginTop:'6%', marginBottom:'4%'}}>
                <Button style={{color:'white', backgroundColor:'#185ABD', width:'20%'}}  onClick={handleClose}>
                    OK
                </Button>
            </Grid>
            </Grid>
        </Grid>
    </Dialog>
  )
}

export default FailedPop
    