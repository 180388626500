import React from 'react';
import { Button, TextField, Grid, Box } from '@mui/material';

const Form2 = ({ handleNext, handleBack }) => {
  return (
    <Box component="form" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Agent Details" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Due Amount" />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop:'2%' }}>
        <Button onClick={handleBack} sx={{color:'white', backgroundColor:'#4034AB', width:'14%'}}>
          Back
        </Button>
        <Button onClick={handleNext} sx={{color:'white', backgroundColor:'#4034AB', width:'14%'}}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Form2;
