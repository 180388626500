import { BULK_PAYOUT_SUCCESS, BULK_PAYOUT_FAILURE } from '../actionTypes';

const initialState = {
    success: false,
    error: null,
};

const bulkPayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case BULK_PAYOUT_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
            };
        case BULK_PAYOUT_FAILURE:
            return {
                ...state,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default bulkPayoutReducer;
