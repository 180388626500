import React from "react";
import CreditCardBillPaymentsList from "../Services/CreditCardBillPaymentsList";
import { Container } from "@mui/material";

export const CCBillPayList = () => {
  return (
    <>
      <Container
        style={{
          marginBottom: "4%",
          borderRadius: "8px",
          marginTop: "calc(3% + 56px)",
          backgroundColor: "#background: #F5F7FA",
        }}
      >
        <h2>Credit Card Bill Payments</h2>
        <CreditCardBillPaymentsList pageSize={25} />
      </Container>
    </>
  );
};
