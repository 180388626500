// RetailerServices.js
import React, { useState } from "react";
import ServiceTabs from "../NewServices/Tabs";
import MobileRecharge from "./MobileRecharges/MobileRecharge";
import { Grid, Paper } from "@material-ui/core";
import { Container, Typography } from "@mui/material";
import NewElectricity2 from "../NewServices/NewElectricity2";
import NewTVRecharge from "./NewTVRecharge";
import { NewBBPSIcon, PartnerArrowIcon } from "../../res/NewWebIcons";
import NewWaterBill from "./NewWaterBill";
import GasBillingScreen from "./GasBills/GasBillingScreen";
import NewPayLoan from "./NewPayLoan";
import NewBroadbandlandline from "./NewBroadbandlandline";
import NewInsurance from "./NewInsurance";


export default function RetailerServices() {
  const [selectedService, setSelectedService] = useState("MobileRecharge");

  const renderServiceComponent = () => {
    switch (selectedService) {
      case "MobileRecharge":
        return <MobileRecharge />;
      case "NewTVRecharge":
        return <NewTVRecharge />;
      case "NewWaterBill":
        return <NewWaterBill />;
      case "NewPayLoan":
        return <NewPayLoan />;
      case "NewBroadbandlandline":
        return <NewBroadbandlandline />;
      case "NewInsurance":
        return <NewInsurance />;
      case "GasBillingScreen":
        return <GasBillingScreen />;
      case "NewElectricity2":
        return <NewElectricity2 />;
      default:
        return <MobileRecharge />;
    }
  };

  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(2% + 58px)' }}> 
        <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Services</Typography>
                <PartnerArrowIcon />
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Services</Typography>
        </Grid>

        <Grid style={{ marginTop:'1%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
                Services
            </Typography>
            <Typography>
                <NewBBPSIcon/>
            </Typography>
        </Grid> 
        <Paper style={{ boxShadow:'none',borderRadius:'18px 18px 18px 18px', marginTop:'2%'}}> 
            <Grid>
                <ServiceTabs setSelectedService={setSelectedService} />
                {renderServiceComponent()}
            </Grid>
        </Paper>
    </Container>
  );
}
