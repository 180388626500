import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

import logo from "../Assets/login/iPaisaLogo2.png";
import PostpaidDetails from "./PostpaidDetails";
import { toast } from "react-toastify";

const PostPaidRecharge = () => {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(false);
  const [postpaidInfo, setPostpaidInfo] = useState(null);
  const [opCode, setOpCode] = useState(""); // Store the selected provider's opcode

  useEffect(() => {
    const fetchProviders = async () => {
      const jwtToken = localStorage.getItem("jwtToken");

      if (!jwtToken) {
        console.error("JWT Token not found in localStorage");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get("https://testapi.ipaisa.co.in/myapp/auth/getAllPostServicePro", {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        setProviders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching providers:", error);
        setLoading(false);
      }
    };

    fetchProviders();
  }, []);

  const handleProviderChange = (event) => {
    const selectedProviderName = event.target.value;
    setSelectedProvider(selectedProviderName);
    setFormError(false);

    // Find the opcode for the selected provider
    const provider = providers.find((prov) => prov.providerName === selectedProviderName);
    if (provider) {
      setOpCode(provider.opcode); // Set the selected provider's opcode
    }
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedProvider || !mobileNumber) {
      setFormError(true);
      return;
    }

    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        console.error("JWT Token not found in localStorage");
        return;
      }

      // Find the selected provider from the list of providers
      const provider = providers.find(
        (prov) => prov.providerName === selectedProvider
      );
      if (!provider) {
        console.error("Selected provider not found in the list");
        return;
      }

      const response = await axios.get(
        `https://testapi.ipaisa.co.in/myapp/auth/getPostpaidInfo`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          params: {
            tel: mobileNumber,
            operator: provider.mplanShortcut, // Use mplanShortcut here
          },
        }
      );

      if (response.data.status === 1) {
        if (response.data.records && response.data.records.length > 0) {
          // If there's an error in records, log or display it
          console.error("Error in response records:", response.data.records[0].desc);
          toast.warn(response.data.records[0].desc);
          setPostpaidInfo({
            ...response.data,
            error: response.data.records[0].desc, // Add error description to state
          });
        } else {
          // If no records or a different error message is found
          console.log("No data available or other issue:", response.data);
          setPostpaidInfo({
            ...response.data,
            error: "No data available or unexpected error occurred.",
          });
        }
      } else {
        // Handle successful response with valid postpaidInfo
        setPostpaidInfo(response.data);
      }
    } catch (error) {
      console.error("Error calling the API:", error.response || error.message);
      setPostpaidInfo({
        error: "There was an issue fetching postpaid info.",
      });
    }
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "9%", marginBottom: "3%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
            />
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", fontSize: "35px" }}
            >
              Instant Postpaid Mobile Recharge Solution
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="provider-label">Select Provider</InputLabel>
                    <Select
                      labelId="provider-label"
                      id="provider"
                      value={selectedProvider}
                      onChange={handleProviderChange}
                    >
                      {providers.map((provider) => (
                        <MenuItem key={provider.id} value={provider.providerName}>
                          {provider.providerName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Mobile Number"
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    margin="normal"
                    variant="outlined"
                  />
                  {formError && (
                    <Typography color="error">Please fill all fields</Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    style={{ marginTop: "16px" }}
                  >
                    Submit
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Pass the selected opcode to PostpaidDetails component */}
      {postpaidInfo && (
        <Box mt={4}>
          <PostpaidDetails response={postpaidInfo} opCode={opCode} />
        </Box>
      )}
    </Container>
  );
};

export default PostPaidRecharge;
