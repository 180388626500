import React, { useState } from 'react';
import { Box, Container, TextField, Button, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SetMpin = ({ }) => {

    const formik = useFormik({
        initialValues: {
            newMpin: '',
            confirmMpin: '',
        },
        validationSchema: Yup.object({
            newMpin: Yup.string()
                .matches(/^\d{0,4}$/, 'MPIN must be 0-4 digits long')
                .required('New MPIN is required'),
            confirmMpin:  Yup.string()
                .matches(/^\d{0,4}$/, 'MPIN must be 0-4 digits long')
                .oneOf([Yup.ref('newMpin'), null], 'MPINs must match')
                .required('Confirm MPIN is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);


            try {
                const response = await fetch('_mpin_submission', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        mobileNumber: values.mobileNumber,
                        newMpin: values.newMpin,
                    }),
                });
                const data = await response.json();
                if (data.status === 'success') {

                    console.log('MPIN changed successfully');
                } else {
                    setErrors({ api: 'Error changing MPIN' });
                }
            } catch (error) {
                setErrors({ api: 'Error changing MPIN' });
            } finally {
                setSubmitting(false);
            }


        }
    });

    const buttonStyles = {
        width: '30%',
        height: '38%',
    };

    return (
        <Container>

            <Box
                sx={{
                    width: '80%',
                    maxWidth: 400,
                    p: 3,
                    boxShadow: 2,
                    borderRadius: 2,
                    mt: { xs: 2, sm: 4, md: 6 },
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    
                        <>
                            <TextField
                                label="Set New MPIN"
                                variant="outlined"
                                fullWidth
                                name="newMpin"
                                type="password"
                                value={formik.values.newMpin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newMpin && Boolean(formik.errors.newMpin)}
                                helperText={formik.touched.newMpin && formik.errors.newMpin}
                                sx={{ mt: 2 }}
                            />
                            <TextField
                                label="Confirm MPIN"
                                variant="outlined"
                                fullWidth
                                name="confirmMpin"
                                type="password"
                                value={formik.values.confirmMpin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.confirmMpin && Boolean(formik.errors.confirmMpin)}
                                helperText={formik.touched.confirmMpin && formik.errors.confirmMpin}
                                sx={{ mt: 2 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginTop: '5%' }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    //onClick={() => setShowMpinFields(false)}
                                    sx={buttonStyles}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    sx={buttonStyles}
                                >
                                    {formik.isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                                </Button>
                            </Box>
                        </>
                    
                    
                    {formik.errors.api && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {formik.errors.api}
                        </Typography>
                    )}
                </form>
            </Box>

        </Container>
    );
};

export default SetMpin;
