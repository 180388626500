import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  CardContent,
  Card,
  Box,
} from "@mui/material";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import { Walllet_Icon } from "../../res/icons";
import { ReactComponent as ChargeBackCard } from '../Assets/Dashboard/ChargeBackCard.svg';


const UploadChargeBack = () => {

    const lineHeight = '50px';

  return (
    <Container style={{ marginTop: "calc(3% + 56px)", height:'100vh' }}>
        <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Charge Back</Typography>
            <PartnerArrowIcon />
            <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Upload Charge Back</Typography>
        </Grid>
        <Grid style={{marginTop:'1%'}}>
            <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Upload Charge Back</Typography>
        </Grid>
        <Grid style={{marginTop:'3%'}}>   
            <Grid container spacing={1}>
                {/* Card Section */}
                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none'}}>
                        <Card style={{ width: "100%", height: "260px", borderRadius: "12px", alignContent: "center", textAlign: 'center', backgroundColor:'#5FE9F3', boxShadow:'none'}}>
                            <CardContent>
                                <ChargeBackCard />
                                <Typography color="textSecondary" sx={{ color: "#FFFFFF", fontSize: "22px" }} gutterBottom>
                                    Charge Back
                                </Typography>
                                <Typography variant="h5" sx={{ color: "#FFFFFF", fontSize: "30px" }} component="h2" >
                                    ₹ 10,000
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>

                {/* Form Section */}
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Paper style={{ boxShadow:'none', padding: '2rem', borderRadius: '10px', boxShadow:'none'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>User Type</InputLabel>
                                    <Select label="User Type" >
                                    <MenuItem value="">Select User Type</MenuItem>
                                    <MenuItem value="AREADISTRIBUTOR">AREADISTRIBUTOR</MenuItem>
                                    <MenuItem value="RETAILER">RETAILER</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Users</InputLabel>
                                    <Select label="Users" >
                                    <MenuItem value="">Select User</MenuItem>
                                    <MenuItem value="User1">User1</MenuItem>
                                    <MenuItem value="User2">User2</MenuItem>
                                    <MenuItem value="User3">User3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Date"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Enter Amount" fullWidth />
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: { xs: '1%', md: '3%' } }}>
                                <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                    <Button
                                        sx={{
                                            border: '1.5px solid #FF0000',
                                            height: lineHeight,
                                            fontSize: '16px',
                                            borderRadius: '8px',
                                            color: '#FF0000',
                                            textTransform: 'none',
                                            width: { xs: '100%', md: '18%' },
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        sx={{
                                            height: lineHeight,
                                            fontSize: '16px',
                                            textTransform: 'none',
                                            width: { xs: '100%', md: '20%' },
                                            borderRadius: '8px',
                                            backgroundColor: "#027BFE",
                                            color: '#FFFFFF',
                                            '&:hover': {
                                                backgroundColor: "#027BFE",
                                                color: '#FFFFFF',
                                            }}}>
                                            Submit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    </Container>
  );
};

export default UploadChargeBack;
