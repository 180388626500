import React from 'react';
import { Button, Grid, Typography, Dialog, Box } from '@mui/material';
import { SuccessPopIcon } from '../../res/icons';
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from '@material-ui/core';
import { BbpsBIcon, ComplaintFinishIcon, PrintIcon } from '../../res/NewWebIcons';
import logo from "../Assets/login/iPaisaLogo2.png";
import GreenCheckmark from "../Assets/SuccessandFailedGif/GreenCheckmark.gif";

const ComplaintInvoice = ({ handleClose, data }) => {
    const { complaintDateTime, typeOfComplaint, txnReferenceId, complaintId, complaintStatus } = data;

    const successIconStyle = {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 70,
        height: 65,
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '600px',
                    padding: '1rem'
                }
            }}
        >
            <Grid container>
                {/* Icon Section */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={logo} alt="iPaisa Logo" style={{ height: 54, width: 54, backgroundColor: 'transparent', borderRadius: '50%' }} />
                    <Box sx={successIconStyle}>
                        <img src={GreenCheckmark} style={{ height: '100%', width: '100%', borderRadius: '50%' }} alt="Success Gif" />
                    </Box>
                    <BbpsBIcon />
                </Grid>

                {/* Title Section */}
                <Grid container justifyContent="center" style={{ marginTop: '4%' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '22px', color: 'green' }}>
                        Successful Complaint Registration
                    </Typography>
                </Grid>

                {/* Details Section */}
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '70%', marginTop: '6%' }}>
                    {/* Left Side (Labels) */}
                    <Grid container direction="column" spacing={1} xs={6}>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Date & Time</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Type of Complaint</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Transaction Ref ID</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint ID</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Status</Typography>
                    </Grid>

                    {/* Right Side (Values) */}
                    <Grid container direction="column" spacing={1} xs={6}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintDateTime}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{typeOfComplaint}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{txnReferenceId}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintId}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintStatus}</Typography>
                    </Grid>
                </Grid>

                {/* Close Button */}
                <Grid container justifyContent="flex-end" style={{ marginTop: '2rem' }}>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ComplaintInvoice;
