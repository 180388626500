import { SET_BANK_DETAILS } from '../actionTypes';

const initialState = {
    bankDetails: [],
};

const bankReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BANK_DETAILS:
            return {
                ...state,
                bankDetails: action.payload,
            };
        default:
            return state;
    }
};

export default bankReducer;
