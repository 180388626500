import {
  Box,
  Button,
  Container,
  Grid2,
  Input,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const QRCodeReady = ({ virtualAccount }) => {
  const convertToDate = (timestamp) => {
    // Convert to milliseconds and create a Date object
    const date = new Date(timestamp * 1000);

    // Format the date as needed
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "2-digit",
      // minute: "2-digit",
      // second: "2-digit",
    });

    return formattedDate;
  };
  return (
    <>
      <Paper elevation={2} style={{ padding: "2rem", marginTop: 0.5 }}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          King QR Details
        </Typography>
        <Stack spacing={1}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Here is Your Qr Code</Typography>
            <Link href={virtualAccount.image_url}>
              <img
                src={virtualAccount.image_url}
                style={{
                  height: "750px",
                  width: "750px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Box>
          <Box sx={{ marginTop: 4, marginBottom: 2 }}>
            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>Account Name</label>
                <Typography>{virtualAccount.name}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>
                  QR Code Description
                </label>
                <Typography>{virtualAccount.description}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>QR Created Date</label>

                <Typography>
                  {convertToDate(virtualAccount.created_at)}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>
                  Total Amount Recieved
                </label>
                <Typography>
                  {virtualAccount.payments_amount_received / 100}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>QR Status</label>
                <Typography>{virtualAccount.status}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>Expired Date</label>
                <Typography>{virtualAccount.closed_at}</Typography>
              </Grid2>
            </Grid2>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default QRCodeReady;
