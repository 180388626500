import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";

const VariableCommission = () => {
  // Define state to store commission percentage for each user type
  const [commissionPercentage, setCommissionPercentage] = useState({
    "Channel Partner": "",
    "Super Distributor": "",
    "Master Distributor": "",
    "Area Distributor": "",
    Retailer: "",
  });

  // Function to fetch commission values from the backend API
  const fetchCommissionValues = async () => {
    try {
      const response = await fetch("your-api-endpoint-for-commissions");
      if (!response.ok) {
        throw new Error("Failed to fetch commission values");
      }
      const data = await response.json();
      setCommissionPercentage(data); // Update state with fetched commission values
    } catch (error) {
      console.error("Error fetching commission values:", error);
    }
  };

  // Fetch commission values when the component mounts
  useEffect(() => {
    fetchCommissionValues();
  }, []);

  // Custom validation function for user ID
  const validateUserId = (value, userType) => {
    let prefix;
    switch (userType) {
      case "Channel Partner":
        prefix = "ICP";
        break;
      case "Super Distributor":
        prefix = "ISD";
        break;
      case "Master Distributor":
        prefix = "IMD";
        break;
      case "Area Distributor":
        prefix = "IAD";
        break;
      case "Retailer":
        prefix = "IR";
        break;
      default:
        return true; // If user type is not set, return true
    }
    return value.startsWith(prefix); // Check if the user ID starts with the correct prefix
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: "10%", padding:'0px 100px 0px 100px'  }}>
      <Paper elevation='2' style={{borderRadius:'10px'}}>
        <CardContent style={{padding:'20px 30px 40px 30px'}}>
          <Typography variant="h6" align="start" style={{fontWeight:'bold'}} gutterBottom>
            Variable Payment Amount
          </Typography>
          <Formik
            initialValues={{
              userType: "",
              userId: "",
              amount: "",
            }}
            validationSchema={Yup.object({
              userId: Yup.string()
                .required("User ID is required")
                .test(
                  "user-id-prefix",
                  "Invalid user ID prefix",
                  (value, context) =>
                    validateUserId(value, context.parent.userType)
                ),
              amount: Yup.number()
                .typeError("Amount must be a number")
                .required("Amount is required"),
            })}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              // Handle form submission
              // You can use values.userType, values.userId, values.amount here
              resetForm();
            }}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>User Type</InputLabel>
                      <Field as={Select} name="userType" label='User Type' defaultValue="">
                        <MenuItem value="Channel Partner">
                          Channel Partner
                        </MenuItem>
                        <MenuItem value="Super Distributor">
                          Super Distributor
                        </MenuItem>
                        <MenuItem value="Master Distributor">
                          Master Distributor
                        </MenuItem>
                        <MenuItem value="Area Distributor">
                          Area Distributor
                        </MenuItem>
                        <MenuItem value="Retailer">Retailer</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  {values.userType && (
                    <Grid item xs={12}>
                      <TextField
                        label="Commission Percentage"
                        type="text"
                        value={commissionPercentage[values.userType]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      name="userId"
                      as={TextField}
                      label="User ID"
                      type="text"
                      fullWidth
                      error={errors.userId && touched.userId}
                    />
                    {errors.userId && touched.userId && (
                      <Typography variant="body2" color="error">
                        {errors.userId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="amount"
                      as={TextField}
                      label="Amount"
                      type="text"
                      fullWidth
                      error={errors.amount && touched.amount}
                    />
                    {errors.amount && touched.amount && (
                      <Typography variant="body2" color="error">
                        {errors.amount}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    
                  </Grid>
                  <Grid item xs={6}>
                    <Button type="submit" style={{textTransform:'none', borderRadius:'7px', backgroundColor: '#027BFE', color:'white', height:'135%', fontSize:'15px'}} fullWidth>
                      Pay
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Paper>
    </Container>
  );
};

export default VariableCommission;
