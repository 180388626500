import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import axios from "axios";

const countryLanguages = {
  US: [
    { label: "English", value: "us_en" },
    { label: "Spanish", value: "us_es" },
  ],
  UK: [
    { label: "English", value: "uk_en" },
    { label: "Welsh", value: "uk_welsh" },
  ],
  India: [
    { label: "Hindi", value: "hindi" },
    { label: "Marathi", value: "marathi" },
    { label: "Tamil", value: "tamil" },
  ],
  Canada: [
    { label: "English", value: "canada_en" },
    { label: "French", value: "canada_fr" },
  ],
  Australia: [
    { label: "English", value: "au_en" },
    { label: "Aboriginal languages", value: "au_aboriginal" },
  ],
  Germany: [
    { label: "German", value: "german" },
    { label: "Turkish", value: "turkish" },
  ],
  France: [
    { label: "French", value: "french" },
    { label: "Arabic", value: "arabic" },
  ],
  China: [
    { label: "Mandarin", value: "mandarin" },
    { label: "Cantonese", value: "cantonese" },
    { label: "English", value: "china_en" },
  ],
  Japan: [
    { label: "Japanese", value: "japanese" },
    { label: "English", value: "japan_en" },
  ],
  Brazil: [
    { label: "Portuguese", value: "portuguese" },
    { label: "Spanish", value: "spanish" },
  ],
  Mexico: [
    { label: "Spanish", value: "mexican_spanish" },
    { label: "Mayan languages", value: "mayan" },
  ],
  Russia: [
    { label: "Russian", value: "russian" },
    { label: "Tatar", value: "tatar" },
  ],
  Malaysia: [
    { label: "Malay", value: "malay" },
    { label: "English", value: "malaysia_en" },
    { label: "Tamil", value: "tamil" },
    { label: "Chinese", value: "chinese" },
  ],
  Dubai: [
    { label: "Arabic", value: "arabic" },
    { label: "English", value: "dubai_en" },
    { label: "Hindi", value: "hindi" },
    { label: "Urdu", value: "urdu" },
  ],
  // Add more countries and their languages here
};

const GeneralSettings = () => {
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openThemes, setOpenThemes] = useState(false);

  // State management for form fields
  const [userProfile, setUserProfile] = useState({
    name: "",
    dob: "",
    address: "",
    documents: null,
    linkedAccounts: "",
    photo: null,
  });

  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleUserProfileChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setUserProfile({ ...userProfile, [name]: files[0] });
    } else {
      setUserProfile({ ...userProfile, [name]: value });
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });

    // Check password strength
    if (name === "newPassword") {
      const strongPasswordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!strongPasswordPattern.test(value)) {
        setPasswordError(
          "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character."
        );
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSaveUserProfile = async () => {
    // Save user profile logic
    const formData = new FormData();
    Object.keys(userProfile).forEach((key) => {
      formData.append(key, userProfile[key]);
    });

    try {
      await axios.post("/api/user/profile", formData);
      setOpenUserProfile(false);
    } catch (error) {
      console.error("Error saving user profile", error);
    }
  };

  const handleSavePassword = async () => {
    // Save password logic
    if (password.newPassword !== password.confirmNewPassword) {
      setPasswordError("New password and confirm password do not match.");
      return;
    }

    try {
      await axios.post("/api/user/password", password);
      setOpenChangePassword(false);
    } catch (error) {
      console.error("Error saving password", error);
    }
  };

  const handleSaveLanguage = async () => {
    // Save language logic
    try {
      await axios.post("/api/user/language", { language: selectedLanguage });
      setOpenLanguage(false);
    } catch (error) {
      console.error("Error saving language", error);
    }
  };

  const handleSaveTheme = async () => {
    // Save theme logic
    try {
      await axios.post("/api/user/theme", { theme: selectedTheme });
      setOpenThemes(false);
    } catch (error) {
      console.error("Error saving theme", error);
    }
  };

  const renderImagePreview = () => {
    if (userProfile.photo) {
      return (
        <img
          src={URL.createObjectURL(userProfile.photo)}
          alt="Profile Preview"
          style={{ width: "100%", height: "auto", marginTop: "16px" }}
        />
      );
    }
    return null;
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: "10%" }}>
      <Box
        mt={4}
        mb={2}
        p={4}
        boxShadow={3}
        borderRadius={4}
        bgcolor="background.paper"
      >
        <Grid container spacing={4} >
          <Grid item xs={12}>
            <Button
              style={{backgroundColor: '#027BFE', color: 'white', borderRadius:'10px'}}
              onClick={() => setOpenUserProfile(true)}
              fullWidth
            >
              User Profile Details
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{backgroundColor: '#027BFE', color: 'white', borderRadius:'10px'}}
              onClick={() => setOpenChangePassword(true)}
              fullWidth
            >
              Change MPIN
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{backgroundColor: '#027BFE', color: 'white', borderRadius:'10px'}}
              onClick={() => setOpenLanguage(true)}
              fullWidth
            >
              Language
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{backgroundColor: '#027BFE', color: 'white', borderRadius:'10px'}}
              onClick={() => setOpenThemes(true)}
              fullWidth
            >
              Themes
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openUserProfile} onClose={() => setOpenUserProfile(false)}>
        <DialogTitle style={{fontWeight:'bold'}}>User Profile Details</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={userProfile.name}
            onChange={handleUserProfileChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="DOB"
            name="dob"
            type="date"
            value={userProfile.dob}
            onChange={handleUserProfileChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={userProfile.address}
            onChange={handleUserProfileChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Upload Documents"
            name="documents"
            type="file"
            onChange={handleUserProfileChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Linked Accounts"
            name="linkedAccounts"
            value={userProfile.linkedAccounts}
            onChange={handleUserProfileChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Change Photo"
            name="photo"
            type="file"
            onChange={handleUserProfileChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          {renderImagePreview()}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={() => setOpenUserProfile(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveUserProfile} color="primary">
            Save
          </Button>
        </DialogActions> */}

          <Grid item xs={12} padding='0px 38px 20px 22px' marginTop='1%'>
              <Box display="flex" justifyContent="center" gap={2} height='130%'>
                <Button
                  onClick={() => setOpenUserProfile(false)}
                  style={{backgroundColor: '#E3E4E6', color:'#808080', textTransform:'none', width:'50%', borderRadius:'9px'}}
                  >Cancel
                </Button>   
                <Button
                onClick={handleSaveUserProfile}
                style={{backgroundColor: '#027BFE', color:'white', fontWeight: 'bold', textTransform:'none', width:'50%', borderRadius:'9px'}}                        
                >Save
                </Button>
              </Box>
          </Grid>
      </Dialog>

      <Dialog
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      >
        <DialogTitle style={{fontWeight:'bold'}}>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Old Password"
            name="oldPassword"
            type="password"
            value={password.oldPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            type="password"
            value={password.newPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
          <Typography variant="body2" color="error">
            {passwordError}
          </Typography>
          <TextField
            fullWidth
            label="Confirm New Password"
            name="confirmNewPassword"
            type="password"
            value={password.confirmNewPassword}
            onChange={handlePasswordChange}
            margin="normal"
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={() => setOpenChangePassword(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSavePassword}
            color="primary"
            disabled={!!passwordError}
          >
            Save
          </Button>
        </DialogActions> */}
        <Grid item xs={12} padding='0px 22px 20px 22px' marginTop='1%'>
              <Box display="flex" justifyContent="center" gap={2} height='130%'>
                <Button
                  onClick={() => setOpenChangePassword(false)} 
                  style={{backgroundColor: '#E3E4E6', color:'#808080', textTransform:'none', width:'50%', borderRadius:'9px'}}
                  >Cancel
                </Button>   
                <Button
                onClick={handleSavePassword}
                disabled={!!passwordError}
                style={{backgroundColor: '#027BFE', color:'white', fontWeight: 'bold', textTransform:'none', width:'50%', borderRadius:'9px'}}                        
                >Save
                </Button>
              </Box>
        </Grid>

      </Dialog>

          
      <Dialog open={openLanguage} onClose={() => setOpenLanguage(false)}>
        <DialogTitle style={{fontWeight:'bold'}}>Language</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedCountry}
            onChange={(e) => {
              setSelectedCountry(e.target.value);
              setSelectedLanguage("");
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Country
            </MenuItem>
            {Object.keys(countryLanguages).map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
          {selectedCountry && (
            <Select
              fullWidth
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              displayEmpty
              style={{ marginTop: 16 }}
            >
              <MenuItem value="" disabled>
                Select Preferred Language
              </MenuItem>
              {countryLanguages[selectedCountry].map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLanguage(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveLanguage} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openThemes} onClose={() => setOpenThemes(false)}>
        <DialogTitle>Themes</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedTheme}
            onChange={(e) => setSelectedTheme(e.target.value)}
            displayEmpty
            renderValue={
              selectedTheme
                ? () => {
                    const theme =
                      selectedTheme.charAt(0).toUpperCase() +
                      selectedTheme.slice(1);
                    return theme;
                  }
                : () => "Select Theme"
            }
          >
            <MenuItem value="" disabled>
              Select Theme
            </MenuItem>
            <MenuItem value="light">Light</MenuItem>
            <MenuItem value="dark">Dark</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenThemes(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveTheme} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GeneralSettings;
