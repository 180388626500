import React, { useState } from 'react';
import { Button, Box, CircularProgress, Typography } from '@mui/material';

const BulkPayout = ({ inputHeight }) => {
    const [file, setFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleBulkSubmit = async () => {
        if (!file) {
            setError('Please upload a file');
            return;
        }
        
        setIsSubmitting(true);
        setError('');

        const formData = new FormData();
        formData.append('file', file);

        try {
            // API call to handle bulk payout
            const response = await fetch('YOUR_BULK_PAYOUT_API_ENDPOINT', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                alert('Bulk payout successful');
            } else {
                setError(result.message || 'Bulk payout failed');
            }
        } catch (error) {
            setError('Network error');
        }

        setIsSubmitting(false);
    };

    return (
        <>
            <input type="file" onChange={handleFileChange} />
            <Box display="flex" justifyContent="end" gap={2} mt={2}>
                <Button
                    sx={{
                        border: '1.5px solid #FF0000',
                        color: '#FF0000',
                        textTransform: "none",
                        width: { xs: '100%', md: '18%' },
                        fontSize: '16px',
                        borderRadius: '8px',
                        height: inputHeight
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleBulkSubmit}
                    sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        textTransform: "none",
                        width: { xs: '100%', md: '18%' },
                        fontSize: '16px',
                        borderRadius: '8px',
                        height: inputHeight,
                        '&:hover': {
                            backgroundColor: "#027BFE",
                        },
                    }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Bulk Payout'}
                </Button>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
        </>
    );
};

export default BulkPayout;
