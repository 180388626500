import React, { useState } from 'react';
import StepperComponent from './StepperComponent';
import Form1 from './Form1';
import Form2 from './Form2';
import Form3 from './Form3';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { PartnerArrowIcon } from '../../../res/NewWebIcons';

const Cashdrop = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Container sx={{ padding: {xs:'0.8rem', md:'0rem'}, marginTop: 'calc(3% + 56px)', marginBottom:'2%' }}>
      <Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>CMS</Typography>
          <PartnerArrowIcon />
          <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Cash Drop</Typography>
        </Grid>

        <Grid style={{ marginTop: '1%' }}>
          <Grid>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Cash Drop</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Paper sx={{padding:{xs:'0.8rem', md:'2rem'}, borderRadius:'12px', boxShadow:'none', marginTop:'3%'}}>
        <StepperComponent activeStep={activeStep} />
        {activeStep === 0 && <Form1 handleNext={handleNext} />}
        {activeStep === 1 && <Form2 handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 2 && <Form3 handleBack={handleBack} />}
      </Paper>
    </Container>
  );
};

export default Cashdrop;
