import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import axios from "axios";


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));
const TicketTable = () => {
    const classes = useStyles();
    const [userTickets, setUserTickets] = useState([]);

    // Sample data for the table

    const fetchTickets = async () => {
        try {
            const userId = localStorage.getItem('userId');

            if (!userId) {
                throw new Error("User ID not found in localStorage");
            }

            const response = await axios.get('https://testapisupport.ipaisa.co.in/supportapp/auth/ticket/getbyuser', {
                headers: {
                    'userid': userId,
                },
            });

            setUserTickets(response.data);
            console.log('Fetched tickets:', response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error.message);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>

            <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                <Table>
                    <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                        <TableRow>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Ticket ID</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Customer Name</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Issue Type</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Status</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Priority</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Assignee Agent</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Assigned Date</TableCell>
                            <TableCell style={{ color: "#FFF", fontWeight: "bold" }}>Last Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userTickets.map((row, index) => (
                            <TableRow key={row.id}
                                className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.username}</TableCell>
                                <TableCell>{row.issueType}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{row.priority}</TableCell>
                                <TableCell>{row.assigneeAgent}</TableCell>
                                <TableCell>{row.assignedDate}</TableCell>
                                <TableCell>{row.lastUpdated}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TicketTable;
