import React, { useState } from "react";
import { Container, Grid, Button, Typography, Paper, Box } from "@mui/material";
import BalanceTopComponent from "../Balance/BalanceTopComponent";
import NewFixCom from "./NewFixCom";
import NewVariableCom from "./NewVariableCom";

const NewFixandVariableCom = () => {
  const [activeForm, setActiveForm] = useState("fix");

  const handleFormToggle = (formType) => {
    setActiveForm(formType);
  };

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
       <BalanceTopComponent />
        <Grid container spacing={2} style={{ marginTop: '3%' }}>
            <Grid item xs={12} md={12} order={{ xs: 2, md: 1 }}>
                <Paper style={{ boxShadow:'none', padding: '1rem', borderRadius: '8px' }}>
                        <Typography variant="h6" align="start" style={{ fontWeight: "bold", padding: '1rem', }}>
                        Set Partner Commissions
                        </Typography>
                        <Grid container spacing={3} justifyContent="center" style={{ marginBottom: "2rem" }}>
                        <Grid item>
                            <Button
                            onClick={() => handleFormToggle("fix")}
                            style={{
                                textTransform: "none",
                                color: activeForm === "fix" ? "#027BFE" : "#000000",
                                borderBottom: activeForm === "fix" ? "2px solid #027BFE" : "none",
                            }}
                            >
                            Fix Commission
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                            onClick={() => handleFormToggle("variable")}
                            style={{
                                textTransform: "none",
                                color: activeForm === "variable" ? "#027BFE" : "#000000",
                                borderBottom: activeForm === "variable" ? "2px solid #027BFE" : "none",
                            }}
                            >
                            Variable Commission
                            </Button>
                        </Grid>
                        </Grid>

                        {activeForm === "fix" && <NewFixCom />}
                        {activeForm === "variable" && <NewVariableCom />}
                </Paper>
            </Grid>
        </Grid>
    </Container>
  );
};

export default NewFixandVariableCom;
