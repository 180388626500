import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';

const CustomStepIconRoot = styled('div')(({ ownerState }) => ({
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: ownerState.completed || ownerState.active ? '#7A69EE' : '#e0e0e0',
  color: ownerState.completed || ownerState.active ? '#fff' : '#000',
}));

const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }}>
      {completed ? <Check /> : icon}
    </CustomStepIconRoot>
  );
};

const steps = ['Enter Details', 'Confirm', 'Completed'];

const StepperComponent = ({ activeStep }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} />}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;
