//
import React, { useState, useEffect } from "react";
import {
    Container, Table, TableBody, TableCell,Typography, TableContainer, TableHead, TableRow, Paper, Button, Box, Grid, TablePagination, Switch, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { Visibility, Edit, Delete, Add, Subject } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));


const initialData = [
    { srNo: 1, ticket: 'FTS0001', date: '10-06-2024 04:14 PM', name: 'Abhijit', usertype: 'DEMO', subject: 'Test'},
    { srNo: 2, ticket: 'FTS0002', date: '11-06-2024 05:30 PM', name: 'Akash', usertype: 'DEMO', subject: 'Test'},
    { srNo: 3, ticket: 'FTS0003', date: '11-06-2024 05:30 PM', name: 'Akashay', usertype: 'DEMO', subject: 'Test'},
    { srNo: 4, ticket: 'FTS0004', date: '11-06-2024 05:30 PM', name: 'Akashay', usertype: 'DEMO', subject: 'Test'},
    { srNo: 5, ticket: 'FTS0005', date: '11-06-2024 05:30 PM', name: 'Akashay', usertype: 'DEMO', subject: 'Test'},
    { srNo: 6, ticket: 'FTS0006', date: '11-06-2024 05:30 PM', name: 'Akashay', usertype: 'DEMO', subject: 'Test'},
    { srNo: 7, ticket: 'FTS0007', date: '11-06-2024 05:30 PM', name: 'Akashay', usertype: 'DEMO', subject: 'Test'},

];

const OngoingTicket = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
   const [data, setData] = useState(initialData);
   const [activeButton, setActiveButton] = useState(null);
   const navigate = useNavigate();

    useEffect(() => {
        fetchData(); // Fetch data when component mounts
    }, []); // Empty dependency array ensures this effect runs only once

    const fetchData = async () => {
        try {
            // Fetch data from your endpoint
            const response = await fetch('your_endpoint_url');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Your existing event handlers and other functions go here...
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleButtonClick = (value) => {
    setActiveButton(value);
};

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };


    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSave = () => {
        handleClose();
    };

    const handleCloseTicket = () => {
        navigate("/closeticket")
      };

      const handleOpenOngoingTicket = () => {
        navigate("/ongoingticket")
      };

      const handlePendingClick = () => {
        navigate("/pendingticket")
      };

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index + page * rowsPerPage, 1);
        setData(newData);
    };

    const filteredData = data.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    return (
        <Container maxWidth="xl" style={{  marginTop: '8%' }}>
            <Box mt={4} mb={2}>
            {/* button */}

            <Grid style={{display:"flex"}}>
           <Grid style={{display:"flex"}}>
             <Typography style={{ fontWeight: 'bold',fontSize: '18px' }} >Support </Typography>
             <Typography style={{marginLeft:"3%",fontWeight: 'bold',fontSize: '18px'}}> Ticket</Typography>
              </Grid>
            <Grid container alignItem="center" justifyContent="flex-end">
                           <Button disableRipple
                                disableElevation
                                // onClick={() => handleButtonClick('Pending')}
                                onClick={handlePendingClick}
                                sx={{ borderRadius: 8, marginRight:'1rem', backgroundColor: activeButton === 'Pending' ? 'primary.main' : 'grey.300',
                                    color: activeButton === 'Pending' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Pending' ? 'primary.main' : 'grey.300',
                                    },}} type="submit" variant="contained" >
                                    <PendingActionsIcon/>
                            Pending
                           </Button>
                           <Button
                           disableRipple
                           disableElevation
                        //    onClick={() => handleButtonClick('Ongoing')}
                        onClick={handleOpenOngoingTicket}

                            sx={{ borderRadius: 8, marginRight:'1rem', backgroundColor: activeButton === 'Ongoing' ? 'primary.main' : 'grey.300',
                                    color: activeButton === 'Ongoing' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Ongoing' ? 'primary.main' : 'grey.300',
                                    },}} type="submit" variant="contained" >
                                    <SignalCellularAltIcon/>
                            Ongoing
                           </Button>
                           <Button
                           disableRipple
                           disableElevation
                        //    onClick={() => handleButtonClick('Closed')}
                        onClick={handleCloseTicket}
                           sx={{ borderRadius: 8, marginRight:'1rem', backgroundColor: activeButton === 'Closed' ? 'primary.main' : 'grey.300',
                                    color: activeButton === 'Closed' ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: activeButton === 'Closed' ? 'primary.main' : 'grey.300',
                                    },}} type="submit" variant="contained" >
                                    <CheckIcon/>
                            Closed
                           </Button>
                    </Grid>
        </Grid>
                <Grid container justifyContent="space-between" alignItems="center" >
                <Grid></Grid>
                    <Grid item >
                        <TextField
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleInputChange}
                            style={{ marginBottom: '2%', width: '200px', height: '30px', marginTop: '10%'}}
                        />
                    </Grid>
                   
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: '4%' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE' }}>
                        <TableRow>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Sr.No</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Ticket ID</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Date & Time</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Name</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>User Type</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Subject</TableCell>
                                <TableCell style={{color:'white' , textAlign: 'center' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const shouldDisplayRow = Object.values(row).some((value) =>
                                        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                                    );

                                    if (!shouldDisplayRow) {
                                        return null;
                                    }

                                    return (
                                        <TableRow key={index}
                                             className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.srNo}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.ticket}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.date}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.name}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.usertype}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{row.subject}</TableCell>
                                           
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton color="primary" aria-label="view" onClick={() => handleClickOpen(row)}>
                                                    <Visibility />
                                                </IconButton>
                                                <IconButton color="secondary" aria-label="delete" onClick={() => handleDelete(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container justifyContent="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle bgcolor={'#027BFE'} color={'#ffffff'} strong>User Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} marginTop={5} borderRadius={10} border={1} boxShadow={3}>
                            {selectedRow && (
                                <div>
                                    <p><strong>Ticket ID:</strong> {selectedRow.ticket}</p>
                                    <p><strong>Name:</strong> {selectedRow.name}</p>
                                    <p><strong>User Type:</strong> {selectedRow.usertype}</p>
                                    <p><strong>Date & Time:</strong> {selectedRow.date}</p>
                                    <p><strong>DOB:</strong> {'17-07-1998'}</p>
                                    <p><strong>Address:</strong> {selectedRow.address}</p>
                                    <p><strong>City:</strong> {selectedRow.address}</p>
                                    <p><strong>State:</strong> {selectedRow.address}</p>
                                    <p><strong>Pincode:</strong> {selectedRow.address}</p>
                                    <p><strong>Wallet Balance:</strong> {selectedRow.walletBalance}</p>
                                    <p><strong>Status:</strong> {selectedRow.status}</p>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <p><strong>Uploaded Agreement:</strong></p>
                            <iframe
                                src={`${process.env.PUBLIC_URL}/IpaisaApplication.pdf`}
                                width="100%"
                                height="400px"
                                style={{ border: 'none' }}
                                title="PDF Document"
                            ></iframe>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} style={{backgroundColor: '#FF0000', color: '#ffffff', textTransform:'none'}}>Close</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default OngoingTicket;

