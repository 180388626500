import React, { useState } from 'react';
import {
  Container, Tabs, Tab, CircularProgress, TablePagination, Button, Box,
  Grid,
  Typography,
} from '@mui/material';
import SearchAndFilters from './SearchAndFilters';
import TransactionTable from './TransactionTable';
import axios from 'axios';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { DownloadIcon, PartnerArrowIcon } from '../../../res/NewWebIcons';

const HierarchyReport = () => {
  const [tabValue, setTabValue] = useState('Pay-In');
  const [payInRecords, setPayInRecords] = useState([]);
  const [payOutRecords, setPayOutRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    userType: '',
    selectedUserId: null,
    searchTerm: '',
    fromDate: '',
    toDate: '',
  });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handleSearch = async (params, page = 0, size = 10) => {
    setLoading(true);
    const { userType, selectedUserId, searchTerm, fromDate, toDate } = params;
    const jwtToken = localStorage.getItem('jwtToken');
    let apiEndpoint = '';
    const queryParams = {
      searchTerm,
      fromDate,
      toDate,
      userId: selectedUserId,
      page,
      size,
    };

    if (tabValue === 'Pay-In') {
      apiEndpoint = 'https://testapi.ipaisa.co.in/myapp/admin/getAllPyInTxnsByUserId';
    } else {
      apiEndpoint = 'https://testapi.ipaisa.co.in/myapp/admin/getAllPayoutTxnsByUserId';
    }

    try {
      const response = await axios.get(apiEndpoint, {
        headers: { Authorization: `Bearer ${jwtToken}` },
        params: queryParams,
      });

      if (response.data.statusCodeValue === 200) {
        const total = response.data.body.totalRecords;
        const currentPage = response.data.body.currentPage;
        const totalPages = response.data.body.totalPages;

        setTotalRecords(total);
        setPage(currentPage);
        setTotalPages(totalPages);
        setCurrentPage(currentPage);

        if (tabValue === 'Pay-In') {
          const username = response.data.body.sender_address.username;
          const userAddress = response.data.body.sender_address.usersAddress;

          const records = response.data.body.data.map(item => ({
            id: item.id,
            txnid: item.txnid,
            easepayid: item.easepayid,
            cardType: item.cardType,
            upiVa: item.upiVa,
            cardnum: item.cardnum,
            phone: item.phone,
            addedon: item.addedon,
            netAmountDebit : item.netAmountDebit,
            amount: item.amount,
            status: item.status,
            userId: item.userId,
            mode: item.mode,
            settlement: item.settlement,
            name: username,
            userAddress: userAddress,
          }));
          setPayInRecords(records);
        } else {
          const { transaction, sender_address } = response.data.body.data;
          const records = transaction?.map(item => ({
            ...item,
            sender_address: sender_address?.usersAddress || null,
            sender_name: sender_address?.username || null,
          })) || [];
          setPayOutRecords(records);
        }
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching transaction data:', error);
    } finally {
      setLoading(false);
    }
  };



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPayInRecords([]);
    setPayOutRecords([]);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleSearch(searchParams, newPage, size);
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setSize(newSize);
    setPage(0);
    handleSearch(searchParams, 0, newSize);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    let tableColumn = [];
    const tableRows = [];

    const records = tabValue === 'Pay-In' ? payInRecords : payOutRecords;

    // Set column headers based on the transaction type
    if (tabValue === 'Pay-In') {
      tableColumn = ["Transaction ID", "Name", "Date", "Status","PayIn Amount", "Amount", "Settlement", "User ID"];
      records.forEach(record => {
        const data = [
          record.txnid,
          record.firstname,
          new Date(record.addedon).toLocaleString(),
          record.status,
          Number(record.amount).toFixed(2),
          record.settlement,
          record.userId,
        ];
        tableRows.push(data);
      });
    } else {
      tableColumn = ["Status", "External Ref", "Transaction Value", "Payee Account", "Payer Name", "Date", "Order ID", "Wallet Opening Balance", "Wallet Closing Balance", "Transfer Mode"];
      records.forEach(record => {
        const data = [
          record.status,
          record.externalRef,
          record.txnValue,
          record.payeeAccount,
          record.payerName,
          new Date(record.timestamp).toLocaleString(),
          record.orderid,
          Number(record.walletOpeningBalance).toFixed(2),
          Number(record.walletClosingBalance).toFixed(2),
          record.transfer_mode,
        ];
        tableRows.push(data);
      });
    }

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save(`${tabValue}_Transactions.pdf`);
  };

  const downloadCSV = () => {
    let csvContent = '';
    const records = tabValue === 'Pay-In' ? payInRecords : payOutRecords;

    // Set column headers based on the transaction type
    if (tabValue === 'Pay-In') {
      csvContent += "Transaction ID,Name,Phone,Mode,Date,Status,Amount,Settlement,User ID\n";
      records.forEach(record => {
        csvContent += `${record.txnid},${record.firstname},${record.phone},${record.mode},${new Date(record.addedon).toLocaleString()},${record.status},${Number(record.amount).toFixed(2)},${record.settlement},${record.userId}\n`;
      });
    } else {
      csvContent += "Status,External Ref,Transaction Value,Payee Account,Payer Name,Date,Order ID,Wallet Opening Balance,Wallet Closing Balance,Transfer Mode\n";
      records.forEach(record => {
        csvContent += `${record.status},${record.externalRef},${record.txnValue},${record.payeeAccount},${record.payerName},${new Date(record.timestamp).toLocaleString()},${record.orderid},${Number(record.walletOpeningBalance).toFixed(2)},${Number(record.walletClosingBalance).toFixed(2)},${record.transfer_mode}\n`;
      });
    }

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${tabValue}_Transactions.csv`);
  };


  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Reports</Typography>
          <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Hierarchywise Report</Typography>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '1%' }}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    fontSize: { xs: '16px', md: '19px' },
                    color: '#343C6A',
                    textAlign: { xs: 'center', md: 'left' },
            }}>
            Hierarchywise Report
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}>
          <Button
            onClick={downloadCSV} // Single handler for both DEBIT and CREDIT
            sx={{
              textTransform: 'none',
              backgroundColor: '#027BFE',
              color: '#FFFFFF',
              borderRadius: '10px',
              padding: '8px 16px',
              fontFamily: 'sans-serif',
              fontSize: { xs: '12px', md: '14px' },
              height: '40px',
              gap: 1,
            }} >
            <DownloadIcon />
            Download CSV
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              backgroundColor: '#027BFE',
              color: '#FFFFFF',
              borderRadius: '10px',
              padding: '8px 16px',
              fontFamily: 'sans-serif',
              fontSize: { xs: '12px', md: '14px' },
              height: '40px',
              minWidth: { xs: '120px', sm: '140px' },
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
            onClick={downloadPDF}>
            <DownloadIcon />
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="start" alignItems="center" mb={2}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Pay-In" value="Pay-In" />
          <Tab label="Pay-Out" value="Pay-Out" />
        </Tabs>
      </Box>

      <SearchAndFilters
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        handleSearch={(params) => handleSearch(params, page, size)}
      />

      {loading ? (
        <CircularProgress sx={{ marginTop: '2%' }} />
      ) : (
        <>
          {tabValue === 'Pay-In' ? (
            <TransactionTable userRecords={payInRecords} page={page} rowsPerPage={size} transactionType="Pay-In" />
          ) : (
            <TransactionTable userRecords={payOutRecords} page={page} rowsPerPage={size} transactionType="Pay-Out" />
          )}

          <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ marginTop: '1%' }}>
            <TablePagination
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[10, 25, 50]}
              labelDisplayedRows={({ from, to, count }) => `${currentPage + 1} of ${totalPages + 1}`}
              labelRowsPerPage="Rows per page"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default HierarchyReport;
