import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Paper,
  Grid,
  CardMedia,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Menu,
  MenuItem,
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid2,
} from "@mui/material";
import { Menu as MenuIcon, ArrowDropDown } from "@mui/icons-material";
import logo from "../Assets/iPaisaLogo/iPaisaLogo2.png";
import "@fontsource/roboto";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MorqueWebImg from '../Assets/MorqueWebImg/vn.png';
import { color } from "framer-motion";
import { styled, keyframes } from "@mui/system";

// Define the blink keyframes for the red dot and text
const blinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

// Styled components with the blink animation
const BlinkText = styled("span")({
  animation: `${blinkAnimation} 1s steps(1, start) infinite`,
  textTransform: 'none',
  color: 'white', // Change the color as needed
});

const RedDot = styled("span")({
  height: "10px",
  width: "10px",
  backgroundColor: "red",
  borderRadius: "50%",
  display: "inline-block",
  marginLeft: "5px",
  animation: `${blinkAnimation} 1s steps(1, start) infinite`, // Apply blink animation
});

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Home");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const theme = useTheme();
  const [imageOpen, setImageOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));



  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolling(true);
  //     } else {
  //       setScrolling(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const offset = section.offsetTop - 100; // Adjust this value as needed
        const height = section.offsetHeight;
        if (scrollPosition >= offset && scrollPosition < offset + height) {
          setActiveTab(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const path = location.pathname.split("/")[1] || "Home"; // Get path from URL
    setActiveTab(path);
  }, [location.pathname]);

  const handleTabClick = (tabName) => {
    console.log(tabName,"This is tab name")
    setActiveTab(tabName);
    navigate(`/${tabName}`, {replace: true});
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = (path) => {
    setAnchorEl(null);
    if (path) navigate(`/${path}`);
  };


  // Function to handle the "New Update" click
  const handleNewUpdateClick = () => {
    setImageOpen(true); // Open the image modal when button is clicked
  };

    // Function to close the modal
    const handleClose = () => {
      setImageOpen(false);
    };

  const menuItems = [
    { label: "Home", path: "" },
    { label: "About Us", path: "Aboutus" },
    { label: "Services", path: "Services" },
    { label: "Blogs", path: "Blogs" },
  ];

  const moreMenuItems = [
    { label: "Career", path: "Career" },
    { label: "Contact Us", path: "ContactUs" },
    { label: "Gallery", path: "Gallery" },
    { label: "Our Team", path: "OurTeam" },

  ];

  // const isHomePage = location.pathname === "/" || location.pathname === "/Home";
  // const backgroundColor = isHomePage
  //   ? scrolling
  //     ? "rgba(255, 255, 255, 0.9)"
  //     : "radial-gradient(circle, #5975FF 0%, #3029D9 100%)"
  //   : scrolling
  //   ? "rgba(255, 255, 255, 0.9)"
  //   : "transparent";

  // const textColor = scrolling ? "black" : "white";
  const isHomePage = location.pathname === "/" || location.pathname === "/Home";
  const backgroundColor = isHomePage
    ? "radial-gradient(circle, #5975FF 0%, #3029D9 100%)"
    : "rgba(255, 255, 255, 0.9)";
  const textColor = isHomePage ? "white" : "black";

  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        width: "100%",
        height: { xs: "auto", md: "110px" },
        borderRadius: "0",
        background: backgroundColor,
        boxShadow: scrolling ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
        transition: "background-color 0.3s ease-in-out",
        zIndex: 1000,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "row", md: "row" },
              padding: { xs: "0 10px", md: "0 24px" },
            }}
          >
        <Box
  sx={{
    display: "flex",
    alignItems: "center", // This centers the items vertically
    justifyContent: "start", // Ensures alignment to the start
    width: { xs: "100%", md: "auto" },
    marginBottom: { xs: "10px", md: "0" },
  }}
>
  <CardMedia
    component="img"
    image={logo}
    alt="iPaisa Logo"
    style={{ backgroundColor: "transparent", borderRadius: "50%" }}
    sx={{
      height: { xs: 50, md: 90 },
      width: { xs: 50, md: 90 },
      mt: { xs: 1, md: 1 },
      marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
    }}
  />
  <Typography
    sx={{
      fontSize: { xs: "18px", md: "22px" },
      fontWeight: "700",
      color: textColor,
      lineHeight: 1, // Ensures the text is vertically centered
      display: "flex",
      alignItems: "center", // Ensures the text is vertically centered
      justifyContent: "center", // Ensures the text is horizontally centered
    }}
  >
    iPaisa
  </Typography>
</Box>

            {isMobile ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    style={{color:'black'}}
                    edge="end"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                  >
                    <List>
                      {menuItems.map((item, index) => (

                        <ListItem
                          button
                          key={index}
                          onClick={() => {
                            console.log(item,"this is item on clickkkk")

                            handleTabClick(item.path)}}
                          sx={{
                            // borderColor: "green",
                            borderBottom: '2px solid transparent',
                            
                          }}
                        >
                          <ListItemText primary={item.label} />
                        </ListItem>
                      ))}
                      <ListItem button onClick={handleMoreClick}>
                        <ListItemText primary="More" />
                      </ListItem>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleMoreClose()}
                      >
                        {moreMenuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => handleMoreClose(item.path)}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Menu>
                        {/* New Update Button */}
                      <Grid item>
                        <Button
                          onClick={handleNewUpdateClick}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "23.44px",
                            textAlign: "left",
                            background: "none",
                            border: "none",
                            paddingRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            color: textColor, // Text color for the button
                          }}
                        >
                          {/* <span 
                            style={{
                              ...styles.blink, 
                              color: isMobile ? "black" : textColor  // Conditionally set the "New" text color
                            }}
                          >
                            New Update
                          </span>
                          <span style={styles.redDot}></span> */}
                          
                        </Button>
                      </Grid>

                      <ListItem
                        button
                        onClick={() => handleTabClick("login")}
                        sx={{ justifyContent: "start" }}
                      >
                        <Button
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "23.44px",
                            textAlign: "left",
                            borderRadius: "8px",
                            padding: "8px 24px",
                            minWidth: 0,
                            textTransform:'none',
                            border:'1px solid'
                          }}
                        >
                          Login
                        </Button>
                      </ListItem>
                    </List>
                  </Drawer>
                </Box>
              </>
            ) : (
              <Grid
                container
                item
                spacing={2}
                justifyContent="center"
                sx={{ marginRight: { md: 14 }, marginTop: 1 }}
                >
                {menuItems.map((item, index) =>

                {
                return (
                  <Grid item key={index}>
                    <Button
                      onClick={() => { 
                        console.log(item,"this is item on click");
                        handleTabClick(item.path)}}
                      color="inherit"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "23.44px",
                        textAlign: "left",
                        borderBottom: activeTab === item.path ? "2px solid #3029D9" : "none", // Green underline for active tab
                        color: activeTab === item.path ? "#3029D9" : textColor, // Green text color for active tab
                        "&:hover": {
                          // color: "green", // Green text color on hover
                          borderBottom: activeTab === item.path ? "2px solid #3029D9" : "2px solid #3029D9", // Maintain green underline for active tab
                          background: "none", // No background change on hover
                        },
                      }}
                    >
                      {item.label}
                    </Button>
                  </Grid>
                )})}
                <Grid item>
                  <Button
                    color="inherit"
                    endIcon={<ArrowDropDown />}
                    onClick={handleMoreClick}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "23.44px",
                      textAlign: "left",
                      color: textColor,
                      borderBottom: "2px solid transparent",
                      "&:hover": {
                        borderBottom: "2px solid blue",
                      },
                    }}
                  >
                    More
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleMoreClose()}
                  >
                    {moreMenuItems.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMoreClose(item.path)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>

 {/* New Update Button */}
 <Grid item>
        <Button
          onClick={handleNewUpdateClick}
          sx={{
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "23.44px",
            textAlign: "left",
            color: "black", // Adjust as needed
            background: "none",
            border: "none",
            paddingRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <BlinkText style={{color: isHomePage ? 'yellow' : 'black', }}>New Update</BlinkText>
          <RedDot></RedDot>
        </Button>
      </Grid>

      {/* Dialog for showing the image */}
      <Dialog open={imageOpen} onClose={handleClose}>
      <Grid2 sx={{display:'flex', justifyContent:'space-between'}}>
      <DialogTitle sx={{fontSize:'16px'}}>New Update</DialogTitle>
      <DialogTitle>
        <Typography sx={{ fontWeight: 'bold', color: 'primary.main', fontSize:'16px' }}>
             Contact for CC support - 7420984745
        </Typography>
     </DialogTitle>
      </Grid2>       
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {/* Replace this image URL with the one you want to display */}
            <img src={MorqueWebImg} alt="New Update" style={{ maxWidth: "50%" }} />
          </Box>
        </DialogContent>
      </Dialog>

                <Grid item sx={{ position: "absolute", right: 0, mr: 3 }}>
                  <Button
                    onClick={() => handleTabClick(isHomePage ? "SignIn" : "ContactUs")}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "23.44px",
                      textAlign: "left",
                      borderRadius: "8px",
                      padding: "8px 24px",
                      minWidth: 0,
                      backgroundColor: isHomePage ? undefined : "#ffffff",
                      color: isHomePage ? "white" : "white",
                      backgroundColor: isHomePage ? undefined : "#111849",
                      textTransform: 'none',
                      border: '1px solid',
                      '&:hover': {
                        backgroundColor: isHomePage ? 'rgba(255, 255, 255, 0.1)' : '#333366', // Change this to your desired hover color
                        color: isHomePage ? 'white' : '#fff', // Optional: change text color on hover
                      },
                    }}
                  >
                    {isHomePage ? "Login" : "Contact Us"}
                  </Button>
                </Grid>

              </Grid>
            )}
          </Toolbar>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Header;