import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Typography,
    Divider,
    Grid,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
    Box,
    Paper,
    FormControl,
    Button,
    TextField,
    Container,
    MenuItem,
    Select,
    InputLabel,
    Autocomplete,
    IconButton,
    Drawer,
    // EditUserID


} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ErrorIcon from '@mui/icons-material/Error';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import EditUserID from './EditUserID';


function BookingPopup({ isOpen, onClose }) {
    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <EditUserID />
        </Drawer>
    );
}



const countries = [
    { label: 'Afghanistan' },
    { label: 'Syria' },
    { label: 'Zambia' },
    { label: 'Zimbabwe' },
];

const PassengerDetail = () => {
    const [userIdEntered, setUserIdEntered] = useState(false);
    const [isBookingOpen, setIsBookingOpen] = useState(false);
    const [agreeToPolicy, setAgreeToPolicy] = useState(false);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        gender: Yup.string().required('Gender is required'),
        fullName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Full Name must contain only letters and spaces')
            .required('Full Name is required'),
            age: Yup.number()
            .typeError('Age must be a number')
            .integer('Age must be an integer')
            .required('Age is required')
            .test('is-valid-age', 'Child traveller (between 0 and 4 years) names will not appear on ticket as they are not charged and not provided seat', 
                value => value === undefined || value > 4 || value === 0)
            .test('is-within-range', 'As per IRCTC rules, traveller age should be between 5 and 125 years', 
                value => value === undefined || (value >= 5 && value <= 125)),
        nationality: Yup.string().required('Nationality is required'),
        berthPreference: Yup.string().required('Berth Preference is required'),
        selectedValue: Yup.string().required('Please select an option to proceed'),
        mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit mobile number')
        .notOneOf(['0000000000', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'], 'Mobile number cannot be all the same digit')
        .test('not-all-zeros', 'Mobile number cannot be all zeros', value => value !== '0000000000')
        .test('not-eight-consecutive-zeros', 'Mobile number cannot contain eight consecutive zeros', value => value && !/00000000/.test(value))
        .test('not-all-same-digit', 'Mobile number cannot be all the same digit', value => {
            if (value) {
                const uniqueDigits = new Set(value.split(''));
                return uniqueDigits.size !== 1;
            }
            return true;
        })
        .required('Mobile number is required'),
        emailAddress: Yup.string()
            .email('Invalid email address')
            .required('Email Address is required'),
            userId: Yup.string()
            .required('User ID is required')
            .matches(/^[A-Za-z0-9]+$/, 'User ID must contain only letters and numbers'),
        agreeToPolicy: Yup.boolean().oneOf([true], 'Please Agree to the Cancellation and Refund Policy to Proceed Further'),

    });
    const formik = useFormik({
        initialValues: {
            gender: '',
            fullName: '',
            age: '',
            nationality: 'India',
            berthPreference: 'No Preference',
            selectedValue: '',
            mobileNumber: '',
            emailAddress: '',
            userId: '',
            agreeToPolicy: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
            // Here you can add further logic, like sending the data to an API
        },
    });

    const handleEditUserID = () => {
        setIsBookingOpen(true);
    };
    const handleCloseEditUserID = () => {
        setIsBookingOpen(false);
    };

    const handleUserIdChange = (e) => {
        formik.handleChange(e);
        setUserIdEntered(e.target.value !== '');
    };


    const handleMobileNumberInput = (e) => {
        const re = /^[0-9\b]+$/;
        // Only allow numeric values and restrict length to 10
        if (
            !re.test(e.key) &&
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            e.key !== 'ArrowLeft' &&
            e.key !== 'ArrowRight'
        ) {
            e.preventDefault();
        } else if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault();
        }
    };

    const handleNameInput = (e) => {
        const re = /^[A-Za-z\s]*$/;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleAgeInput = (e) => {
        const re = /^[0-9]*$/;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };


    return (
        <Container maxWidth="xl" sx={{ marginTop: '8%' }}>
        <Grid container spacing={2}>
            {/* Main Form Column */}
            <Grid item xs={9}>
                <Grid style={{height:'18%'}}>
                     <Paper  elevation={2} sx={{ padding: 2, borderRadius: 4}}  style={{height:'100%'}}>
                        <Typography style={{display:'flex'}} fontWeight="bold" variant="h5">RAJYARANI EXP
                            <Typography style={{marginTop:'0.4%'}}>(17611)</Typography>
                            <Typography style={{paddingLeft:'36.3%', fontWeight:'bold', marginTop:'0.4%'}}>2S,</Typography>
                            <Typography style={{marginTop:'0.4%', paddingLeft:'0.5%'}}>General Quota</Typography>
                            <Typography style={{marginTop:'0.4%', paddingLeft:'1%', color:'green', fontWeight:'bold'}}>AVL 64</Typography>
                        </Typography>
                        <Divider />
                        <Grid marginTop="1%">
                        <Grid style={{display:'flex'}}>
                            <Typography style={{ justifyItems:"flex-start", fontSize:'20px', marginTop:'1%'}}>
                            02:55,
                            </Typography>
                            <span style={{paddingTop:'1%' , paddingLeft:'0.8%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'23%' , fontSize: '1.2rem', color: 'gray'}}>--- 49 m ---</span>
                            <span style={{paddingTop:'1%' , paddingLeft:'23%' , fontSize: '1.2rem', color: 'gray'}}>12 Jun,</span>
                            <Typography style={{fontSize:'20px', marginTop:'1%', paddingLeft:'0.8%'}}>
                            03:44
                            </Typography>
                        </Grid>
                        <span style={{ paddingTop: '1%', fontSize: '1rem' }}>Aurangabad (AWB)</span>
                        <span style={{ paddingTop: '1%', marginLeft: '53.3%', fontSize: '1rem'}}>Pune Jn (PUNE)</span>
                        </Grid>
        </Paper>
        </Grid>


                    <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Paper elevation={2} sx={{ padding: 2, height:'90%', borderRadius: 4, marginTop: '3%', display: 'flex' }}>
                            <Grid style={{ display: 'flex', marginLeft: "5%" }}>
                                <Radio
                                    style={{marginTop:'-6%'}}
                                    checked={formik.values.selectedValue === 'a'}
                                    onChange={formik.handleChange}
                                    value="a"
                                    name="selectedValue"
                                    inputProps={{ 'aria-label': 'A' }}
                                    onBlur={formik.handleBlur}
                                />
                                <Typography marginTop="8%" fontWeight="700">
                                    Yes, I want Free Cancellation
                                    <Typography style={{ color: 'green' }}>Get full train fare refund of ₹265</Typography>
                                </Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', paddingLeft: "20%" }}>
                                <Radio
                                    style={{marginTop:'-5%'}}
                                    checked={formik.values.selectedValue === 'b'}
                                    onChange={formik.handleChange}
                                    value="b"
                                    name="selectedValue"
                                    inputProps={{ 'aria-label': 'B' }}
                                    onBlur={formik.handleBlur}
                                />
                                <Typography marginTop="8%" fontWeight="700">
                                    No, I don’t want Free Cancellation
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {formik.touched.selectedValue && formik.errors.selectedValue ? (
                            <Typography variant="body2" color="error" style={{fontWeight:'bold', fontSize:'0.960rem'}}>
                                <ErrorIcon style={{paddingTop:'1%'}}/>
                                {formik.errors.selectedValue}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>



        <Grid paddingTop="2%" display="flex">
            <PersonOutlineIcon sx={{ fontSize:33 }} />
                <Typography fontWeight="bold" fontSize="1.5rem">Traveller Details</Typography>
        </Grid>
        <Grid style={{color:'grey', marginLeft:'3%'}}>
                <Typography fontSize="0.9rem">Name should be as per Government ID Proof</Typography>
        </Grid>

        <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop: '3%' }} id="passengerdetails">
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12}>
                        <FormControl component="fieldset" error={formik.touched.gender && Boolean(formik.errors.gender)}>
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="Transgender" control={<Radio />} label="Transgender" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                            {formik.touched.gender && formik.errors.gender && (
                                <Typography variant="body2" color="error">
                                    {formik.errors.gender}
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter Full Name"
                            name="fullName"
                            onKeyPress={handleNameInput}
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            helperText={formik.touched.fullName && formik.errors.fullName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter Age"
                            name="age"
                            onKeyPress={handleAgeInput}
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.age && Boolean(formik.errors.age)}
                            helperText={formik.touched.age && formik.errors.age}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            options={countries}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => formik.setFieldValue('nationality', value?.label || '')}
                            value={countries.find(country => country.label === formik.values.nationality)} // Set default value
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Nationality"
                                    variant="outlined"
                                    name="nationality"
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                                    helperText={formik.touched.nationality && formik.errors.nationality}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Berth Preference</InputLabel>
                            <Select
                                name="berthPreference"
                                value={formik.values.berthPreference}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.berthPreference && Boolean(formik.errors.berthPreference)}
                                label="Berth Preference"
                            >
                                <MenuItem value="">
                                </MenuItem>
                                <MenuItem value="Lower Berth">Lower Berth</MenuItem>
                                <MenuItem value="Middle Berth">Middle Berth</MenuItem>
                                <MenuItem value="Upper Berth">Upper Berth</MenuItem>
                                <MenuItem value="Side Lower Berth">Side Lower Berth</MenuItem>
                                <MenuItem value="Side Upper Berth">Side Upper Berth</MenuItem>
                                <MenuItem value="No Preference">No Preference</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button
                        sx={{
                            fontSize: '1rem',
                            padding: '12px 24px',
                            backgroundColor: '#512DA8',
                            borderRadius: '20px',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#512DA8',
                            },
                        }}
                        variant="contained"
                        onClick={formik.handleSubmit}
                    >
                        Save Details
                    </Button>
                </Box>
            </Paper>
            <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop: '3%' }} id="ContactInformation">
                <Grid paddingTop="4%" paddingLeft="1%" display="flex">
                    <CallOutlinedIcon sx={{ fontSize:27, paddinTop:'2%'}} />
                    <Typography fontWeight="bold" fontSize="1.3rem">Contact Information</Typography>
                    </Grid>
                    <Grid style={{color:'grey', marginLeft:'3%'}}>
                        <Typography fontSize="0.9rem">Your ticket will be sent to below mobile number and email ID</Typography>
                    </Grid>    
                    <form onSubmit={formik.handleSubmit} style={{display:'flex'}}>
                    <Grid container spacing={2} style={{ width: '100%' }}>
                        <Grid item xs={12} sm={4}style={{paddingLeft:'4.5%'}}>
                        <TextField
                            fullWidth
                            id="mobileNumber"
                            name="mobileNumber"
                            label="Enter Mobile Number"
                            value={formik.values.mobileNumber}
                            onKeyDown={handleMobileNumberInput}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                            margin="normal"
                            inputProps={{
                                maxLength: 10,
                                title: 'Please enter a 10-digit number',
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={4} style={{paddingLeft:'1.4%'}}>
                        <TextField
                            fullWidth
                            id="emailAddress"
                            name="emailAddress"
                            label="Enter Email Address"
                            value={formik.values.emailAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                            helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                            margin="normal"
                        />
                        </Grid>
                        </Grid>
                    </form>
               
            </Paper>
            </Grid>
             {/* Sidebar Column */}
             <Grid item xs={3}>
             <Paper elevation={2} sx={{ padding: 2, borderRadius: 4 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid display="flex">
                        <BadgeOutlinedIcon style={{ fontSize: '1.6rem', paddingTop: '0.8%' }} />
                        <Typography variant="h6" fontWeight="bold" paddingLeft='5%'>
                            IRCTC User ID
                        </Typography>
                    </Grid>
                    <Box position="relative">
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Add User ID"
                            name="userId"
                            value={formik.values.userId}
                            onChange={handleUserIdChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.userId && Boolean(formik.errors.userId)}
                            helperText={formik.touched.userId && formik.errors.userId}
                            margin="normal"
                            sx={{ marginTop: 2 }}
                        />
                        {userIdEntered && (
                            <IconButton
                                size="small"
                                onClick={handleEditUserID}
                                sx={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
                        {userIdEntered
                            ? "Your IRCTC Password will be required after payment to complete the booking."
                            : "Enter your IRCTC User ID to proceed"}
                    </Typography>

                    <Typography style={{ color: '#512DA8', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }}>
                        Forgot Password?
                    </Typography>
                </form>
            </Paper>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop:'7%' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid>
                                <Typography variant="h6" fontWeight="bold" fontSize="1.1rem">
                                Fare Breakup
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
                                    The fare may change when you update traveller information
                            </Typography>
                            </Grid> 
                            <Grid display="flex" justifyContent="space-between" paddingTop="3%">
                                <Typography fontWeight="500">Base Fare</Typography>
                                <Typography>₹55</Typography>
                            </Grid>
                        </form>
                    </Paper>
                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop:'7%' }}>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControlLabel
                            control={<Checkbox style={{marginTop:'-7%'}}/>}
                            label={
                                <Typography color="textSecondary">
                                    IRCTC accident insurance: Rs. 0.45/person
                                    <a href="#" style={{textDecoration:'none', bgcolor:'#512DA8'}}> View T&C </a>
                                </Typography>
                            }
                        />
                    </form>
                    </Paper>

                    <Paper elevation={2} sx={{ padding: 2, borderRadius: 4, marginTop: '7%' }}>
                <form onSubmit={formik.handleSubmit}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="agreeToPolicy"
                                checked={agreeToPolicy}
                                onChange={(e) => {
                                    setAgreeToPolicy(e.target.checked);
                                    formik.setFieldValue("agreeToPolicy", e.target.checked);
                                }}
                                onBlur={formik.handleBlur}
                                color="primary"
                                style={{ marginTop: '-7%' }}
                            />
                        }
                        label={
                            <Typography color="textSecondary">
                                I agree to the Cancellation and Refund Policy
                                <a href="#" style={{ textDecoration: 'none', bgcolor: '#512DA8' }}> View </a>
                            </Typography>
                        }
                    />
                </form>
            </Paper>
            {formik.touched.agreeToPolicy && formik.errors.agreeToPolicy ? (
                        <Typography variant="body2" color="error" style={{fontWeight:'bold', fontSize:'0.960rem'}}>
                        <ErrorIcon style={{paddingTop:'3%', marginTop:'2%'}}/>
                            {formik.errors.agreeToPolicy}
                        </Typography>
                    ) : null}

            <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                    marginTop: '7%',
                    borderRadius: '20px',
                    bgcolor: '#512DA8',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#512DA8',
                    }
                }}
                onClick={formik.handleSubmit}
            >
                Proceed to Book
            </Button>    
                        </Grid>
                    </Grid>
                    <BookingPopup isOpen={isBookingOpen} onClose={handleCloseEditUserID} />
                </Container>
    );
};

export default PassengerDetail;
