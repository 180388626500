import React, { useState } from 'react';
import {
    Container,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Paper,
    Button,
    TextField,
    Grid,
    Menu,
    MenuItem,

    Select,
    IconButton,
    Typography,
    ListItemAvatar,
    Avatar,
    Divider,
    Checkbox,
    Box,
    Slider,
    Collapse,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DetailedFlightComponent from './DetailedFlightComponent';
import FareDetailsComponent from './FareDetailsComponent';
import BookingComponent from './BookingComponent';

function Flights({ flight }) {
    const [tripType, setTripType] = React.useState('oneway');
    const [anchorEl, setAnchorEl] = useState(null);
    const [focused, setFocused] = useState(false);
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [infants, setInfants] = useState(0);
    const [cabinClass, setCabinClass] = useState('Economy');
    const [flights, setFlights] = useState([]); // State for flights
    // Define state variables for departure and arrival times
    const [departureValue, setDepartureValue] = React.useState([0, 1440]); // Initial range from 12:00 AM to 11:59 PM
    const [arrivalValue, setArrivalValue] = React.useState([0, 1440]); // Initial range from 12:00 AM to 11:59 PM
    const minTime = 0; // Minimum time (12:00 AM)
    const maxTime = 1440; // Maximum time (11:59 PM)
    const [expanded, setExpanded] = useState(null);
    const handleExpandClick = (id) => {
        setExpanded(expanded === id ? null : id);
    };


    const handleTripTypeChange = (event) => {
        setTripType(event.target.value);
        if (event.target.value === 'roundtrip') {
            formik.setFieldValue('toDate', null);
        }
    };

    const handleTravellerClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleIncrement = (field) => {
        switch (field) {
            case 'adults':
                setAdults(adults + 1);
                break;
            case 'children':
                setChildren(children + 1);
                break;
            case 'infants':
                setInfants(infants + 1);
                break;
            default:
                break;
        }
    };

    const handleDecrement = (field) => {
        switch (field) {
            case 'adults':
                if (adults > 0) setAdults(adults - 1);
                break;
            case 'children':
                if (children > 0) setChildren(children - 1);
                break;
            case 'infants':
                if (infants > 0) setInfants(infants - 1);
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        console.log("Adults:", adults);
        console.log("Children:", children);
        console.log("Infants:", infants);
        console.log("Cabin Class:", cabinClass);
        setAnchorEl(null);
    };

    function formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    }

    const validationSchema = Yup.object().shape({
        from: Yup.string().required('From field is required'),
        to: Yup.string().required('To field is required'),
        fromDate: Yup.date().required('From Date is required'),
        toDate: Yup.date().nullable().when('tripType', {
            is: 'roundtrip',
            then: Yup.date().required('To Date is required for round trips'),
        }),
    });
    const handleSwapStations = () => {
        const from = formik.values.from;
        const to = formik.values.to;
        formik.setFieldValue('from', to);
        formik.setFieldValue('to', from);
    };
    function valuetext(value) {
        return `${value}`;
    }

    const minPrice = 1000;
    const maxPrice = 10000;


    const [value, setValue] = React.useState([minPrice, maxPrice]);
    const [fareVisible, setFareVisible] = useState(false);
    const [bookingVisible, setBookingVisible] = useState(false);
    const [selectedFare, setSelectedFare] = useState(null);



    const handleBookingClick = (fare) => {
        setSelectedFare(fare);
        setBookingVisible(true);
    };

    const handleBackClick = () => {
        setBookingVisible(false);
        setSelectedFare(null);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const formik = useFormik({
        initialValues: {
            from: '',
            to: '',
            fromDate: dayjs(),
            toDate: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Fetch flights data based on the form values
            // This is just a mock; replace with actual API call
            const mockFlights = [
                { id: 1, airline: 'Indigo', from: values.from, to: values.to, price: 100 },
                { id: 2, airline: 'Airline B', from: values.from, to: values.to, price: 150 },
                { id: 3, airline: 'Airline C', from: values.from, to: values.to, price: 200 },
            ];
            setFlights(mockFlights);
            console.log("Form Values:", values);
        },
    });
    // Function to handle departure from KLH slider
    const handleDepartureChange = (event, newValue) => {
        // Adjust the minimum and maximum values to account for the 30-minute gap
        const adjustedMin = newValue[0] + 30;
        const adjustedMax = newValue[1] + 30;

        // Update the state with the adjusted values
        setDepartureValue([adjustedMin, adjustedMax]);
    };

    // Function to handle arrival to TIR slider
    const handleArrivalChange = (event, newValue) => {
        // Adjust the minimum and maximum values to account for the 30-minute gap
        const adjustedMin = newValue[0] - 30;
        const adjustedMax = newValue[1] - 30;

        // Update the state with the adjusted values
        setArrivalValue([adjustedMin, adjustedMax]);
    };
    const handleFareClick = () => {
        setFareVisible(!fareVisible);
    };

    return (
        <Container maxWidth="lg" sx={{ marginTop: '7%' }}>
            <Paper elevation={2} sx={{ padding: 2 }}>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Trip Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="trip-type"
                            name="tripType"
                            value={tripType}
                            onChange={handleTripTypeChange}
                        >
                            <FormControlLabel value="oneway" control={<Radio />} label="One Way" />
                            <FormControlLabel value="roundtrip" control={<Radio />} label="Round Trip" />
                        </RadioGroup>
                    </FormControl>

                    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginTop: 2 }}>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <FormLabel>From</FormLabel>
                                <TextField
                                    variant="standard"
                                    name="from"
                                    value={formik.values.from}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.from && Boolean(formik.errors.from)}
                                    helperText={formik.touched.from && formik.errors.from}
                                    onFocus={() => setFocused(true)}
                                    InputLabelProps={{
                                        shrink: focused,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} md={1} onClick={handleSwapStations} container justifyContent="center">
                            <CompareArrowsIcon />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <FormLabel>To</FormLabel>
                                <TextField
                                    variant="standard"
                                    name="to"
                                    value={formik.values.to}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.to && Boolean(formik.errors.to)}
                                    helperText={formik.touched.to && formik.errors.to}
                                    onFocus={() => setFocused(true)}
                                    InputLabelProps={{
                                        shrink: focused,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid justifyContent="center" container spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            label="Departure Date"
                                            value={formik.values.fromDate}
                                            onChange={(value) => formik.setFieldValue('fromDate', value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="fromDate"
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
                                                    helperText={formik.touched.fromDate && formik.errors.fromDate}
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {tripType === 'roundtrip' && (
                                        <Grid item xs={6}>
                                            <DatePicker
                                                label="Return Date"
                                                value={formik.values.toDate}
                                                onChange={(value) => formik.setFieldValue('toDate', value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="toDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.toDate && Boolean(formik.errors.toDate)}
                                                        helperText={formik.touched.toDate && formik.errors.toDate}
                                                        variant="standard"
                                                        disabled={tripType !== 'roundtrip'}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    cursor: 'pointer',

                                }}
                                onClick={handleTravellerClick}
                            >
                                <span style={{ fontSize: '68%' }}>Travellers & Cabin Class</span>
                                <span style={{ fontWeight: 'bold' }}>{adults + children + infants} Travellers</span>
                                <span style={{ fontSize: '68%' }}>{cabinClass}</span>
                            </div>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <div style={{ padding: '20px', flexDirection: 'space-between' }} >
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle2">Adults</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => handleDecrement('adults')}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item width={50}>
                                            <TextField
                                                id="adults"
                                                value={adults}
                                                variant="standard"
                                                inputProps={{ readOnly: true, style: { textAlign: 'center' } }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => handleIncrement('adults')}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ padding: '20px' }} >
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle2">Children</Typography>
                                        </Grid>
                                        <Grid item marginLeft={-1.8}>
                                            <IconButton size="small" onClick={() => handleDecrement('children')}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item width={50}>
                                            <TextField
                                                id="children"
                                                value={children}
                                                variant="standard"
                                                inputProps={{ readOnly: true, style: { textAlign: 'center' } }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => handleIncrement('children')}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ padding: '20px' }} >
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle2">Infants</Typography>
                                        </Grid>
                                        <Grid item marginLeft={-0.5}>
                                            <IconButton size="small" onClick={() => handleDecrement('infants')}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item width={50}>
                                            <TextField
                                                id="infants"
                                                value={infants}
                                                variant="standard"
                                                inputProps={{ readOnly: true, style: { textAlign: 'center' } }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => handleIncrement('infants')}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                                <FormControl fullWidth>
                                    <Grid item>
                                        <Typography variant="h6" marginLeft={2}>Class</Typography>
                                    </Grid>
                                    <Select
                                        style={{ width: '78%', marginLeft: '8%', marginTop: '2%' }}
                                        labelId="cabin-class-label"
                                        id="cabin-class"
                                        value={cabinClass}
                                        onChange={(e) => setCabinClass(e.target.value)}
                                    >
                                        {['Economy', 'Premium Economy', 'Business'].map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button variant="contained" color="primary" style={{ width: '20%', marginLeft: '36%', marginTop: '3%' }} onClick={handleSubmit}>
                                    Done
                                </Button>
                            </Menu>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <Button type="submit" variant="contained" fullWidth style={{ marginLeft: tripType === 'oneway' ? '-50%' : '0' }}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} md={3}>
                    <Paper elevation={2} sx={{ padding: 2 }}>
                        <Typography variant="h6">Filters</Typography>


                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <LocationOnIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Stops</FormLabel>
                            </Grid>
                            <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                                <FormControlLabel control={<Checkbox />} label="Non-Stop" />


                                <FormControlLabel control={<Checkbox />} label="1 Stop" />


                                <FormControlLabel control={<Checkbox />} label="2 Stops" />

                            </Grid>
                        </FormControl>
                        <Divider />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <CurrencyRupeeIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Price Range</FormLabel>
                            </Grid>
                            <Box sx={{ width: 210, marginLeft: '15%', marginTop: '7%' }}>
                                <Typography variant='h6' gutterBottom fontSize={12}> Select Price Range </Typography>
                                <Slider
                                    style={{ width: '80%' }}
                                    getAriaLabel={() => 'Price Range'}
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={minPrice}
                                    max={maxPrice}
                                    step={100}
                                />
                                <Typography variant="caption" gutterBottom align="left" marginLeft={-1}>
                                    {value[0]}
                                </Typography>
                                <Typography variant="caption" gutterBottom align="right" marginLeft={18}>
                                    {value[1]}
                                </Typography>
                            </Box>
                        </FormControl>
                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FlightTakeoffIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Depature from KLH</FormLabel>
                            </Grid>
                            <Box sx={{ width: 210, marginLeft: '15%', marginTop: '7%' }}>
                                <Slider
                                    style={{ width: '80%' }}
                                    getAriaLabel={() => 'Departure Time'}
                                    value={departureValue}
                                    onChange={handleDepartureChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={minTime}
                                    max={maxTime}
                                    step={1}
                                />
                            </Box>
                        </FormControl>
                        <Typography variant="caption" gutterBottom align="left" marginLeft={-1}>
                            {formatTime(value[0])}
                        </Typography>
                        <Typography variant="caption" gutterBottom align="right" marginLeft={18}>
                            {formatTime(value[1])}
                        </Typography>

                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FlightLandIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Arrival to TIR</FormLabel>
                            </Grid>
                            <Box sx={{ width: 210, marginLeft: '15%', marginTop: '7%' }}>
                                <Slider
                                    style={{ width: '80%' }}
                                    getAriaLabel={() => 'Arrival Time'}
                                    value={arrivalValue}
                                    onChange={handleArrivalChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={minTime}
                                    max={maxTime}
                                    step={1}
                                />
                            </Box>
                        </FormControl>
                        <Typography variant="caption" gutterBottom align="left" marginLeft={-1}>
                            {formatTime(value[0])}
                        </Typography>
                        <Typography variant="caption" gutterBottom align="right" marginLeft={18}>
                            {formatTime(value[1])}
                        </Typography>
                        <Divider />
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FlightClassIcon />
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Airlines</FormLabel>
                            </Grid>
                            <Grid container spacing={2} marginTop={1} flexDirection={'column'} marginLeft={2}>

                                <FormControlLabel control={<Checkbox />} label="Star Air" />
                                <FormControlLabel control={<Checkbox />} label="Indigo" />

                            </Grid>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Paper elevation={2} sx={{ padding: 2 }}>
                        <Typography variant="h6">Available Flights</Typography>
                        {flights.map((flight) => (
                            <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }} key={flight.id}>
                                {!bookingVisible ? (
                                    <>
                                        {/* <Grid container display="flex" flexDirection="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginTop: 2 }}> */}
                                        <Grid display='flex' flexDirection="row">
                                            <Grid >
                                                <ListItemAvatar>
                                                    <Avatar alt={flight.airline} src={`/${flight.airline.toLowerCase().replace(/\s+/g, '')}.png`} />
                                                </ListItemAvatar>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <Typography variant="h6">{flight.airline}</Typography>
                                                    </Grid>
                                                    <Grid item container width="130%" display='flex' flexDirection="row" alignItems="center">
                                                        <Grid item>
                                                            <Typography variant='body2'>Flight Details</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton onClick={() => handleExpandClick(flight.id)}>
                                                                {expanded === flight.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} justifyContent="space-around" alignItems="center">
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography variant='body1'>11:00</Typography>
                                                        <Typography variant='body1'>Kolhapur</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant='body2' marginLeft={2}>Travel Time</Typography>
                                                    </div>
                                                    <Divider orientation="horizontal" flexItem sx={{ my: 1 }} />
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant='body2' marginLeft={4}>1 stop</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography variant='body1'>1:00</Typography>
                                                        <Typography variant='body1'>Tirupati</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography variant='body1'>10000</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Button variant='outlined' sx={{ borderRadius: '28%' }} onClick={handleFareClick}>View Fare</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Collapse in={expanded === flight.id} timeout="auto" unmountOnExit>
                                            <DetailedFlightComponent />
                                        </Collapse>
                                        {fareVisible && <FareDetailsComponent onPriceClick={handleBookingClick} />}

                                    </>
                                ) : (
                                    <BookingComponent selectedFare={selectedFare} onBack={handleBackClick} />
                                )}

                            </Paper>



                        ))}
                    </Paper>
                </Grid>
            </Grid>
        </Container >
    );
}

export default Flights;
