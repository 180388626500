import React, { useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

// Register the components
ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip);

const PieChartComponent = () => {
  const [data, setData] = useState({
    ChannelPartner: 20,
    SuperDistributor: 30,
    MasterDistributor: 10,
    AreaDistributor: 25,
    Distributor: 15,
    Retailer: 5,
  });

  const [timeRange, setTimeRange] = useState('Today');

  const chartData = {
    labels: ['Channel\nPartner', 'Super\nDistributor', 'Master\nDistributor', 'Area\nDistributor', 'Distributor', 'Retailer'],
    datasets: [
      {
        data: [
          data.ChannelPartner,
          data.SuperDistributor,
          data.MasterDistributor,
          data.AreaDistributor,
          data.Distributor,
          data.Retailer,
        ],
        backgroundColor: [
          '#00178D',
          '#77279D',
          '#2FC4B1',
          '#C95400',
          '#008D39',
          '#CED6DE',
        ],
        hoverBackgroundColor: [
          '#00178D',
          '#77279D',
          '#2FC4B1',
          '#C95400',
          '#008D39',
          '#CED6DE',
        ],
      },
    ],
  };

  const getBarChartData = (range) => {
    switch (range) {
      case 'One Week':
        return [data.ChannelPartner * 2, data.SuperDistributor * 2, data.MasterDistributor * 2, data.AreaDistributor * 2, data.Distributor * 2, data.Retailer * 2];
      case 'One Month':
        return [data.ChannelPartner * 4, data.SuperDistributor * 4, data.MasterDistributor * 4, data.AreaDistributor * 4, data.Distributor * 4, data.Retailer * 4];
      case 'Six Months':
        return [data.ChannelPartner * 6, data.SuperDistributor * 6, data.MasterDistributor * 6, data.AreaDistributor * 6, data.Distributor * 6, data.Retailer * 6];
      default:
        return [data.ChannelPartner, data.SuperDistributor, data.MasterDistributor, data.AreaDistributor, data.Distributor, data.Retailer];
    }
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const barChartData = {
    labels: ['Channel\nPartner', 'Super\nDistributor', 'Master\nDistributor', 'Area\nDistributor', 'Distributor', 'Retailer'],
    datasets: [
      {
        label: 'Services',
        data: getBarChartData(timeRange),
        backgroundColor: [
          '#00178D',
          '#77279D',
          '#2FC4B1',
          '#C95400',
          '#008D39',
          '#CED6DE',
        ],
        hoverBackgroundColor: [
          '#00178D',
          '#77279D',
          '#2FC4B1',
          '#C95400',
          '#008D39',
          '#CED6DE',
        ],
      },
    ],
  };

  const combinedLabels = chartData.labels.map(label => `${label}`);

  const handleClick = (event, elements) => {
    if (elements && elements.length > 0) {
      const clickedElementIndex = elements[0].index;
      const clickedLabel = chartData.labels[clickedElementIndex];
      const clickedValue = chartData.datasets[0].data[clickedElementIndex];
      console.log(`${clickedLabel}: ${clickedValue}`);
    } else {
      console.log('No valid elements found');
    }
  };

  return (
    <Container style={{ justifyContent: "flex-start", marginRight: '25%', }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper style={{ boxShadow:'none', padding: '3%', height: '90%', borderRadius: 20, width: "90%", }}>
            <h2>Total Balance</h2>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Pie
                  data={chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: (event, elements) => handleClick(event, elements),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} container direction="column" justifyContent="center">
                {chartData.labels.map((label, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={{ backgroundColor: chartData.datasets[0].backgroundColor[index], borderRadius: '50%', width: '10px', height: '10px', marginRight: '10px' }}></div>
                    <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</p>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid style={{ padding: '3%', height: '87%', borderRadius: 20, width: '123%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>Services</h2>
              <select value={timeRange} onChange={handleTimeRangeChange} style={{ fontSize: '16px', padding: '5px' }}>
                <option value="Today">Today</option>
                <option value="One Week">One Week</option>
                <option value="One Month">One Month</option>
                <option value="Six Months">Six Months</option>
              </select>
            </div>
            <Bar
              data={barChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PieChartComponent;
