import React from 'react';
import { Button, Grid, Typography, Dialog } from '@mui/material';
import { SuccessPopIcon } from '../../res/icons';
import CloseIcon from '@mui/icons-material/Close';


const Successtoverify = ({ handleClose }) => {
    return (
        <Dialog open={true} onClose={handleClose}>
          <Grid style={{ padding: '2rem' }}>
            <Grid style={{ padding: '1rem' }}>
              {/* Close Icon */}
              <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CloseIcon style={{cursor:'pointer'}} onClick={handleClose} />
              </Grid>
              {/* Success Icon */}
              <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                <SuccessPopIcon />
              </Grid>
              {/* Success Message */}
              <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>Bank Account Verified!</Typography>
              </Grid>
              {/* Description */}
              <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
                <Typography color='textSecondary' style={{ fontSize: '15px', textAlign: 'center' }}>
                  Your Bank Details has been Verified successfully.
                </Typography>
              </Grid>
              {/* OK Button */}
              <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%', marginBottom: '4%' }}>
                <Button
                  style={{ color: 'white', backgroundColor: '#185ABD', width: '20%' }}
                  onClick={handleClose}  // Add onClick handler here
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      );
}

export default Successtoverify
