// Main Screen with Toggle
import React, { useState } from "react";
import { Grid, Typography, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import NewGasBilling from "./NewGasBilling";
import NewBookCylinder from "./NewBookCylinder";

const GasBillingScreen = () => {
    const [selectedOption, setSelectedOption] = useState("payGasBill");
  
    return (
      <Box sx={{padding:'2rem'}} container spacing={3}>
        <Grid item xs={12}>
          <RadioGroup
            row
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            {/* <FormControlLabel
              value="payGasBill"
              control={<Radio color="primary" />}
              label="Pay Gas Bill"
            /> */}
            <FormControlLabel
              value="bookCylinder"
              control={<Radio color="primary" />}
              label="Book a Cylinder"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          {selectedOption === "payGasBill" && <NewGasBilling />}
          {selectedOption === "bookCylinder" && <NewBookCylinder />}
        </Grid>
      </Box>
    );
  };
  
  export default GasBillingScreen;