import React from 'react';
import {
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  InputAdornment,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PartnerArrowIcon, SearchnormalIcon } from '../../../res/NewWebIcons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#7A69EE',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const AlternateRowTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
}));

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  flexWrap: 'wrap',
}));

const ReportsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Report = () => {
  return (
    <Container sx={{ padding: {xs:'0.8rem', md:'0rem'}, marginTop: 'calc(3% + 56px)', marginBottom:'2%' }}>
        <Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>CMS</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Report</Typography>
            </Grid>
            <Grid style={{ marginTop: '1%' }}>
                <Grid>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Report</Typography>
                </Grid>
            </Grid>
        </Grid>

        <Paper sx={{padding:{xs:'0.8rem', md:'2rem'}, borderRadius:'12px', boxShadow:'none', marginTop:'3%'}}>
            {/* Search Section */}
            <SearchBar>
            <TextField
                size="small"
                variant="outlined"
                placeholder="Search here"
                sx={{
                    flex: 1,
                    maxWidth: 400,
                    mr: 2,
                    '& .MuiOutlinedInput-root': {
                    '& input::placeholder': {
                        color: '#4034AB99', // Custom placeholder color
                        opacity: 1, // Optional: Ensure it doesn’t fade
                    },
                    },
                }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchnormalIcon />
                    </InputAdornment>
                    ),
                }}
                />
                <Button
                sx={{
                    backgroundColor: '#4034AB',
                    '&:hover': { backgroundColor: '#6A5CD6' },
                    color: '#fff',
                    width:'12%',
                }}
                >
                Search
                </Button>
            </SearchBar>

            {/* Reports Table */}
            <ReportsContainer>
                <Typography style={{fontSize:'22px', fontWeight:'bold', }}>Reports</Typography>
                <TableContainer component={Paper} style={{boxShadow:'none', borderRadius:'12px 12px 0px 0px', marginTop:'2%'}}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>Action</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Depositor Id</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Mobile No</StyledTableCell>
                        <StyledTableCell>Biller Name</StyledTableCell>
                        <StyledTableCell>Amount Paid</StyledTableCell>
                        <StyledTableCell>Product</StyledTableCell>
                        <StyledTableCell>Tx Id</StyledTableCell>
                        <StyledTableCell>Tx Status</StyledTableCell>
                        <StyledTableCell>Remarks</StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {[1, 2, 3].map((row, index) => (
                        <TableRow
                        key={index}
                        sx={{
                            backgroundColor: index % 2 === 0 ? '#EEE9FC' : 'transparent',
                        }}
                        >
                        <AlternateRowTableCell>Action</AlternateRowTableCell>
                        <AlternateRowTableCell>Date</AlternateRowTableCell>
                        <AlternateRowTableCell>Depositor Id</AlternateRowTableCell>
                        <AlternateRowTableCell>Name</AlternateRowTableCell>
                        <AlternateRowTableCell>Mobile No</AlternateRowTableCell>
                        <AlternateRowTableCell>Biller Name</AlternateRowTableCell>
                        <AlternateRowTableCell>Amount Paid</AlternateRowTableCell>
                        <AlternateRowTableCell>Product</AlternateRowTableCell>
                        <AlternateRowTableCell>Tx Id</AlternateRowTableCell>
                        <AlternateRowTableCell>Tx Status</AlternateRowTableCell>
                        <AlternateRowTableCell>Remarks</AlternateRowTableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </ReportsContainer>
        </Paper>
    </Container>
  );
};

export default Report;
