import React, { useState } from 'react';
import NewPayInOutTopComp from './NewPayInOutTopComp'
import { Container, Grid, Paper, Typography, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import { makeStyles } from '@mui/styles';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const NewBalanceReqHis = () => {

    const classes = useStyles();
    const [searchParams, setSearchParams] = useState({
        fromDate: '',
        toDate: '',
    });

    const [tableData, setTableData] = useState([]); // State to hold table data

    const validationSchema = Yup.object({
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required'),
    });

    const handleSearch = (values) => {
        setSearchParams(values);
        // Fetch data based on the search params
        const data = fetchTableData(values);
        setTableData(data);
    };

    const fetchTableData = (params) => {
        // This is a mock function. Replace it with your data fetching logic.
        return [
            {
                transactionId: '12345',
                date: '2023-01-01',
                partnerRequestId: '54321',
                requestedBy: 'John Doe',
                amount: 1000,
                status: 'Approved',
                remarks: 'Transaction successful',
                approvedDate: '2023-01-02',
                processedBy: 'Jane Doe',
                paymentMethod: 'Bank Transfer',
            },
            // Add more data objects as needed
        ];
    };

    const handleDownload = (row) => {
        const doc = new jsPDF();
        doc.text(`Transaction Details`, 10, 10);
        doc.text(`Transaction ID: ${row.transactionId}`, 10, 20);
        doc.text(`Date: ${row.date}`, 10, 30);
        doc.text(`Partner Request ID: ${row.partnerRequestId}`, 10, 40);
        doc.text(`Requested By: ${row.requestedBy}`, 10, 50);
        doc.text(`Amount: $${row.amount.toFixed(2)}`, 10, 60);
        doc.text(`Status: ${row.status}`, 10, 70);
        doc.text(`Remarks: ${row.remarks}`, 10, 80);
        doc.text(`Approved Date: ${row.approvedDate}`, 10, 90);
        doc.text(`Processed By: ${row.processedBy}`, 10, 100);
        doc.text(`Payment Method: ${row.paymentMethod}`, 10, 110);
        doc.save(`transaction_${row.transactionId}.pdf`);
    };

    const lineHeight = '50px';
  return (
    <Container sx={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{display:'flex', alignItems:'center', gap:'1%', justifyContent:'start'}}>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Report</Typography>
                <PartnerArrowIcon/>
                <Typography color='textSecondary' style={{fontFamily:'sans-serif', fontSize:'14px'}}>Balance Request History</Typography>
            </Grid>

                <Grid sx={{marginTop:'1%'}}>
                    <Typography sx={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>Balance Request History</Typography>
                </Grid>

                <Grid sx={{ marginTop: '3%' }}>
                    <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                        <Formik
                            initialValues={{ fromDate: '', toDate: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSearch}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <Grid container spacing={2} sx={{marginTop: { xs: '0%', md:'1%'} }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="from-date"
                                        name="fromDate"
                                        label="From Date"
                                        type="date"
                                        value={values.fromDate}
                                        onChange={(event) => setFieldValue('fromDate', event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            style: { paddingTop: '0.6rem' },
                                        }}
                                        error={touched.fromDate && Boolean(errors.fromDate)}
                                        helperText={touched.fromDate && errors.fromDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="to-date"
                                        name="toDate"
                                        label="To Date"
                                        type="date"
                                        value={values.toDate}
                                        onChange={(event) => setFieldValue('toDate', event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            style: { paddingTop: '0.6rem' },
                                        }}
                                        error={touched.toDate && Boolean(errors.toDate)}
                                        helperText={touched.toDate && errors.toDate}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', justifyContent:'end', marginTop: { xs: '0%', md:'1%'} }}>
                                    <Button
                                       sx={{
                                        height: lineHeight,
                                        fontSize: '16px',
                                        textTransform:'none',
                                        width: { xs: '100%', md: '18%' },
                                        borderRadius: '8px',
                                        backgroundColor: "#027BFE",
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: "#027BFE",
                                            color: '#FFFFFF',
                                        },
                                    }}
                                        type="submit"
                                        fullWidth
                                        onClick={() => handleSearch(values)}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                            )}
                        </Formik>
                    </Paper>                   
                </Grid>

                <Grid sx={{ marginTop: '3%' }}>
                    <Grid>
                        <Grid sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                            <Grid>
                            <Typography sx={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>History</Typography>
                            </Grid>
                        
                                {/* <Button sx={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', fontFamily:'sans-serif', fontSize:'14px', width:'20%', gap:'5%'}}
                                onClick={generatePDF}
                                >
                                <DownloadIcon/>
                                Download PDF File</Button> */}
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} sx={{ marginTop:'2%',maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                        <Table>
                           <TableHead sx={{backgroundColor: '#027BFE'}}>
                           <TableRow>
                           <TableCell sx={{color:'white',textAlign:'start', fontSize: {xs:'9px',sm:'10px',md:'11px'}}}>Sr. No</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start',fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction ID</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Date</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Request ID</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Requested By</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Amount</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Status</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Remarks</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Approved Date</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Processed By</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Payment Method</TableCell>
                           <TableCell sx={{color:'white', textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Download</TableCell>
                    </TableRow>
            </TableHead>
            <TableBody>
                    {tableData.map((row, index) => (
                        <TableRow key={row.transactionId}
                         className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{index + 1}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionId}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.date}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerRequestId}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.requestedBy}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.amount}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.status}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.remarks}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.approvedDate}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.processedBy}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.paymentMethod}</TableCell>
                            <TableCell sx={{ textAlign: 'start', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>
                        <IconButton onClick={() => handleDownload(row)}>
                        <DownloadIcon />
                        </IconButton>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
                    </TableContainer>
                </Grid>
    </Container>
  )
}

export default NewBalanceReqHis
