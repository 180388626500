import React from 'react';
import { Button, TextField, Grid, Box } from '@mui/material';

const Form1 = ({ handleNext }) => {
  return (
    <Box component="form" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Reg No" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Txn ID" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Depositor ID" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Company Name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label="Amount" />
        </Grid>
      </Grid>
      <Box sx={{display:'flex', justifyContent:'end', marginTop:'2%'}}>
        <Button onClick={handleNext} sx={{color:'white', backgroundColor:'#4034AB', width:'14%'}}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Form1;
