import React, { useState } from 'react';
import { Card, CardContent, Button, Typography } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ElectricIcon from '@mui/icons-material/ElectricCar';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';

const DetailedBusComponent = () => {
  // State to manage which section is currently active
  const [activeSection, setActiveSection] = useState('');

  // Pickup and drop-off points data
  const pickupPoints = [
    { name: 'Dhaula Kuan (HPCL Pump)', time: '11:55 PM, 12 Jun' },
    { name: 'NueGo Lounge Kashmiri Gate', time: '11:00 PM, 12 Jun' },
    { name: 'Karol Bagh', time: '11:20 PM, 12 Jun' }
  ];

  const dropOffPoints = [
    { name: 'NIMS University', time: '4:45 AM, 13 Jun' },
    { name: 'Amity University', time: '4:47 AM, 13 Jun' },
    { name: 'Eidgah (Mansoori Travels)', time: '5:20 AM, 13 Jun' },
    { name: 'Transport Nagar (Aswal Travels)', time: '5:30 AM, 13 Jun' },
    { name: 'Narayan Singh Circle', time: '5:40 AM, 13 Jun' },
    { name: 'Sindhi Camp (Shri Balaji Travels)', time: '5:50 AM, 13 Jun' },
    { name: 'Polo Victory (Paras Travels)', time: '5:55 AM, 13 Jun' },
    { name: 'Chomu Pulia Bus Stop', time: '6:15 AM, 13 Jun' }
  ];

  // Function to handle click on a section
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  // Function to render content based on active section
  const renderContent = () => {
    switch (activeSection) {
      case 'Bus Features':
        return (
          <div>
            <h2>Bus Features</h2>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10%' }}>
              {/* Render bus features items horizontally */}
              <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
                <ElectricIcon style={{ color: '#39e75f' }} />
                <Typography>Electric</Typography>
              </div>
              <div>Clean Bus</div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
                <Typography>Comfortable Seats</Typography>
              </div>
            </div>
          </div>
        );
      case 'Pick-up & Drop-off':
        return (
          <div>
            <h2>Pick-up & Drop-off</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Pick up points */}
              <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '100%', marginBottom: '20px' }}>
                <CardContent>
                  <h3>Pick up points</h3>
                  <ul>
                    {pickupPoints.map((point, index) => (
                      <li key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>{point.name}</div>
                          <div>{point.time}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
              {/* Drop off points */}
              <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '100%', marginBottom: '20px' }}>
                <CardContent>
                  <h3>Drop off points</h3>
                  <ul>
                    {dropOffPoints.map((point, index) => (
                      <li key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div>{point.name}</div>
                          <div>{point.time}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </div>
          </div>
        );
      case 'Cancellation policy':
        return (
          <div>
            <h2>Cancellation Policy</h2>
            {/* Render cancellation policy details */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',gap:'10%' }}>
              <div style={{ flex: 1 }}>
                <h3>Time of Cancellation</h3>
                <p>Cancelled before Tue, 11 Jun 10:59 PM</p>
                <p>Between Tue, 11 Jun 11:00 PM and Wed, 12 Jun 10:59 AM</p>
                <p>Between Wed, 12 Jun 11:00 AM and Wed, 12 Jun 6:59 PM</p>
                <p>Non refundable After Wed, 12 Jun 7:00 PM</p>
              </div>
              <div style={{ flex: 1 }}>
                <h3>Refund Percentage</h3>
                <p>85%</p>
                <p>75%</p>
                <p>50%</p>
                <p>Non-refundable</p>
              </div>
              <div style={{ flex: 1 }}>
                <h3>Refund Amount</h3>
                <p>₹323.85</p>
                <p>₹285.75</p>
                <p>₹190.50</p>
                <p>Non-refundable</p>
              </div>
            </div>
          </div>
        );
      case 'Review':
        return (
          <div>
            <h2>Review</h2>
            {/* Render review details */}
            <p>Overall rating: 3.6</p>
            <p>61 Ratings</p>
            {/* Include the detailed ratings for each category */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px' }}>
        {/* Navigation buttons for each section */}
        <Button onClick={() => handleSectionClick('Bus Features')}><DirectionsBusIcon /> Bus Features</Button>
        <Button onClick={() => handleSectionClick('Pick-up & Drop-off')}>Pick-up & Drop-off</Button>
        <Button onClick={() => handleSectionClick('Cancellation policy')}>Cancellation Policy</Button>
        <Button onClick={() => handleSectionClick('Review')}>Review</Button>
      </div>

      {/* Render content based on active section */}
      {renderContent()}
    </div>
  );
};

export default DetailedBusComponent;









// import React, { useState } from 'react';
// import { Card, CardContent, Button, Typography } from '@mui/material';
// import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
// import ElectricIcon from '@mui/icons-material/ElectricCar';
// import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';

// const DetailedBusComponent = () => {
//   // State to manage which section is currently active
//   const [activeSection, setActiveSection] = useState('');

//   // Pickup and drop-off points data
//   const pickupPoints = [
//     { name: 'Dhaula Kuan (HPCL Pump)', time: '11:55 PM, 12 Jun' },
//     { name: 'NueGo Lounge Kashmiri Gate', time: '11:00 PM, 12 Jun' },
//     { name: 'Karol Bagh', time: '11:20 PM, 12 Jun' }
//   ];

//   const dropOffPoints = [
//     { name: 'NIMS University', time: '4:45 AM, 13 Jun' },
//     { name: 'Amity University', time: '4:47 AM, 13 Jun' },
//     { name: 'Eidgah (Mansoori Travels)', time: '5:20 AM, 13 Jun' },
//     { name: 'Transport Nagar (Aswal Travels)', time: '5:30 AM, 13 Jun' },
//     { name: 'Narayan Singh Circle', time: '5:40 AM, 13 Jun' },
//     { name: 'Sindhi Camp (Shri Balaji Travels)', time: '5:50 AM, 13 Jun' },
//     { name: 'Polo Victory (Paras Travels)', time: '5:55 AM, 13 Jun' },
//     { name: 'Chomu Pulia Bus Stop', time: '6:15 AM, 13 Jun' }
//   ];

//   // Function to handle click on a section
//   const handleSectionClick = (section) => {
//     setActiveSection(section);
//   };

//   // Function to render content based on active section
//   const renderContent = () => {
//     switch (activeSection) {
//       case 'Bus Features':
//         return (
//           <div>
//             <h2>Bus Features</h2>
//             <div style={{ display: 'flex', flexDirection: 'row', gap: '10%' }}>
//               {/* Render bus features items horizontally */}
//               <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
//                 <ElectricIcon style={{ color: '#39e75f' }} />
//                 <Typography>Electric</Typography>
//               </div>
//               <div>Clean Bus</div>
//               <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
//                 <Typography>Comfortable Seats</Typography>
//               </div>
//             </div>
//           </div>
//         );
//       case 'Pick-up & Drop-off':
//         return (
//           <div>
//             <h2>Pick-up & Drop-off</h2>
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//               {/* Pick up points */}
//               <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '100%', marginBottom: '20px' }}>
//                 <CardContent>
//                   <h3>Pick up points</h3>
//                   <ul>
//                     {pickupPoints.map((point, index) => (
//                       <li key={index} style={{ marginBottom: '10px' }}>
//                         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                           <div>{point.name}</div>
//                           <div>{point.time}</div>
//                         </div>
//                       </li>
//                     ))}
//                   </ul>
//                 </CardContent>
//               </Card>
//               {/* Drop off points */}
//               <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '100%', marginBottom: '20px' }}>
//                 <CardContent>
//                   <h3>Drop off points</h3>
//                   <ul>
//                     {dropOffPoints.map((point, index) => (
//                       <li key={index} style={{ marginBottom: '10px' }}>
//                         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                           <div>{point.name}</div>
//                           <div>{point.time}</div>
//                         </div>
//                       </li>
//                     ))}
//                   </ul>
//                 </CardContent>
//               </Card>
//             </div>
//           </div>
//         );
//       case 'Cancellation policy':
//         return (
//           <div>
//             <h2>Cancellation Policy</h2>
//             {/* Render cancellation policy details */}
//             <p>Time of Cancellation</p>
//             <p>Refund Percentage</p>
//             <p>Refund Amount</p>
//             {/* Include the detailed refund policy */}
//           </div>
//         );
//       case 'Review':
//         return (
//           <div>
//             <h2>Review</h2>
//             {/* Render review details */}
//             <p>Overall rating: 3.6</p>
//             <p>61 Ratings</p>
//             {/* Include the detailed ratings for each category */}
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <div style={{ display: 'flex', gap: '10px' }}>
//         {/* Navigation buttons for each section */}
//         <Button onClick={() => handleSectionClick('Bus Features')}><DirectionsBusIcon /> Bus Features</Button>
//         <Button onClick={() => handleSectionClick('Pick-up & Drop-off')}>Pick-up & Drop-off</Button>
//         <Button onClick={() => handleSectionClick('Cancellation policy')}>Cancellation Policy</Button>
//         <Button onClick={() => handleSectionClick('Review')}>Review</Button>
//       </div>

//       {/* Render content based on active section */}
//       {renderContent()}
//     </div>
//   );
// };

// export default DetailedBusComponent;






// import React, { useState } from 'react';
// import { Card, CardContent, Button, Typography } from '@mui/material';
// import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
// import ElectricIcon from '@mui/icons-material/ElectricCar';
// import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';

// const DetailedBusComponent = () => {
//   // State to manage which section is currently active
//   const [activeSection, setActiveSection] = useState('');

//   // Pickup and drop-off points data
//   const pickupPoints = [
//     { name: 'Dhaula Kuan (HPCL Pump)', time: '11:55 PM, 12 Jun' },
//     { name: 'NueGo Lounge Kashmiri Gate', time: '11:00 PM, 12 Jun' },
//     { name: 'Karol Bagh', time: '11:20 PM, 12 Jun' }
//   ];

//   const dropOffPoints = [
//     { name: 'NIMS University', time: '4:45 AM, 13 Jun' },
//     { name: 'Amity University', time: '4:47 AM, 13 Jun' },
//     { name: 'Eidgah (Mansoori Travels)', time: '5:20 AM, 13 Jun' },
//     { name: 'Transport Nagar (Aswal Travels)', time: '5:30 AM, 13 Jun' },
//     { name: 'Narayan Singh Circle', time: '5:40 AM, 13 Jun' },
//     { name: 'Sindhi Camp (Shri Balaji Travels)', time: '5:50 AM, 13 Jun' },
//     { name: 'Polo Victory (Paras Travels)', time: '5:55 AM, 13 Jun' },
//     { name: 'Chomu Pulia Bus Stop', time: '6:15 AM, 13 Jun' }
//   ];

//   // Function to handle click on a section
//   const handleSectionClick = (section) => {
//     setActiveSection(section);
//   };

//   // Function to render content based on active section
//   const renderContent = () => {
//     switch (activeSection) {
//       case 'Bus Features':
//         return (
//           <div>
//             <h2>Bus Features</h2>
//             <div style={{ display: 'flex', flexDirection: 'row', gap: '10%' }}>
//               {/* Render bus features items horizontally */}
//               <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
//                 <ElectricIcon style={{ color: '#39e75f' }} />
//                 <Typography>Electric</Typography>
//               </div>
//               <div>Clean Bus</div>
//               <div style={{ display: 'flex', alignItems: 'center', gap: '3%' }}>
//                 <Typography>Comfortable Seats</Typography>
//               </div>
//             </div>
//           </div>
//         );
//       case 'Pick-up & Drop-off':
//         return (
//           <div>
//             <h2>Pick-up & Drop-off</h2>
//             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//               {/* Pick up points */}
//               <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '45%' }}>
//                 <CardContent>
//                   <h3>Pick up points</h3>
//                   <ul>
//                     {pickupPoints.map((point, index) => (
//                       <li key={index}>{point.name} - {point.time}</li>
//                     ))}
//                   </ul>
//                 </CardContent>
//               </Card>
//               {/* Drop off points */}
//               <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', width: '45%' }}>
//                 <CardContent>
//                   <h3>Drop off points</h3>
//                   <ul>
//                     {dropOffPoints.map((point, index) => (
//                       <li key={index}>{point.name} - {point.time}</li>
//                     ))}
//                   </ul>
//                 </CardContent>
//               </Card>
//             </div>
//           </div>
//         );
//       case 'Cancellation policy':
//         return (
//           <div>
//             <h2>Cancellation Policy</h2>
//             {/* Render cancellation policy details */}
//             <p>Time of Cancellation</p>
//             <p>Refund Percentage</p>
//             <p>Refund Amount</p>
//             {/* Include the detailed refund policy */}
//           </div>
//         );
//       case 'Review':
//         return (
//           <div>
//             <h2>Review</h2>
//             {/* Render review details */}
//             <p>Overall rating: 3.6</p>
//             <p>61 Ratings</p>
//             {/* Include the detailed ratings for each category */}
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <div style={{ display: 'flex', gap: '10px' }}>
//         {/* Navigation buttons for each section */}
//         <Button onClick={() => handleSectionClick('Bus Features')}><DirectionsBusIcon /> Bus Features</Button>
//         <Button onClick={() => handleSectionClick('Pick-up & Drop-off')}>Pick-up & Drop-off</Button>
//         <Button onClick={() => handleSectionClick('Cancellation policy')}>Cancellation Policy</Button>
//         <Button onClick={() => handleSectionClick('Review')}>Review</Button>
//       </div>

//       {/* Render content based on active section */}
//       {renderContent()}
//     </div>
//   );
// };

// export default DetailedBusComponent;





















// import React from 'react'

// const DetailedBusComponent = () => {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default DetailedBusComponent

// import React from 'react';
// import { Typography, Box, Divider } from '@mui/material';

// const DetailedBusComponent = () => (
//     <Box sx={{ p: 2 }}>
//         <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
//             <Box>
//                 <Typography variant="body1">Kolhapur</Typography>
//                 <Typography variant="h6">02:40 PM</Typography>
//                 <Typography variant="body2">Mon, 11 Nov</Typography>
//                 <Typography variant="body2">Kolhapur Airport</Typography>
//             </Box>
//             <Box textAlign="center">
//                 <Typography variant="body2">1h 20m</Typography>
//             </Box>
//             <Box textAlign="right">
//                 <Typography variant="body1">Hyderabad</Typography>
//                 <Typography variant="h6">04:00 PM</Typography>
//                 <Typography variant="body2">Mon, 11 Nov</Typography>
//                 <Typography variant="body2">Rajiv Gandhi Airport</Typography>
//             </Box>
//         </Box>
//         <Divider />
//         <Box textAlign="center" sx={{ my: 2, backgroundColor: '#FFF7E0', p: 1, borderRadius: 1 }}>
//             <Typography variant="body2"><strong>2h 20m layover in Hyderabad.</strong></Typography>
//             <Typography variant="body2">You need to change the flight during the layover.</Typography>
//         </Box>
//         <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
//             <Box>
//                 <Typography variant="body1">Hyderabad</Typography>
//                 <Typography variant="h6">06:20 PM</Typography>
//                 <Typography variant="body2">Mon, 11 Nov</Typography>
//                 <Typography variant="body2">Rajiv Gandhi Airport</Typography>
//             </Box>
//             <Box textAlign="center">
//                 <Typography variant="body2">1h 5m</Typography>
//             </Box>
//             <Box textAlign="right">
//                 <Typography variant="body1">Tirupati</Typography>
//                 <Typography variant="h6">07:25 PM</Typography>
//                 <Typography variant="body2">Mon, 11 Nov</Typography>
//                 <Typography variant="body2">Tirupati Airport</Typography>
//             </Box>
//         </Box>
//     </Box>
// );

// export default DetailedBusComponent;
