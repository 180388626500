import React, { useState } from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  FormLabel,
  Dialog,
  DialogContent,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

const NewInsurance = () => {
  const [insurer, setInsurer] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [modalOpen, setModalOpen] = useState(false);


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const insurers = ['Insurer 1', 'Insurer 2', 'Insurer 3']; // Replace with actual insurer data

  const handleGetPremium = () => {
    // Logic to get premium (e.g., API call)
    console.log('Fetching Premium for:', { insurer, policyNumber, email, dob });
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <FormLabel
          component="legend"
          style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
          >
          Pay Insurance Premium
      </FormLabel>
      <Grid container spacing={3} style={{marginTop:'1%'}}>
        {/* Select Insurer */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Insurer</InputLabel>
            <Select
              value={insurer}
              onChange={(e) => setInsurer(e.target.value)}
              label="Insurer"
            >
              {insurers.map((ins, index) => (
                <MenuItem key={index} value={ins}>
                  {ins}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Policy Number */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Policy Number"
            value={policyNumber}
            onChange={(e) => setPolicyNumber(e.target.value)}
          />
        </Grid>

        {/* Email ID */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email ID"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        {/* Date of Birth */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Date of Birth"
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            InputLabelProps={{ shrink: true }} // Ensures the label doesn't overlap with the value
          />
        </Grid>

        {/* Get Premium Button */}
        <Grid item xs={12} justifyContent="flex-end" style={{display:'flex', justifyContent:'flex-end'}}>
          <Box>
            <Button
            style={{
              backgroundColor: "#027BFE",
              color: "white",
              textTransform: "none",
              fontSize: "14px",
            }}
            onClick={handleModalOpen}
            >
              Get Premium
            </Button>
          </Box>
        </Grid>
      </Grid>

          {/* Payment Modal */}
          <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid container spacing={4}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Insurer
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {insurer}
                    </Typography>
                  </Grid>

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Policy Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {policyNumber}
                    </Typography>
                  </Grid>

                  {/* Email ID */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Email ID
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {email}
                    </Typography>
                  </Grid>

                  {/* DOB */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Date of Birth
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {dob}
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      1230
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}

                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      1000
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    </Box>
  );
};

export default NewInsurance;
