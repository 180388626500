import React, { useState } from 'react';
import {
    Container,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Paper,
    Button,
    TextField,
    Grid,
    Typography,
    Checkbox,
    Box,
    CircularProgress,
    FormGroup,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Collapse,
    Drawer,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TuneIcon from '@mui/icons-material/Tune';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import StarIcon from '@mui/icons-material/Star';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DetailedBusComponent from './DetailedBusComponent';
import SeatSelection from './SeatSelection';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

function BookingPopup({ isOpen, onClose }) {
    return (
       
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box sx={{ width: 800 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h6" sx={{ marginLeft: 2, margin: "10%" }}>Select Your Seat</Typography>
                    <IconButton onClick={onClose} sx={{ marginRight: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <SeatSelection />
            </Box>
        </Drawer>
    );
}

function RBusTicketsBook() {
    const [tripType, setTripType] = useState('oneway');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState([100, 10000]);
    const [departureValue, setDepartureValue] = useState([0, 1440]);
    const [arrivalValue, setArrivalValue] = useState([0, 1440]);
    const [showSeatSelection, setShowSeatSelection] = useState(false);
    const [isBookingOpen, setIsBookingOpen] = useState(false);
    const navigate = useNavigate();

    const handleBookNowClick = () => {
        setIsBookingOpen(true);
    };

    const handleCloseBooking = () => {
        setIsBookingOpen(false);
    };

    const handleExpandClick = (id) => {
        setExpanded(expanded === id ? null : id);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDepartureChange = (event, newValue) => {
        setDepartureValue(newValue);
    };

    const handleArrivalChange = (event, newValue) => {
        setArrivalValue(newValue);
    };

    const handleTripTypeChange = (event) => {
        setTripType(event.target.value);
    };

    const handleSwapStations = () => {
        const from = formik.values.from;
        const to = formik.values.to;
        formik.setFieldValue('from', to);
        formik.setFieldValue('to', from);
    };

    const validationSchema = Yup.object().shape({
        from: Yup.string().required('Please Select Source'),
        to: Yup.string().required('To field is required'),
        fromDate: Yup.date().required('From Date is required'),
        toDate: Yup.date().when('tripType', {
            is: 'roundtrip',
            then: Yup.date().required('To Date is required for round trips'),
        }),
    });

    const formik = useFormik({
        initialValues: {
            from: '',
            to: '',
            fromDate: dayjs(),
            toDate: null,
            acBusesOnly: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                setTimeout(() => {
                    setLoading(false);
                    const mockBuses = [
                        { id: 1, busName: 'Gajraj Travels', from: values.from, to: values.to, price: 100 },
                        { id: 2, busName: 'NueGo', from: values.from, to: values.to, price: 150 },
                        { id: 3, busName: 'Bus C', from: values.from, to: values.to, price: 200 },
                        { id: 4, busName: 'Bus D', from: values.from, to: values.to, price: 200 },

                    ];
                    setBuses(mockBuses);
                }, 2000);
            } catch (error) {
                setLoading(false);
                console.error('Error submitting form:', error);
            }
        },
    });

    const operators = [
        "Ajay Travels",
        "Ambey Travel Agency",
        "Babu Travels",
        "Gajraj Bus Service",
        "Gulzar Tours And Travels",
        "M/S Gulzar Tour & Travels",
        "Jakhar Travels",
        "Mahalaxmi Travels ISO 9001:2015",
        "RSRTC (Rajasthan State Road Transport Corporation)",
        "Samreen Travels",
        "Shekhar Travels",
        "Shri Rishabh Travels",
        "Nama Travels",
        "Shrinath Solitaire Premium Class",
        "V K Jain Travels",
    ];

    

   
    const [expanded, setExpanded] = useState(false);
    const visibleOperators = expanded ? operators : operators.slice(0, 5);

    // amenties
    const Amenties = [
         "Live Tracking",
        "Wifi",
        "Water Bottle",
        "Snacks",
        "Blanket",
        "Pillow",
        "Washroom",
        "Charging Point",
        "TV",
    ];

    const visibleAmenties = expanded ? Amenties : Amenties.slice(0, 5);

    const pickupPoints = [
        "108 Meter Hanuman Murti Karol Bagh",
        "21 Old Fathepuri Delhi",
        "4385 Khanna Market Opprajendra Market Petrol Pump",
        "5 Gokahle Market Shree Shyam Travels",
        "7014227392 Hanuman Murti",
        "7014227392 Kashmiri Gate Metro Station Gate No",
        "7014227392 Near Pillar No",
        "7014227392 Shiv Murti",
        "7014227392 Shop No 12 Fatehpuri",
        "732 Faiz Road Karolbaug",
        "95 Khanna Market",
        "9929589015 Dhaula Kuan Near Rr Hospital Petrol Pump Delhi",
        "9929589015 Isbt Kashmiri Gate Delhi",
        "Ahemdabad Safar Travels Morigate Gol Chakkar",
        "Airportdelhi Palam Mode Shiv Murti",
        "Akardham Mandir",
        "Akshardham",
        "Akshardham Pandav Nagar Bus Stop Pillar No",
        "Ambiance Mall",
        "Ambience Mall",
        "Ambience Mall Highway",
        "Anand Vihar",
        "Anand Vihar Near Edm Mall",
        "Ashok Travels Dharuhera Flyover End",
        "B R Travels Tis Hazari Gole Chakar Shop No 18 B R Travels",
        "Babu Travels 36 Behind Tees Hajari Court Gate No",
        "Bawal Chowk",
        "Behroad Bus Stand",
        "Bus Stand Near Fire Station Fimistan",
        "By Pass",
        "Dharuhera",
        "Dharuhera Main Highway Jaipur Road"
    ];

    const visiblePickupPoints = expanded ? pickupPoints : pickupPoints.slice(0, 5);

    const [buses, setBuses] = useState([]);

    return (
        <Container maxWidth="lg" sx={{ marginTop: '7%' }}>
        
            <Typography component="legend" variant="h5" style={{ color: "black", fontWeight: "bold", marginBottom: "2%" }} >Book Bus Tickets</Typography>
            <Paper elevation={2} sx={{ padding: 2, borderRadius: 5 }}>
                {/* First section */}
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-label="trip-type"
                                name="tripType"
                                value={tripType}
                                onChange={handleTripTypeChange}
                            >
                                <FormControlLabel value="oneway" control={<Radio />} label="One Way" />
                                <FormControlLabel value="roundtrip" control={<Radio />} label="Round Trip" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                {/* Second section */}
                <Grid container spacing={2}>
                    <Grid item xs={5} md={3}>
                        <FormControl fullWidth>
                            <FormLabel>From</FormLabel>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    variant="standard"
                                    name="from"
                                    value={formik.values.from}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.from && Boolean(formik.errors.from)}
                                    helperText={formik.touched.from && formik.errors.from}
                                />
                                <Box display="flex" alignItems="center" justifyContent="center" height="100%" sx={{ marginLeft: '10%' }}>
                                    <CompareArrowsIcon onClick={handleSwapStations} sx={{ cursor: 'pointer' }} />
                                </Box>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} md={3}>
                        <FormControl>
                            <FormLabel>To</FormLabel>
                            <TextField
                                variant="standard"
                                name="to"
                                value={formik.values.to}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.to && Boolean(formik.errors.to)}
                                helperText={formik.touched.to && formik.errors.to}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Departure Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={formik.values.fromDate}
                                            onChange={(value) => formik.setFieldValue('fromDate', value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="fromDate"
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
                                                    helperText={formik.touched.fromDate && formik.errors.fromDate}
                                                    variant="standard"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            {tripType === 'roundtrip' && (
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Return Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={formik.values.toDate}
                                                onChange={(value) => formik.setFieldValue('toDate', value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="toDate"
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.toDate && Boolean(formik.errors.toDate)}
                                                        helperText={formik.touched.toDate && formik.errors.toDate}
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* Third section */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel>Seat Type</FormLabel>
                            <Box display="flex" style={{ marginTop: "2%", gap: "8px" }}>
                                <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0' }}>Seater</Button>
                                <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0' }}>Sleeper</Button>
                                <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0' }}>Semi-Sleeper</Button>
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button type="submit" variant="contained" onClick={formik.handleSubmit} disabled={loading} sx={{ borderRadius: 8, textTransform: 'none', fontWeight: 'bold', fontSize: '100%' }}>
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Search Buses'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Filters and Available Buses */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} md={3}>
                    <Paper elevation={2} sx={{ padding: 2,borderRadius:6,boxShadow:4 }}>
                        <Grid style={{ display: "flex" }}>
                            <TuneIcon />
                            <Typography variant="h6" style={{ marginBottom: "2%" }}> Filters</Typography>
                        </Grid>
                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FormLabel component="legend" varient="h3" sx={{ marginLeft: 1 }}>Bus Type</FormLabel>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" flexWrap="wrap">
                                        <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}> <AcUnitIcon />AC</Button>
                                        <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><AcUnitIcon />Non-AC</Button>
                                        <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><AirlineSeatReclineExtraIcon />Seater</Button>
                                        <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><AirlineSeatFlatIcon />Sleeper</Button>
                                        <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><AirlineSeatReclineNormalIcon />Semi-Sleeper</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FormLabel component="legend" sx={{ marginLeft: 1 }}>Single window seater/sleeper</FormLabel>
                            </Grid>
                            <Box sx={{ width: 210, marginLeft: '15%', marginTop: '7%' }}>
                            <Button variant="outlined" sx={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><FlightClassIcon/>Single Seater</Button>
                            </Box>
                        </FormControl>
                        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                <Grid container direction="column" alignItems="center">
                    <FormLabel component="legend" sx={{ marginLeft: 1 }}>Drop-off time</FormLabel>
                    <Grid container item direction="row" justifyContent="center" spacing={2}  sx={{ marginTop: 2 }}>
                        <Grid variant="contained" aria-label="Departure Time" sx={{ marginBottom: 2, }}>
                            <Button onClick={() => handleDepartureChange(0)}>Early Morning</Button>
                            <Button onClick={() => handleDepartureChange(300)} sx={{ marginLeft: 2, }} >Morning</Button> {/* 5 AM = 300 minutes */}
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" justifyContent="center" spacing={2} sx={{ marginTop: 2,marginBottom:2 }}>
                        <Grid variant="contained" aria-label="Departure Time">
                            <Button onClick={() => handleDepartureChange(720)}>Afternoon</Button> {/* 12 PM = 720 minutes */}
                            <Button onClick={() => handleDepartureChange(1080)} sx={{ marginLeft: 2, }}>Evening</Button> {/* 6 PM = 1080 minutes */}
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>

            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                <Grid container direction="column" alignItems="center">
                    <FormLabel component="legend" sx={{ marginLeft: 1 }}>Operator Rating</FormLabel>
                    <Grid container item direction="row" justifyContent="center" spacing={2}  sx={{ marginTop: 2 }}>
                        <Grid variant="contained" sx={{ marginBottom: 2, }} display={'flex'} >
                            <Button onClick={() => handleDepartureChange(0)} style={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem' }}><StarIcon/>5</Button>
                            <Button onClick={() => handleDepartureChange(300)} style={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem',marginLeft:'2%' }} ><StarIcon/>4 & Above</Button> 
                        </Grid>
                    </Grid>
                    <Grid container item direction="row" justifyContent="center" spacing={2}  sx={{ marginTop: 2 }}>
                        <Grid variant="contained" sx={{ marginBottom: 2, }} display={'flex'} >
                            <Button onClick={() => handleDepartureChange(0)} style={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem', }}><StarIcon/>1 & Above</Button>
                            <Button onClick={() => handleDepartureChange(300)} style={{ borderRadius: 6, backgroundColor: '#f0f0f0', margin: '0.5rem', }} ><StarIcon/>3 </Button> 
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>

                            <Grid container alignItems="center">
                                <FormLabel component="legend" varient="h3" sx={{ marginLeft: 1 }}>Operators</FormLabel>
                            </Grid>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {visibleOperators.map((operator, index) => (
                                    <ListItem key={index}>
                                        <Checkbox />
                                        <ListItemText primary={operator} />
                                    </ListItem>
                                ))}
                            </List>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Show Less' : 'Show More'}</Button>
                            </Box>
                        </FormControl>
                        <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FormLabel component="legend" varient="h3" sx={{ marginLeft: 1 }}>Pick-up Points</FormLabel>
                            </Grid>
                            <FormGroup>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {visiblePickupPoints.map((pickupPoint, index) => (
                                    <FormControlLabel key={index} control={<Checkbox />} label={pickupPoint} />
                                ))}
                                </List>
                            </FormGroup>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Show Less' : 'Show More'}</Button>
                            </Box>
                        </FormControl>
                        <Divider variant="middle" style={{ marginTop: "3%" }} />

                        <FormControl component="fieldset" sx={{ marginTop: 2 }}>
                            <Grid container alignItems="center">
                                <FormLabel component="legend" varient="h3" sx={{ marginLeft: 1 }}>Drop-Off Points</FormLabel>
                            </Grid>
                            <FormGroup>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {visiblePickupPoints.map((pickupPoint, index) => (
                                    <FormControlLabel key={index} control={<Checkbox />} label={pickupPoint} />
                                ))}
                                </List>
                            </FormGroup>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Show Less' : 'Show More'}</Button>
                            </Box>
                        </FormControl>
                        <Divider variant="middle" style={{ marginTop: "3%" }} />

                        {/* amenties */}
                        <Grid container alignItems="center">
                                <FormLabel component="legend" varient="h3" sx={{ marginLeft: 1 }}>Amenties</FormLabel>
                            </Grid>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {visibleAmenties.map((operator, index) => (
                                    <ListItem key={index}>
                                        <Checkbox />
                                        <ListItemText primary={operator} />
                                    </ListItem>
                                ))}
                            </List>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Show Less' : 'Show More'}</Button>
                            </Box>
                        
                        <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>

                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid elevation={2} sx={{ padding: 2,}}>
                    
                        <Grid style={{ display: "flex" }}>
                            <Typography variant="h6" style={{ marginBottom: "2%" }}><DirectionsBusIcon /> Available Buses</Typography>
                        </Grid>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            buses.map((bus) => (
                                
                                <Paper key={bus.id} sx={{ marginBottom: 1, padding: 2,borderRadius:6,boxShadow:4,gap:3 }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="h5" style={{fontWeight:"bold",}}>{bus.busName}</Typography>
                                            <Typography variant="body2">{bus.from} - {bus.to}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1" style={{ color: "green" }}>${bus.price}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant="contained" sx={{ borderRadius: 8 }} startIcon={<DirectionsBusIcon />} onClick={handleBookNowClick}>Book Now</Button>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                                    <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h6"></Typography>
                                            </Grid>
                                            <Grid item container width="130%" display='flex' flexDirection="row" alignItems="center">
                                                <Grid item>
                                                    <Typography variant='body2'>Bus Details</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={() => handleExpandClick(bus.id)}>
                                                        {expanded === bus.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Collapse in={expanded === bus.id} timeout="auto" unmountOnExit>
                                    <DetailedBusComponent />
                                </Collapse>
                                </Paper>
                            ))
                        )}
                        
                    </Grid>
                </Grid>
            </Grid>
            <BookingPopup isOpen={isBookingOpen} onClose={handleCloseBooking} />
        </Container>
    );
}

export default RBusTicketsBook;









