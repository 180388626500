// reducers/dashboardReducer.js
import { FETCH_WALLET_BALANCE, FETCH_PARTNER_DATA, FETCH_ERROR } from '../actions/types';

const initialState = {
    walletBalance: 0,
    partnerCount: {
        channelPartnerCount: 0,
        superDistributerCount: 0,
        masterDistributerCount: 0,
        areaDistributerCount: 0,
        retailerCount: 0,
        userCount: 0,
    },
    error: null,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WALLET_BALANCE:
            return { ...state, walletBalance: action.payload };
        case FETCH_PARTNER_DATA:
            return { 
                ...state,
                partnerCount: {
                    channelPartnerCount: action.payload.CHANNELPARTNER || 0,
                    superDistributerCount: action.payload.SUPERDISTRIBUTOR || 0,
                    masterDistributerCount: action.payload.MASTERDISTRIBUTOR || 0,
                    areaDistributerCount: action.payload.AREADISTRIBUTOR || 0,
                    retailerCount: action.payload.RETAILER || 0,
                    userCount: action.payload.USER || 0,
                },
            };
        case FETCH_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default dashboardReducer;
