import React, { useState } from "react";
import { Box, Card, CardContent, Dialog, DialogContent, FormLabel, Typography, Grid, Button, TextField, MenuItem } from "@mui/material";


// Pay Gas Bill Component
const NewGasBilling = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState("500");

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Grid style={{marginTop:'1%'}}>
        <FormLabel
          component="legend"
          style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
        >
          Pay Gas Bill
        </FormLabel>
        <Grid container spacing={2} style={{marginTop:'1%'}}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              label="Piped Gas Provider"
              variant="outlined"
            >
              <MenuItem value="Provider 1">Provider 1</MenuItem>
              <MenuItem value="Provider 2">Provider 2</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Customer ID" variant="outlined" />
          </Grid>
          <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
            <Button           
            style={{
              color: "white",
              backgroundColor: "#027BFE",
              textTransform: "none",
              fontSize: "16px",
              width: "16%",
            }}
            onClick={handleModalOpen}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
                    {/* Payment Modal */}
        <Dialog open={modalOpen} onClose={handleModalClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid container spacing={4}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Consumer ID
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      22671555447897
                    </Typography>
                  </Grid>

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Customer Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      98897678767
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {amount}
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      1000
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
    </Grid>
  );
};

export default NewGasBilling;