import React, { useState } from 'react';
import { Grid, TextField, Button, Box, CircularProgress, MenuItem, Typography } from '@mui/material';

const SinglePayout = ({ bankDetails, payoutModes, handlePayClick, inputHeight }) => {
    const [formData, setFormData] = useState({
        name: '',
        payeeaccountNumber: '',
        bankIfsc: '',
        transferMode: '',
        transferAmount: '',
        remarks: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        // Auto-fill other fields if account number is selected
        if (name === 'payeeaccountNumber') {
            const selectedBankDetail = bankDetails.find(bank => bank.accountNumber === value);
            if (selectedBankDetail) {
                setFormData({
                    ...formData,
                    name: selectedBankDetail.name,
                    bankIfsc: selectedBankDetail.ifsc,
                    payeeaccountNumber: selectedBankDetail.accountNumber,
                });
            }
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setError('');

        try {
            // API call to handle payout
            const response = await fetch('YOUR_PAYOUT_API_ENDPOINT', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const result = await response.json();

            if (response.ok) {
                // Handle successful payout
                alert('Payout successful');
            } else {
                setError(result.message || 'Payout failed');
            }
        } catch (error) {
            setError('Network error');
        }

        setIsSubmitting(false);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="name"
                        label="Name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {bankDetails.length > 0 ? (
                        <TextField
                            name="payeeaccountNumber"
                            label="Account Number"
                            select
                            fullWidth
                            variant="outlined"
                            value={formData.payeeaccountNumber}
                            onChange={handleChange}
                        >
                            {bankDetails.map(bank => (
                                <MenuItem key={bank.accountNumber} value={bank.accountNumber}>
                                    {`${bank.accountNumber} - ${bank.name}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Typography color="error">No bank details available</Typography>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="bankIfsc"
                        label="Bank IFSC"
                        value={formData.bankIfsc}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="transferMode"
                        label="Transfer Mode"
                        select
                        value={formData.transferMode}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    >
                        {payoutModes.map((mode) => (
                            <MenuItem key={mode.value} value={mode.value}>
                                {mode.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="transferAmount"
                        label="Transfer Amount"
                        value={formData.transferAmount}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="remarks"
                        label="Remarks"
                        value={formData.remarks}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="end" gap={2} mt={2}>
                <Button
                    sx={{
                        border: '1.5px solid #FF0000',
                        color: '#FF0000',
                        textTransform: "none",
                        width: { xs: '100%', md: '18%' },
                        fontSize: '16px',
                        borderRadius: '8px',
                        height: inputHeight
                    }}
                    onClick={() => setFormData({
                        name: '',
                        payeeaccountNumber: '',
                        bankIfsc: '',
                        transferMode: '',
                        transferAmount: '',
                        remarks: '',
                    })}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        textTransform: "none",
                        width: { xs: '100%', md: '18%' },
                        fontSize: '16px',
                        borderRadius: '8px',
                        height: inputHeight,
                        '&:hover': {
                            backgroundColor: "#027BFE",
                        },
                    }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Pay'}
                </Button>
            </Box>
            {error && <Typography color="error">{error}</Typography>}
        </>
    );
};

export default SinglePayout;
